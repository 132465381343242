import { useDispatch  } from 'react-redux';
import { SetPageTitle } from '../../redux/slices/LayoutSlice';
import { useEffect } from "react";

const Graphe = () => {
    const dispatch = useDispatch();
    

    useEffect(() => {
        dispatch(SetPageTitle('Statistuques'));
    },[dispatch]);
    
    return (
        <>
           Graphe
        </>
    );
};

export default Graphe;