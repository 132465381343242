import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import {
  searchFamilles,
  insertFamilles,
  updateArticle,
  AddArticle,
} from "../../../services/produits";

import ExtraForm from "../../../components/ExtraChamp/ExtraForm";

const EditProductModal = forwardRef((props, ref) => {
  const OpenProductModalBtn = useRef();
  const FormContainer = useRef();
  const FamilleText = useRef();

  const [DataFamille, setDataFamille] = useState([]);
  const [FromErrors, setFromErrors] = useState({});
  const [ShowFamille, setShowFamille] = useState(false);
  const [currentArticle, setCurrentArticle] = useState({});
  const [ShowAddFamille, setShowAddFamille] = useState(false);
  const [NewFamille, setNewFamille] = useState("");
  const [SwitchEtat, SetSwitchEtat] = useState("A");

  const initialArticle = {
    idArticle: "",
    FamilleRef: "",
    RefArticle: "",
    Designation: "",
    Type: "A",
    PrixVTTC: "0.00",
    PrixATTC: "0.00",
    TTva: "20.00",
    Stock: "0",
    StFact: "0",
    StMin: "0",
    Etat: "A",
    DesignationFamille: "",
  };

  useImperativeHandle(ref, () => ({
    async OpenModal(Article = initialArticle) {
      OpenProductModalBtn.current.click();
      setShowAddFamille(false);
      setShowFamille(false);

      setCurrentArticle(Article);

      // RefArticle.current.value = Article.RefArticle;
      const form = FormContainer.current.getElementsByTagName("form")[0];
      form.RefArticle.value = Article.RefArticle;
      form.Designation.value = Article.Designation;
      form.PrixATTC.value = Article.PrixATTC;
      form.PrixVTTC.value = Article.PrixVTTC;
      FamilleText.current.value = Article.DesignationFamille;

      setDataFamille(await searchFamilles());
      SetSwitchEtat(Article.Etat);
      setFromErrors({});
      //  console.log(currentArticle.RefArticle)
    },
  }));

  const VerifData = () => {
    let verif = {};

    if (currentArticle.RefArticle === "")
      verif = { ...verif, RefArticle: "Erreur Réference" };
    if (currentArticle.Designation === "")
      verif = { ...verif, Designation: "Erreur Désignation" };
    if (currentArticle.FamilleRef === "")
      verif = { ...verif, FamilleRef: "Erreur Famille" };
    if (currentArticle.PrixATTC === "")
      verif = { ...verif, PrixATTC: "Erreur Prix Achat" };
    if (currentArticle.PrixVTTC === "")
      verif = { ...verif, PrixVTTC: "Erreur Prix de Vente" };

    setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    if (currentArticle.idArticle === "") {
      AddArticle(currentArticle,props.TypeProduit).then((data) => {
        props.getData();
        currentArticle.idArticle = data.lastID;

        console.log(data.message);
      });
    } else {
      updateArticle(currentArticle)
        .then((message) => {
          props.getData();

          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const SelectFamille = (idFamille, DesignationFamille) => {
    setShowFamille(false);
    currentArticle.FamilleRef = idFamille;
    currentArticle.DesignationFamille = DesignationFamille;
    FamilleText.current.value = DesignationFamille;
  };

  const SetCurrentEtat = () => {
    currentArticle.Etat = currentArticle.Etat === "A" ? "S" : "A";
    SetSwitchEtat(currentArticle.Etat);
  };

  const GetCodeBarre = () => {
    const tb_controle = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
    const codebase =
      "00" +
      Math.floor(Date.now() / 1000)
        .toString()
        .substring(0, 10);
    let controle = 0;

    for (let i = 0; i < 12; i++) {
      let chiffre_c = parseInt(codebase.substring(i, i + 1));
      controle += chiffre_c * tb_controle[i];
    }

    controle = controle % 10;
    controle = controle === 0 ? 0 : 10 - controle;

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Attention Nouveau Code Barre : " + codebase + controle)) {
      FormContainer.current.getElementsByTagName("form")[0].RefArticle.value =
        codebase + controle;
      currentArticle.RefArticle = codebase + controle;
    }
  };

  const Newbtn = () => {
    setCurrentArticle(initialArticle);
    const form = FormContainer.current.getElementsByTagName("form")[0];
    form.RefArticle.value = "";
    form.Designation.value = initialArticle.Designation;
    form.PrixATTC.value = initialArticle.PrixATTC;
    form.PrixVTTC.value = initialArticle.PrixVTTC;
    FamilleText.current.value = initialArticle.DesignationFamille;
    SetSwitchEtat("A");
  };

  return (
    <>
      <button
        type="button"
        ref={OpenProductModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModal"
      >
        OpenProductModalBtn
      </button>

      <div
        className="modal fade"
        id="myModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="form-check form-switch  small">
                <input
                  className="form-check-input"
                  id="SwitchEtat2"
                  type="checkbox"
                  checked={SwitchEtat === "A"}
                  onChange={() => SetCurrentEtat()}
                  disabled={!props.upte}
                />
                <label
                  className="form-check-label fw-bold "
                  htmlFor="SwitchEtat2"
                >
                  {SwitchEtat === "A" ? (
                    <span>Active</span>
                  ) : (
                    <span className="text-secondary">Archive</span>
                  )}
                </label>
              </div>
              <h5
                className="modal-title mx-5 small d-none d-md-inline w-100 "
                id="myModalLabel"
              >
                Edition Produit
              </h5>
              {currentArticle.idArticle && (
                <div className="w-100 mx-2">
                  <ExtraForm
                    Tble={"Articles"}
                    idOrigine={currentArticle.idArticle}
                  />
                </div>
              )}

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div ref={FormContainer}>
                <form>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <label className="mb-1">Réference </label>
                        <div className="d-flex gap-2">
                          <input
                            id="RefArticle"
                            className="form-control form-control-sm"
                            type="text"
                            onChange={(e) =>
                              (currentArticle.RefArticle = e.target.value)
                            }
                          />
                          <input
                            className="btn btn-light btn-sm form-control form-control-sm"
                            style={{ maxWidth: 40 }}
                            type="button"
                            value="C"
                            click="GetCodeBarre"
                            readOnly
                            onClick={() => GetCodeBarre()}
                            disabled={!props.upte}
                          />
                        </div>
                        <span className="text-danger">
                          {FromErrors.RefArticle}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <label>Désignation</label>
                        <input
                          id="Designation"
                          className="form-control form-control-sm"
                          type="text"
                          onChange={(e) =>
                            (currentArticle.Designation = e.target.value)
                          }
                        />
                      </div>
                      <span className="text-danger">
                        {FromErrors.Designation || ""}
                      </span>
                    </div>
                    <div className="col-12 my-1 d-flex justify-content-end gap-1">
                      <button
                        className="btn btn-outline-primary btn-sm text-nowrap"
                        onClick={() => setShowAddFamille(!ShowAddFamille)}
                        type="button"
                      >
                        Familles <i className="fa fa-plus mx-1"></i>
                      </button>

                      {ShowAddFamille && (
                        <>
                          <div className="form-group flex-grow-1">
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              onChange={(e) => setNewFamille(e.target.value)}
                            />
                          </div>
                          <button
                            className="btn btn-success btn-xs"
                            type="button"
                            onClick={async () => {
                              const data = await insertFamilles(NewFamille,props.TypeProduit);
                              setShowAddFamille(false);
                              setNewFamille("");
                              SelectFamille(data.Ref, NewFamille);
                            }}
                          >
                            +
                          </button>
                        </>
                      )}
                    </div>

                    <div className="col-12 my-1">
                      <div className="form-group">
                        <span className="text-danger">
                          {FromErrors.FamilleRef || ""}
                        </span>
                        <div className="d-flex gap-1">
                          <input
                            className="form-control form-control-sm"
                            ref={FamilleText}
                            // onBlur={() => setShowFamille(false)}
                            onFocus={() => setShowFamille(true)}
                            placeholder="Selectionner Famille"
                            onChange={async (e) =>
                              setDataFamille(
                                await searchFamilles(e.target.value)
                              )
                            }
                          />

                          {ShowFamille && (
                            <button
                              className="btn btn-light btn-xs"
                              type="button"
                              onClick={() => {
                                setShowFamille(false);
                                SelectFamille(
                                  currentArticle.FamilleRef,
                                  currentArticle.DesignationFamille
                                );
                              }}
                            >
                              <i className="fa fa-arrow-left"></i>
                            </button>
                          )}
                        </div>
                        {ShowFamille && (
                          <div className="vh-scroled-modal">
                            <table className="table bg-light">
                              <tbody className="border ">
                                {DataFamille.map((row, i) => (
                                  row.Type === props.TypeProduit && 
                                  <tr key={i}>
                                    <td
                                      calss="text-center"
                                      data-style="width:50px;"
                                    >
                                      {i + 1}
                                    </td>
                                    <td data-style="width:100%;">
                                      {row.Designation} 
                                    </td>
                                    <td
                                      className="text-end"
                                      data-style="width:50px;"
                                    >
                                      <button
                                        className="btn btn-secondary btn-sm"
                                        type="button"
                                        onClick={() =>
                                          SelectFamille(
                                            row.Ref,
                                            row.Designation
                                          )
                                        }
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Prix d'Achat</label>
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          id="PrixATTC"
                          onChange={(e) =>
                            (currentArticle.PrixATTC = e.target.value)
                          }
                        />
                      </div>
                      <span className="text-danger">
                        {FromErrors.PrixATTC || ""}
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Prix de Vente</label>
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          id="PrixVTTC"
                          onChange={(e) =>
                            (currentArticle.PrixVTTC = e.target.value)
                          }
                        />
                      </div>
                      <span className="text-danger">
                        {FromErrors.PrixVTTC || ""}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <button
                onClick={() => {
                  props.upte && Newbtn();
                }}
                type="button"
                className="btn btn-outline-success btn-sm"
                disabled={!props.upte}
              >
                Nouveau
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary mx-1 btn-sm"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
                <button
                  onClick={() => props.upte && VerifData()}
                  type="button"
                  className="btn btn-primary btn-sm"
                   disabled={!props.upte}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default EditProductModal;
