import { useState, useEffect, forwardRef,useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";

import {
  getDetailData,  
} from "../../../../services/inventaires";

import { isLogin } from "../../../../services/global";
import Spinner from "../../../../components/widgets/spinner/spinner";

const DetailInventaire = forwardRef((props, ref) => {  

  const navigate = useNavigate();

  const [DataList, setDataList] = useState([]);
  const [count, setcount] = useState(0); // only to rerend when changing QteINV
  const [isLoading, setLoading] = useState(false);
  const [SearchText, SetSearchText] = useState("");

  const getData = async () => {
    setLoading(true);
    const data = await getDetailData(props.IdInventaire);
    setDataList(data);    
    setLoading(false);
    props.setDetailPrint([...data]);
  }

  useImperativeHandle(ref, () => ({
    async getData() {      
      await getData();     
    },
    async search(text) {
      SetSearchText(text)     
    },
  }));

  useEffect(() => {
    sessionStorage.setItem("url", "/produits/inventaires");
    if (!isLogin()) {
      navigate("/login");
    } 
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.IdInventaire, navigate]);

  const updateQteInv=(row,Value)=>{
    row.qteInv = Value;
    row.Diff = Value - row.qteStock;    
    setcount(count + 1)   
  }

 

  return (
    <>
      <table className="table table-hover w-100 border">
        <thead>
          <tr className="text-center">
            <th>N°</th>
            <th>Désignation</th>
            <th>Stock</th>
            <th>Inventaire</th>
            <th>Diff</th>
          </tr>
        </thead>
        <tbody>
          {DataList.map((row, i) => (
            (row.Designation.toLowerCase()).includes(SearchText.toLowerCase()) && 
            <tr key={i} className="text-center">
              <td className="text-center border-end">{i + 1}</td>
              <td className="text-start border" style={{ minWidth: "300px" }}>{row.Designation}</td>
              <td>{row.qteStock}</td>
              <td className="d-flex justify-content-center border " htmlFor={row.IdDetailInventaire } >
              <input                  
                  className="form-control text-center border-0 text-primary"                  
                  type="number"
                  id={row.IdDetailInventaire }
                  defaultValue={row.qteInv}
                  onFocus={(e)=> e.target.select()}
                  onChange={(e) => updateQteInv(row,e.target.value)}
                />
              </td>
              <td>{row.Diff}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Spinner isLoading={isLoading} StyleClass="spinner-positionDetail" />

      {/* <!-- Button trigger modal --> */}

     
     
    </>
  );
});

export default DetailInventaire;
