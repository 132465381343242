import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { updateFacture } from "../../../../services/factures_ventes";
import {
  padNumber,
  FormattNumber,
  formatDate,
} from "../../../../services/global";
import DetailFacture from "./DetailFacture";
import ComboClient from "../../Clients/ComboClient";

const EditModal = forwardRef((props, ref) => {
  const OpenModalBtn = useRef();
  const FormHeader = useRef();
  const FormFooter = useRef();
  const ClientText = useRef();

  const ComboClient_Ref = useRef(null);

  const [FromErrors, setFromErrors] = useState({});
  const [currentFacture, setcurrentFacture] = useState({});

  const [ShowClients, setShowClients] = useState(false);
  // const [ShowReglement, setShowReglement] = useState(false);

  const setDetailPrint = (data) => {
    props.setDetailCurrentPrint(data);
  };

  const initialFacture = {
    IdFacture: 0,
    NumFacture: 0,
    CodeClt: 1,
    DateFacture: formatDate(new Date()),
    Mod_HT: "0",
    MontantTTC: 0,
    Tva: 0,
    Avoir: 1,
    Description: "",
    UserID: 0,
    Client: "Client Comptant",
  };

  useImperativeHandle(ref, () => ({
    async OpenModal(Facture = initialFacture) {
      // setShowReglement(false);
      OpenModalBtn.current.click();

      setShowClients(false);

      setcurrentFacture(Facture);

      

      FormHeader.current.NumFacture.value = padNumber(Facture.NumFacture, 4);
      FormHeader.current.DateFacture.value = Facture.DateFacture;

      FormFooter.current.Description.value = Facture.Description;

      ClientText.current.value = Facture.Client;

      setFromErrors({});

      window.onafterprint = function () {
        try {
          const div = document.querySelectorAll(".bg-transparent");
          div[0].classList.remove("bg-transparent");
          div[0].classList.add("modal-backdrop");
        } catch {}
      };

    },
  }));

  const imprimer = () => {
    const div = document.querySelectorAll(".modal-backdrop");
    div[0].classList.add("bg-transparent");
    div[0].classList.remove("modal-backdrop");
    window.print();
  };

  const setTotalFacture = (V) => {
    // currentFacture.MontantTTC = V;
    setcurrentFacture({ ...currentFacture, MontantTTC: V }); // selement pour raffrichir le Montant TTC
  };

  const VerifData = () => {
    let verif = {};
    // if(currentFacture.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}

    // setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    if (currentFacture.IdFacture === "") {
      //     AddFacture(currentFacture).then((data) => {
      //         props.getData();
      //         currentFacture.IdFacture=data.lastID;
      //         console.log(data.message);
      //    })
    } else {
      updateFacture(currentFacture)
        .then((message) => {
          //  props.getData();

          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const SelectClient = (idClient, DesignationClient) => {
    setShowClients(false);
    currentFacture.CodeClt = idClient;
    currentFacture.Client = DesignationClient;
    ClientText.current.value = DesignationClient;
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        type="button"
        ref={OpenModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModalList"
      >
        OpenModalBtn
      </button>

      <div
        className="modal fade not-print"
        id="myModalList"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-"
        aria-labelledby="myModalListLabel"
        aria-hidden="true"
      >
        {/* <div
          className={
            "modal-dialog modal-dialog-centered " +
            (ShowReglement ? "" : "modal-xl")
          }
        > */}
        <div className="modal-dialog modal-dialog-centered modal-xl">
          {/* Bloc Facture Détail */}
          {/* <div className={ShowReglement ? "d-none" : "modal-content py-2"}> */}
          <div className="modal-content py-2">
            <button
              type="button"
              onClick={() => {
                props.getData();
                props.switchPrint("List");
                setShowClients(false);              
              }}
              className="btn btn-light position-absolute top-0 start-100 translate-middle rounded-circle"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>

            <form ref={FormHeader} className="modal-header">
              <div className="row w-100 m-auto">
                <div className="col-12 col-md-6 d-flex gap-2 mb-1 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control text-center"
                    disabled={true}
                    type="text"
                    id="NumFacture"
                    readOnly
                  />
                  <input
                    className="form-control py-0"
                    ref={ClientText}
                    onFocus={() => setShowClients(true)}
                    placeholder="Selectionner Client"
                    onChange={async (e) => {
                      ComboClient_Ref.current.SerachDatafr(e.target.value);
                    }}
                  />
                  {ShowClients && (
                    <>
                      <button
                        className="btn btn-success btn-sm edit mx-1"
                        onClick={() => ComboClient_Ref.current.NewFour()}
                        name="edit"
                        type="button"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        className="btn btn-light btn-xs"
                        type="button"
                        onClick={() => {
                          setShowClients(false);
                          SelectClient(
                            currentFacture.CodeClt,
                            currentFacture.Client
                          );
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>
                      </button>
                    </>
                  )}
                </div>

                <div className="col-6 col-md-4 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control"
                    type="date"
                    id="DateFacture"
                    onChange={(e) =>
                      (currentFacture.DateFacture = e.target.value)
                    }
                  />
                </div>

                <div className="col-6 col-md-2 justify-content-end d-flex gap-2 py-1 ">
                  <input
                    onClick={() => VerifData()}
                    value="Enregistrer"
                    className={
                      currentFacture.Avoir === "1"
                        ? "btn btn-primary btn-sm"
                        : "btn btn-danger btn-sm"
                    }
                    type="button"
                  />
                </div>
              </div>
            </form>

            {ShowClients && (
              <ComboClient
                ref={ComboClient_Ref}
                SelectClient={SelectClient}
              />
            )}

            <div
              className="modal-body border vh-scroled-detail"
              onClick={() => {
                setShowClients(false);
                SelectClient(currentFacture.CodeClt, currentFacture.Client);
              }}
            >
              <DetailFacture
                IdFacture={currentFacture.IdFacture}
                Sens={currentFacture.Avoir}
                getData={props.getData}
                setTotalFacture={setTotalFacture}
                setDetailPrint={setDetailPrint}
              />
            </div>
            <form ref={FormFooter} className="modal-footer d-flex flex-column">
              <div className="row w-100">
                <div className="col-8 col-md-6 input-group-sm">
                  <input
                    className="form-control"
                    type="text"
                    id="Description"
                    placeholder="Edtion Commentaire"
                    onChange={(e) =>
                      (currentFacture.Description = e.target.value)
                    }
                  />
                </div>
                <div className="col-4 col-md-2 py-1">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => imprimer()}
                    type="button"
                  >
                    <i className="fa fa-print"></i>
                  </button>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-end align-items-center small">
                  <b className="mx-2 h4 text-primary">
                    Total : {FormattNumber(currentFacture.MontantTTC * 1, 2)}
                  </b>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditModal;
