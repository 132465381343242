
// import axios from "axios";
// const baseApi="https://api.gstok.com"

export const deconnect =() => { sessionStorage.removeItem("token");window.location.href = "/login";}
// export const deconnect =(test='deconnect') => {console.log(test)}

export const FormattNumber = (m, n) => {              
    var value = m.toLocaleString(
        undefined, // leave undefined to use the browser's locale,
        // or use a string like 'en-US' to override it.
        { minimumFractionDigits: n }
      );
      return value;
};

export const padNumber = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

function isValidDate(dateString) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === dateString;
}

export const formatDate = (date) => { // yyyy-mm-dd
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export const GetDate = async () => {             
  
  const date = new Date();

  let debut = sessionStorage.getItem('Debut') ? sessionStorage.getItem('Debut') :  formatDate(new Date(date.getFullYear(), date.getMonth(), 1)) ;
  let fin = sessionStorage.getItem('Fin') ? sessionStorage.getItem('Fin') :  formatDate(new Date(date.getFullYear(), date.getMonth()+1, 0));


 
      if(isValidDate(debut) && isValidDate(fin) && debut <= fin)
      {
        return {debut:debut,fin:fin}
      }
      else
      {
        return {debut:formatDate(new Date(date.getFullYear(), date.getMonth(), 1)),fin: formatDate(new Date(date.getFullYear(), date.getMonth()+1, 0))}
      } 

      

};


export const isLogin = () => {             
  
  return sessionStorage.getItem("token") ? true : false;
};

export const isAuth = (Auth) => {  
  if (!Auth) {
    alert("Accès Non Autorisé");
  } else {
    return true
  }
}
export const reformatDate = (dateStr) => {
  let dArr = dateStr.split("-");  // ex input "2010-01-18"
  return dArr[2] + "-" + dArr[1] + "-" + dArr[0]; //ex out: "18/01/10"
}


export const sortBy = (param ,TypeData,data,sens) => {
  switch (TypeData) {
      case 'S':                  
          return data.sort((p1, p2) => (p1[param] > p2[param]) ? 1*sens :  -1*sens );  
      case 'N':
          return data.sort((p1, p2) => (p1[param]*1 > p2[param]*1) ? 1*sens :  -1*sens ); 
      case 'D':
          return data.sort((p1, p2) => (p1[param] > p2[param]) ? 1*sens :  -1*sens ); 
      default:
          return ;
    }

  
  
}

export const SerachData = (content,searchText) => {

  const SearchArray = searchText.split(" ");
  let rep = false;

  // for(let i=0; i < SearchArray.length;i++){
  //   // console.log(SearchArray[i]);
  //   rep = (SearchArray[i] !== '' && content.toLowerCase().includes(SearchArray[i].toLowerCase())) || searchText === ''  ;
  //   if (rep || i=== SearchArray.length-1){
  //     return rep
  //   }
  // }
  // if ( rep || i=== SearchArray.length-1){
  //   return rep
  // }

  for(let i=0; i < SearchArray.length;i++){ 
    if( i === 0){
      rep = (content.toLowerCase().includes(SearchArray[i].toLowerCase())) || searchText === ''  ;
    }
    else{
      rep = (rep &&  content.toLowerCase().includes(SearchArray[i].toLowerCase())) || searchText === ''  ;
    } 
    
    if ( i=== SearchArray.length-1){
      return rep
    }
  }

  return rep;    
};








