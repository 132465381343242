import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { updateClient, AddClient } from "../../../services/clients";

const EditClientModal = forwardRef((props, ref) => {
  const OpenClientModalBtn = useRef();
  const FormContainer = useRef();

  const [FromErrors, setFromErrors] = useState({});
  const [currentClient, setCurrentClient] = useState({});

  const initialClient = {
    IdClient: "",
    Client: "",
    ICE: "",
    Adresse: "",
    Tel: "",
    Ville: "",
    Solde: "0",
    Etat: "A",
  };

  useImperativeHandle(ref, () => ({
    OpenModal(Client = initialClient) {
      OpenClientModalBtn.current.click();

      setCurrentClient(Client);

      const form = FormContainer.current.getElementsByTagName("form")[0];
      form.Client.value = Client.Client;
      form.ICE.value = Client.ICE;
      form.Adresse.value = Client.Adresse;
      form.Tel.value = Client.Tel;
      form.Ville.value = Client.Ville;

      // setFromErrors({})
    },
  }));

  const VerifData = () => {
    let verif = {};
    if (currentClient.Client === "")
      verif = { ...verif, Client: "Erreur Client" };

    setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    console.log(currentClient.IdClient);
    if (currentClient.IdClient === "") {
      AddClient(currentClient).then((data) => {
        props.getData();
        currentClient.IdClient = data.lastID;

        console.log(data.message);
      });
    } else {
      updateClient(currentClient)
        .then((message) => {
          props.getData();

          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const Newbtn = () => {
    setCurrentClient(initialClient);
    const form = FormContainer.current.getElementsByTagName("form")[0];
    form.Client.value = initialClient.Client;
    form.ICE.value = initialClient.ICE;
    form.Adresse.value = initialClient.Adresse;
    form.Tel.value = initialClient.Tel;
    form.Ville.value = initialClient.Ville;
  };

  return (
    <>
      <button
        type="button"
        ref={OpenClientModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModal"
      >
        OpenClientModalBtn
      </button>

      <div
        ref={FormContainer}
        className="modal fade"
        id="myModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myModalLabel">
                Edition Client
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form className="modal-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="form-group">
                    <label>Client</label>
                    <input
                      id="Client"
                      className="form-control form-control-sm"
                      type="text"
                      onChange={(e) => (currentClient.Client = e.target.value)}
                    />
                  </div>
                  <span className="text-danger">{FromErrors.Client || ""}</span>
                </div>
                <div className="col-12 mb-3">
                  <div className="form-group">
                    <label className="mb-1">ICE </label>
                    <input
                      id="ICE"
                      className="form-control form-control-sm "
                      type="text"
                      onChange={(e) => (currentClient.ICE = e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-6 mb-3">
                  <div className="form-group">
                    <label>Tél</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="Tel"
                      onChange={(e) => (currentClient.Tel = e.target.value)}
                    />
                  </div>
                  <span className="text-danger">{FromErrors.Tel || ""}</span>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Ville</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="Ville"
                      onChange={(e) => (currentClient.Ville = e.target.value)}
                    />
                  </div>
                  <span className="text-danger">{FromErrors.Tel || ""}</span>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Adresse</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="Adresse"
                      onChange={(e) => (currentClient.Adresse = e.target.value)}
                    />
                  </div>
                  <span className="text-danger">
                    {FromErrors.Adresse || ""}
                  </span>
                </div>
              </div>
            </form>
            <div className="modal-footer d-flex justify-content-between">
              <button
                onClick={() => {
                    props.upte && Newbtn();
                }}
                type="button"
                className="btn btn-outline-primary btn-sm"
                disabled={!props.upte}
              >
                Nouveau
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary mx-1 btn-sm"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
                <button
                  onClick={() => props.upte && VerifData()}
                  type="button"
                  className="btn btn-primary btn-sm"
                  disabled={!props.upte}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default EditClientModal;
