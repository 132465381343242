import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { searchClients, updateBon } from "../../../../services/ventes";
import { getNumBon } from "../../../../services/devis";
import { FormattNumber, formatDate, padNumber } from "../../../../services/global";
import ReglementBL from "./ReglementBL";
import DetailBL from "./DetailBL";
import ComboClient from "../../Clients/ComboClient";

const EditBLModal = forwardRef((props, ref) => {
  const OpenModalBtn = useRef();
  const FormHeader = useRef();
  const FormFooter = useRef();
  const ClientText = useRef();

  const ComboClient_Ref = useRef(null);
  const closeModalRef = useRef(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const [FromErrors, setFromErrors] = useState({});
  const [currentDetail, setCurrentDetail] = useState({});
  // const [DataClient, setDataClient] = useState([]);
  const [ShowClients, setShowClients] = useState(false);
  const [ShowReglement, setShowReglement] = useState(false);

  const setDetailPrint = (data) => {
    props.setDetailCurrentPrint(data);
  };



  const initialBon = {
    IdBL: 0,
    NumBL: 0,
    CodeClt: "",
    DateBL: formatDate(new Date()),
    Mod_HT: "0",
    Avoir: 1,
    MontantTTC: 0,
    Tva: 0,
    Facture: 0,
    Type: "A",
    Etat: "C",
    Description: "",
    BonNew: "",
    Note: "",
    UserID: 0,
    Client: "Comptant",
    Reglement: 0,
  };

  useImperativeHandle(ref, () => ({
    async OpenModal(Bon = initialBon) {
      setShowReglement(false);
      OpenModalBtn.current.click();

      setShowClients(false);

      setCurrentDetail(Bon);

      FormHeader.current.NumBL.value = Bon.NumBL;
      FormHeader.current.DateBL.value = Bon.DateBL;

      FormFooter.current.Description.value = Bon.Description;

      ClientText.current.value = Bon.Client;

      setFromErrors({});

      window.onafterprint = function () {
        try {
          const div = document.querySelectorAll(".bg-transparent");
          div[0].classList.remove("bg-transparent");
          div[0].classList.add("modal-backdrop");
        } catch { }
      };
    },
  }));

  const setTotalReg = (V) => {
    currentDetail.Reglement = V;
  };

  const setTotalBL = (V) => {
    // currentDetail.MontantTTC = V;
    setCurrentDetail({ ...currentDetail, MontantTTC: V }); // selement pour raffrichir le Montant TTC
    props.setCurrentPrint({ ...currentDetail, MontantTTC: V });
  };

  const imprimer = () => {
    const div = document.querySelectorAll(".modal-backdrop");
    div[0].classList.add("bg-transparent");
    div[0].classList.remove("modal-backdrop");
    window.print();
  };

  const VerifData = () => {
    if (!props.upte) {
      alert('Accès Non Autorisé')
    } else {
      let verif = {};
      // if(currentDetail.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}

      // setFromErrors(verif);
      if (Object.keys(verif).length === 0) {
        SaveData();
      }
    }

  };
  const SaveData = async () => {
    if (currentDetail.idBon === "") {
      //     AddBon(currentDetail).then((data) => {
      //         props.getData();
      //         currentDetail.idBon=data.lastID;
      //         console.log(data.message);
      //    })
    } else {
      updateBon(currentDetail)
        .then((message) => {
          console.log(message);
          setIsUpdate(false);
          getNumBon(currentDetail.IdBL)
            .then((data) => {
              console.log(data.NumBL)
              setCurrentDetail({ ...currentDetail, NumBL: data.NumBL })
              FormHeader.current.NumBL.value = padNumber(data.NumBL * 1, 4);
              props.setCurrentPrint({ ...currentDetail, NumBL: padNumber(data.NumBL * 1, 4) });

            })


        })
        .catch((e) => alert("Erreur Modification"));


    }
  };

  const doYouSaveBefore = (action) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Voulez vous enregister les modifications avant de quitter ") === true) {
      VerifData();
      setTimeout(() => {
        closeModalRef.current.click();
      }, "1000");

    } else {
      setIsUpdate(false);
      setTimeout(() => {
        closeModalRef.current.click();
      }, "1000");

    }
  }

  const SelectClient = (idClient, DesignationClient) => {
    setShowClients(false);
    if (idClient !== currentDetail.CodeClt) { setIsUpdate(true) }
    currentDetail.CodeClt = idClient;
    currentDetail.Client = DesignationClient;
    ClientText.current.value = DesignationClient;
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        type="button"
        ref={OpenModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModalList"
      >
        OpenModalBtn
      </button>

      <div
        className="modal fade not-print"
        id="myModalList"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-"
        aria-labelledby="myModalListLabel"
        aria-hidden="true"
      >
        <div
          className={
            "modal-dialog modal-dialog-centered " +
            (ShowReglement ? "" : "modal-xl")
          }
        >
          {/* bloc BL Détail */}
          <div 
          className={ShowReglement ? "d-none" : "modal-content py-2 rounded-0 rounded-bottom"}
          style={{borderTop:'3px #0d6efd solid', boxSizing:'border-box'}}
          >
            <span className="btn btn-primary rounded-0 rounded-top modal-edition-title d-none d-lg-block">Edition Bon de Livraison</span>
            
            <button
              type="button"
              onClick={() => {
                isUpdate && doYouSaveBefore('close');
                !isUpdate && props.switchPrint("List");
                !isUpdate && props.getData();
                !isUpdate && setShowClients(false);
              }}
              className="btn btn-light position-absolute top-0 start-100 translate-middle rounded-circle"
              data-bs-dismiss={isUpdate ? '' : 'modal'}
              aria-label="Close"
              ref={closeModalRef}
            >
              X
            </button>

            <form ref={FormHeader} className="modal-header">
              <div className="row w-100 m-auto">
                <div className="col-12 col-md-6 d-flex gap-2 mb-1 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control text-center"
                    disabled={true}
                    type="text"
                    id="NumBL"
                    readOnly
                  />
                  <input
                    className="form-control py-0"
                    ref={ClientText}
                    onFocus={() => props.upte && setShowClients(true)}
                    placeholder="Selectionner Client"
                    onChange={async (e) => {
                      props.upte && ComboClient_Ref.current.SerachDataClt(e.target.value);
                    }}
                  />
                  {ShowClients && (
                    <>
                      <button
                        className="btn btn-success btn-sm edit mx-1"
                        onClick={() => ComboClient_Ref.current.NewClt()}
                        name="edit"
                        type="button"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        className="btn btn-light btn-xs"
                        type="button"
                        onClick={() => {
                          setShowClients(false);
                          SelectClient(currentDetail.CodeFour, currentDetail.Client);
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>
                      </button>
                    </>
                  )}
                </div>

                <div className="col-6 col-md-4 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control"
                    type="date"
                    id="DateBL"
                    onChange={(e) => { currentDetail.DateBL = e.target.value; setIsUpdate(true) }
                    }
                  />
                </div>

                <div className="col-6 col-md-2 justify-content-end d-flex gap-2 py-1 ">
                  <input
                    onClick={() => VerifData()}
                    value="Enregistrer"
                    className={
                      currentDetail.Avoir === "1"
                        ? "btn btn-primary btn-sm"
                        : "btn btn-danger btn-sm"
                    }
                    type="button"
                    disabled={!props.upte}
                  />
                </div>
              </div>
            </form>
            {ShowClients && (
              <ComboClient
                ref={ComboClient_Ref}
                SelectClient={SelectClient}
              />
            )}
            <div className="modal-body  m-0 py-0">
              <DetailBL
                IdBL={currentDetail.IdBL}
                Sens={currentDetail.Avoir}
                getData={props.getData}
                setTotalBL={setTotalBL}
                setDetailPrint={setDetailPrint}
                imprimer={imprimer}
                upte={props.upte}
              />


            </div>
            <form ref={FormFooter} className="modal-footer d-flex flex-column py-0">
              <div className="row w-100">
                <div className="col-12 col-lg-6 input-group-sm d-flex p-0">
                  <input
                    className="form-control"
                    type="text"
                    id="Description"
                    placeholder="Edtion Commentaire"
                    onChange={(e) =>
                      {currentDetail.Description = e.target.value; setIsUpdate(true)}
                    }
                  />
               
                  
                  <button
                    className="btn btn-success btn-sm mx-2"
                    onClick={() => props.upte && setShowReglement(true)}
                    type="button"
                    disabled={!props.upte}
                  >
                    <i className="fa fa-usd"></i>
                  </button>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-between align-items-center small px-0 mt-1">
                  <b className="text-nowrap">
                    Réglement : {FormattNumber(currentDetail.Reglement * 1, 2)}
                  </b>
                  <b className="mx-2 h5 text-primary text-end text-nowrap">
                    Total : {FormattNumber(currentDetail.MontantTTC * 1, 2)}
                  </b>
                </div>
              </div>
            </form>
          </div>

          {/* bloc Réglement BL */}
          <div className={!ShowReglement ? "d-none" : "modal-content"}>
            <ReglementBL
              idBon={currentDetail.IdBL}
              CodeClt={currentDetail.CodeClt}
              getData={props.getData}
              setTotalReg={setTotalReg}
              setShowReglement={setShowReglement}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditBLModal;
