import { Routes, Route } from "react-router-dom";
import Page404 from '../../pages/Page404';
import Login from '../../pages/Login';
import { useSelector } from 'react-redux';


// import Home from "../../pages/Home";
import { ListeProduit, Etatperiodique, Graphe, Inventaires, ProdMouvement } from '../../pages/Produits';
import { BonsAchats, CommandesAchats, FacturesAchats, Fournisseurs, ReglementsAchats, FournisseurMouvement } from '../../pages/Achats';
import { BonsLivraisons, DevisVentes, FacturesVentes, Clients, ReglementsVentes, ClientMouvement, Comptoir } from '../../pages/Ventes';
import { CaisseJournal, CaisseSuivi, BanquesOperations } from '../../pages/Tresorie';
import { UsersList } from '../../pages/Params';
import { UserRoles } from '../../pages/Params';

import './RSide.css';


const RSide = () => {
    let pageTitle = useSelector((state) => state.LayoutReducer.pageTitle);
    const currentUser = useSelector((state) => state.userReducer.currentUser);


    const getRole = (roleType) => {
        try {
            return (currentUser[roleType][0] === '1' || currentUser.role * 1 < 2) 
        }
        catch {
            return false
        }
    }

    const getRoles = (roleType) => {
        try {
            if (currentUser.role * 1 < 2)
            return  {upte:true,del:true}
        else            
            return {upte:currentUser[roleType][1] === '1',del:currentUser[roleType][2] === '1'}
        }
        catch {
            return {upte:false,del:false}
        }
    }

    return (

        <div id="RSide">

            <section id="Rside-container" className={pageTitle !== "Login" ? "my-2 px-2" : ""}>
                <Routes>
                    <Route path="/" element={<Page404 />} />
                    <Route path="/produits/liste" element={getRole('prodList') ? <ListeProduit pageRoles={getRoles('prodList')} /> : <Page404 />} />
                    <Route path="/produits/etatperiodique" element={getRole('prodPeriodique') ? <Etatperiodique pageRoles={getRoles('prodPeriodique')} /> : <Page404 />} />
                    <Route path="/produits/inventaires" element={getRole('prodInvenatire') ? <Inventaires pageRoles={getRoles('prodInvenatire')} /> : <Page404 />} />
                    <Route path="/produits/graphe" element={getRole('prodGraphique') ? <Graphe pageRoles={getRoles('prodGraphique')} /> : <Page404 />} />
                    <Route path="/produits/:id" element={getRole('prodList') ? <ProdMouvement pageRoles={getRoles('prodList')} /> : <Page404 />} />

                    <Route path="/achats/liste" element={getRole('AchatsBon') ? <BonsAchats pageRoles={getRoles('AchatsBon')} /> : <Page404 />} />
                    <Route path="/achats/commandes" element={getRole('AchatCommande') ? <CommandesAchats pageRoles={getRoles('AchatCommande')} /> : <Page404 />} />
                    <Route path="/achats/factures" element={getRole('AchatFacture') ? <FacturesAchats pageRoles={getRoles('AchatFacture')} /> : <Page404 />}  />
                    <Route path="/achats/fournisseurs" element={getRole('AchatFournisseur') ? <Fournisseurs pageRoles={getRoles('AchatFournisseur')} /> : <Page404 />}  />
                    <Route path="/achats/fournisseur/:id" element={getRole('AchatFournisseur') ? <FournisseurMouvement pageRoles={getRoles('AchatFournisseur')} /> : <Page404 />}  />
                    <Route path="/achats/reglements" element={getRole('AchatReglement') ? <ReglementsAchats pageRoles={getRoles('AchatReglement')} /> : <Page404 />}  />

                    <Route path="/ventes/liste"  element={getRole('VenteBon') ? <BonsLivraisons pageRoles={getRoles('VenteBon')} /> : <Page404 />}   />
                    <Route path="/ventes/devis"  element={getRole('VenteDevis') ? <DevisVentes pageRoles={getRoles('VenteDevis')} /> : <Page404 />}   />
                    <Route path="/ventes/factures"  element={getRole('VenteFacture') ? <FacturesVentes pageRoles={getRoles('VenteFacture')} /> : <Page404 />} />
                    <Route path="/ventes/clients"  element={getRole('VenteClient') ? <Clients pageRoles={getRoles('VenteClient')} /> : <Page404 />}  />
                    <Route path="/vente/client/:id"  element={getRole('VenteClient') ? <ClientMouvement pageRoles={getRoles('VenteClient')} /> : <Page404 />}  />
                    <Route path="/ventes/reglements"  element={getRole('VenteReglement') ? <ReglementsVentes pageRoles={getRoles('VenteReglement')} /> : <Page404 />}   />
                    <Route path="/ventes/comptoir"  element={getRole('VenteBon') ? <Comptoir pageRoles={getRoles('VenteReglement')} /> : <Page404 />}   />

                    <Route path="/tresorie/caisse/journal" element={getRole('JournalCaisse') ? <CaisseJournal pageRoles={getRoles('JournalCaisse')} /> : <Page404 />} />
                    <Route path="/tresorie/caisse/suivi" element={getRole('SuiviCaisse') ? <CaisseSuivi pageRoles={getRoles('SuiviCaisse')} /> : <Page404 />}  />
                    <Route path="/tresorie/banques/operations" element={getRole('Banque') ? <BanquesOperations pageRoles={getRoles('Banque')} /> : <Page404 />}  />

                    <Route path="/params/users" element={currentUser.role && currentUser.role * 1 < 2 ? <UsersList /> : <Page404 />} />
                    <Route path="/params/users/:id" element={<UserRoles />} />

                    <Route path="/login" element={<Login />} />

                    <Route path="*" element={<Page404 />} />
                </Routes>
            </section>
        </div>
    );
};

export default RSide;