
import axios from "axios";
import { deconnect } from './global';

const baseApi="https://api.gstok.com";



export const getProductListe = async () => {                 
    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
    })

    
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
        
};

export const searchProduct = async (seachValue) => {              
    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'searchItem',
        token:sessionStorage.getItem("token"),
        search: seachValue
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const updateArticle = async (current) => { 
    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'update',
        token:sessionStorage.getItem("token"),
        idArticle: current.idArticle,
        RefArticle: current.RefArticle,
        FamilleRef: current.FamilleRef,
        Designation: current.Designation,
        PrixATTC: current.PrixATTC,
        TTva: current.TTva,
        PrixVTTC: current.PrixVTTC,
        Etat: current.Etat,
        user: 0,
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const AddArticle = async (current,TypeProduit='A') => {
    // await console.log(current);
    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'insert', 
        token:sessionStorage.getItem("token"),       
        RefArticle: current.RefArticle,
        FamilleRef: current.FamilleRef,
        Designation: current.Designation,
        PrixATTC: current.PrixATTC,
        TTva: current.TTva,
        PrixVTTC: current.PrixVTTC,
        TypeProduit: TypeProduit,
        user: 0,
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const delelteArticle = async (current) => { 
    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'delete',
        token:sessionStorage.getItem("token"),
        idArticle: current.idArticle,           
        user: 0,
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
    
};

export const getFamilles = async () => {              
    const data = await axios.post(`${baseApi}/Familles`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
    })
    
    // console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const insertFamilles = async (DesignationFamille,TypeProduit='A') => {              
    const data = await axios.post(`${baseApi}/Familles`, {
        action: 'insert',
        token:sessionStorage.getItem("token"),
        Designation: DesignationFamille,
        TypeProduit: TypeProduit,
        user: 0,
    })
    
    
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const searchFamilles = async (seachValue="") => {              
    const data = await axios.post(`${baseApi}/Familles`, {
        action: 'searchItem',
        token:sessionStorage.getItem("token"),
        search: seachValue
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};


export const getSingleArticle = async (idArticle) => {             
    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'fetchSingle',
        token:sessionStorage.getItem("token"),
        idArticle: idArticle,
    })
    // console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const getDataMouvement = async (periode,idArticle) => { 
    sessionStorage.setItem('Debut',periode.debut);
    sessionStorage.setItem('Fin',periode.fin);

    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'StatData',
        token:sessionStorage.getItem("token"),
        idArticle:idArticle,
        Debut: periode.debut,
        Fin: periode.fin,
    })
    // console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};
export const getStockIni = async (periode,idArticle) => {    
    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'getStockIni',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,
        idArticle: idArticle,
    });
    // console.log(data.data[0].SIni);
    if (data.data && data.status===200){return data.data[0].SIni}
    else {deconnect();}

}

export const getPeriodiqueStockListe = async (periode) => { 
    sessionStorage.setItem('Debut',periode.debut);
    sessionStorage.setItem('Fin',periode.fin);

    const data = await axios.post(`${baseApi}/Articles`, {
        action: 'getPeriodiqueStockListe',
        token:sessionStorage.getItem("token"),        
        Debut: periode.debut,
        Fin: periode.fin,
    })
    // console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};
