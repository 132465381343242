import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDownloadExcel } from "react-export-table-to-excel";

import {
  getInventaireListe,
  AddInventaire,
  getSingleInventaire,
  deleteInventaire,
} from "../../../services/inventaires";
import {
  isLogin,
  GetDate,
  padNumber,
  reformatDate,
  formatDate,
  sortBy,
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";

import EditModal from "./EditModal";
import PeriodeDateur from "../../../components/widgets/periode/periode";

let sortParm = "";
let sortType = "S";
let sortSens = 1;

const Inventaires = ({ pageRoles }) => {
  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [OrderText, SetOrderText] = useState("Ordre ");
  const [SearchText, SetSearchText] = useState("");

  const [currentPrint, setCurrentPrint] = useState({
    IdInventaire: 0,
    DateInventaire: formatDate(new Date()),
    Bon: 0,
    Commentaire: "",
    Familles: "",
  });

  const [currentDetailPrint, setDetailCurrentPrint] = useState([]);

  const EditModal_Ref = useRef();
  const PeriodeDateurRef = useRef();
  const ListePrintRef = useRef();
  const DetailPrintRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { onDownload } = useDownloadExcel({
    currentTableRef: ListePrintRef.current,
    filename: "Liste Inventaires",
    // sheet: 'Users'
  });

  const getSortedData = (
    param = sortParm,
    TypeData = sortType,
    data = [...DataList],
    sens = 0
  ) => {
    if (param !== "") {
      sens = sens === 0 ? (sortParm === param ? -1 * sortSens : 1) : sortSens;
      sortParm = param;
      sortType = TypeData;
      sortSens = sens;

      setDataList(sortBy(param, TypeData, data, sens));
    }
  };

  const switchPrint = (doc) => {
    if (doc === "Detail") {
      ListePrintRef.current.classList.remove("print");
      DetailPrintRef.current.classList.add("print");
    } else {
      ListePrintRef.current.classList.add("print");
      DetailPrintRef.current.classList.remove("print");
    }
  };

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      setLoading(true);
      const data = await getInventaireListe(Periode);
      setDataList(data);
      setLoading(false);
      getSortedData(undefined, undefined, data, sortSens);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/produits/inventaires");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getInventaireListe(periode);
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Liste des Inventaires"));
  }, [dispatch, navigate]);

  const AddNewInventaire = async () => {
    setLoading(true);
    AddInventaire()
      .then((LastID) => {
        setLoading(false);
        return getSingleInventaire(LastID);
      })
      .then((Inventaire) => EditModal_Ref.current.OpenModal({ ...Inventaire }))
      .catch((e) => {
        alert("Erreur Ajout");
        console.log(e);
      });
  };

  const delelteInventaire = (Inventaire) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Êtes vous sûr de vouloir supprimer l'Inventaire N°: \n ${padNumber(
          Inventaire.IdInventaire,
          4
        )} ?`
      )
    ) {
      setLoading(true);
      deleteInventaire(Inventaire.IdInventaire)
        .then((message) => {
          setLoading(false);
          getData();
          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  return (
    isLogin() && (
      <>
        <div className="row mb-3 not-print">
          <div className="col-12 col-md-6 d-flex mb-1  ">
            <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />
            <div className="btn-group ms-2">
              <button
                className="btn btn-outline-success btn-sm"
                onClick={() => onDownload()}
              >
                <i className="fa fa-file-excel-o"></i>
                <div className="infobulle btn bg-light border border-2 btn-sm">
                  Excel Export
                </div>
              </button>

              
            </div>

            <div className="dropdown">
              <button
                className="btn dropdown-toggle btn-sm d-none d-lg-block"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {OrderText}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      getSortedData("IdInventaire", "N", undefined);
                      SetOrderText("Numéro");
                    }}
                  >
                    Numéro
                  </button>
                </li>

                <li>
                  <button
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      getSortedData("DateInventaire", "D", undefined);
                      SetOrderText("Date");
                    }}
                  >
                    Date Inventaire
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 text-end d-flex gap-2 ">
            <div className="input-group">
              <input
                className="form-control"
                onChange={(e) => SetSearchText(e.target.value)}
                placeholder="Recherche"
              />
              <span className="input-group-text pe-2">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <button
              className="btn btn-outline-primary btn-sm" 
              onClick={() => pageRoles.upte && AddNewInventaire()}
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <TableOne vh="vh-scroled-1">
          {DataList.map(
            (row, i) =>
              row.Commentaire.toLowerCase().includes(
                SearchText.toLowerCase()
              ) && (
                <tr key={i}>
                  <td
                    onDoubleClick={() => {  
                      switchPrint("Detail");
                      setCurrentPrint({ ...row });
                      EditModal_Ref.current.OpenModal({ ...row });
                    
                    }}
                  >
                    <div className="row align-items-center">
                      <div className="col-6 col-md-2">
                        <b>Num : {padNumber(row.IdInventaire, 4)}</b>
                      </div>

                      <div className="col-6 col-md-2 text-end">
                        {reformatDate(row.DateInventaire)}
                      </div>
                      <div className="col-12 col-md-6">{row.Commentaire}</div>

                      <div className="col-12 col-md-2 text-end">
                        <button
                          className="btn btn-primary btn-sm mx-1" 
                          onClick={() => { 
                            switchPrint("Detail");
                            setCurrentPrint({ ...row });
                            EditModal_Ref.current.OpenModal({ ...row });
                          }}
                        >
                          <i className="fa fa-edit"></i>
                        </button>

                        <button
                          className="btn btn-warning btn-sm delete" disabled={!pageRoles.del}
                          onClick={() => pageRoles.del && delelteInventaire(row)}
                        >
                          <i className="fa fa-trash-o"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
          )}
        </TableOne>

        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-50">
                    Doc :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.length}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <EditModal
          ref={EditModal_Ref}
          getData={getData}
          switchPrint={switchPrint}
          setCurrentPrint={setCurrentPrint}
          setDetailCurrentPrint={setDetailCurrentPrint}
          upte={pageRoles.upte}
        />

        <Spinner isLoading={isLoading} />

        
          <table ref={ListePrintRef} className="table w-100 border table-bordered small print  ">
            <thead>
              <tr className="w-100 text-center">
                <th colSpan={3}>
                  <div className="d-flex justify-content-between">
                    <span>Liste Inventaires</span>

                    <span>le {reformatDate(formatDate(Date()))}</span>
                  </div>
                </th>
              </tr>
              <tr className="w-100 text-center ">
                <th>Num</th>
                <th>Date</th>
                <th>Familles</th>
              </tr>
            </thead>
            <tbody>
              {DataList.map((row, i) => (
                <tr key={i}>
                  <td className="text-center">
                    <b>{padNumber(row.IdInventaire, 4)}</b>
                  </td>
                  <td className="text-center" style={{ minWidth: "100px" }}>
                    {row.DateInventaire}
                  </td>
                  <td className="w-100">{row.Commentaire}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="page-break">
              <tr>
                <th colSpan={3} className="text-end">
                  Nombre Inventaire : {DataList.length}
                </th>
              </tr>
            </tfoot>
          </table>
        

        <table ref={DetailPrintRef} className="table table-borderless d-none">
          <thead>
            <tr className="w-100 text-center ">
              <td colSpan={6}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end" colSpan={2}>
                        Date Inventaire :{" "}
                        {reformatDate(currentPrint.DateInventaire)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "200px", height: "100px" }}
                        className="border"
                      >
                        Logo
                      </td>
                      <td>
                        Familles : <b>{currentPrint.Commentaire}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-end">
                        N° Inventaire :
                        <b>
                          {padNumber(currentPrint.IdInventaire * 1, 4)} /
                          {currentPrint.DateInventaire.substr(-2)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="text-center  bg-secondary text-white  rounded-start">
                N°
              </td>
              <th className="w-50 text-center bg-secondary text-white border border-0 border-top border-white">
                Désignation
              </th>
              <th
                className="text-center  bg-secondary text-white  "
                style={{ minWidth: "70px" }}
              >
                Stock
              </th>
              <th
                className="text-center bg-secondary text-white "
                style={{ minWidth: "70px" }}
              >
                INV
              </th>
            </tr>
          </thead>
          <tbody className="small">
            {currentDetailPrint.map((row, i) => (
              <tr key={i} className="border">
                <td className="text-center border">
                  <b>{i + 1}</b>
                </td>
                <td className="w-75">{row.Designation}</td>

                <td className="text-center border">{row.qteStock}</td>
                <td
                  className={row.qteInv === "0" ? "text-white" : "text-center"}
                >
                  {row.qteInv}
                </td>
              </tr>
            ))}
            <tr className="w-100 text-center ">
              <td colSpan={4}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end">-</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
          <tfoot className="page-break">
            <tr>
              <th colSpan={4}>
                <div className="d-flex justify-content-between">
                  <span></span>
                  <span id="pageFooter"></span>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    )
  );
};

export default Inventaires;
