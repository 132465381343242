import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import {
  updateClient,
  AddClient,
} from "../../../services/clients";

const EditClient = forwardRef((props, ref) => {
  const FormContainer = useRef();

  const [FromErrors, setFromErrors] = useState({});
  const [currentClient, setCurrentClient] = useState({});
  const [ShowDetail, setShowDetail] = useState(false);

  const initialClient = {
    IdClient: "",
    Client: "",
    ICE: "",
    Adresse: "",
    Tel: "",
    Mobile: "",
    Solde: "0",
    Etat: "A",
  };

  useImperativeHandle(ref, () => ({
    OpenEditData(Client = initialClient) {
      setShowDetail(true);
      setCurrentClient(Client);

      const form = FormContainer.current;
      form.Client.value = Client.Client;
      form.ICE.value = Client.ICE;
      form.Adresse.value = Client.Adresse;
      form.Tel.value = Client.Tel;
      form.Mobile.value = Client.Mobile;      
    },
  }));

  const VerifData = () => {
    let verif = {};
    if (currentClient.Client === "")
      verif = { ...verif, Client: "Erreur Client" };

    setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    console.log(currentClient.IdClient);
    if (currentClient.IdClient === "") {
      AddClient(currentClient).then((data) => {
        props.getData();
        currentClient.IdClient = data.lastID;

        console.log(data.message);
      });
    } else {
      console.log(currentClient);
      updateClient(currentClient)
        .then((message) => {
          props.getData();

          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const Newbtn = () => {
    setCurrentClient(initialClient);
    const form = FormContainer.current;
    form.Client.value = initialClient.Client;
    form.ICE.value = initialClient.ICE;
    form.Adresse.value = initialClient.Adresse;
    form.Tel.value = initialClient.Tel;
    form.Mobile.value = initialClient.Mobile;
  };

  return (    
    <div
      className={
        ShowDetail
          ? "bg-dark bg-opacity-25 modal-fullscreen w-100 fixed-top d-flex align-items-center justify-content-center "
          : "d-none"
      }
      style={{ position: "fixed", zIndex: "99", left: "0px", top: "-91px" }}
    >
      <div
        className="bg-white border rounded-3 position-relative shadow-lg p-3"
        style={{ width: "500px", minHeight: "230px" }}
      >
        <div className="d-flex py-2 border-bottom justify-content-between">
          <h5 className="modal-title" id="myModalLabel">
            Edition Client
          </h5>

          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setShowDetail(false)}
          ></button>
        </div>
        <form ref={FormContainer} className="my-2">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>Client</label>
                <input
                  id="Client"
                  className="form-control"
                  type="text"
                  onChange={(e) =>
                    (currentClient.Client = e.target.value)
                  }
                />
              </div>
              <span className="text-danger">
                {FromErrors.Client || ""}
              </span>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">ICE </label>
                <input
                  id="ICE"
                  className="form-control"
                  type="text"
                  onChange={(e) => (currentClient.ICE = e.target.value)}
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label>Tél</label>
                <input
                  className="form-control"
                  type="text"
                  id="Tel"
                  onChange={(e) => (currentClient.Tel = e.target.value)}
                />
              </div>
              <span className="text-danger">{FromErrors.Tel || ""}</span>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Mobile</label>
                <input
                  className="form-control"
                  type="text"
                  id="Mobile"
                  onChange={(e) => (currentClient.Mobile = e.target.value)}
                />
              </div>
              <span className="text-danger">{FromErrors.Tel || ""}</span>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>Adresse</label>
                <input
                  className="form-control"
                  type="text"
                  id="Adresse"
                  onChange={(e) =>
                    (currentClient.Adresse = e.target.value)
                  }
                />
              </div>
              <span className="text-danger">{FromErrors.Adresse || ""}</span>
            </div>
          </div>
        </form>
        <div
          className="d-flex justify-content-between"
          style={{ maxHeight: "30px" }}
        >
          <button
            onClick={() => {
              Newbtn();
            }}
            type="button"
            className="btn btn-outline-warning text-black btn-sm"
          >
            Nouveau
          </button>
          <div>
            <button
              type="button"
              className="btn btn-secondary mx-1 btn-sm"
              onClick={() => setShowDetail(false)}
            >
              Fermer
            </button>

            <button
              onClick={() => VerifData()}
              type="button"
              className="btn btn-primary btn-sm"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditClient;
