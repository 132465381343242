
import axios from "axios";
import { deconnect } from './global';

const baseApi="https://api.gstok.com"

export const AddBonLivraion = async (Avoir) => { 
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'AddNew',
        token:sessionStorage.getItem("token"),
        Avoir: Avoir,
    })

    // console.log(data)
    if (data.data.LastID && data.status===200){return data.data.LastID}
    else {deconnect();}
    
};

export const getSingleBL = async (IdBL) => {    
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'single',
        token:sessionStorage.getItem("token"),
        IdBL:IdBL,        
    });
    //  console.log(data.data)
     if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const getBonLivraionListe = async (periode) => {
    sessionStorage.setItem('Debut',periode.debut);
    sessionStorage.setItem('Fin',periode.fin);
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,        
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const searchBonLivraion = async (seachValue,periode) => {              
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'searchItem',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,
        search: seachValue
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};


export const deleteBonLivraion = async (IdBL) => { 
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'delete',
        token:sessionStorage.getItem("token"),
        IdBL: IdBL,
    })

    // console.log(data)
    if (data.data.message && data.status===200){return data.data.message}
    else {deconnect();}
    
};

// Reglement Client
export const getDataListReg = async (IdBL) => {
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'GetReglementList',
        token:sessionStorage.getItem("token"),
        idBon: IdBL,       
    });
    // console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};



export const AddReglementBL = async (current,IdBL,CodeClt) => {     
    const data = await axios.post(`${baseApi}/VentesBons`, {  
        action: 'insertReg',
        token:sessionStorage.getItem("token"),      
        CodeClt: CodeClt,
        DateReg: current.DateReg,
        DateEch: current.DateEch,
        Montant: current.Montant,
        ModeReg: current.ModeReg,
        Banque: current.Banque,
        NumDoc: current.NumDoc,
        Type: current.Type,
        Sens: current.Sens,
        idBon: IdBL,
        Etat: current.Etat,
        TypeDoc: current.TypeDoc,
    })
        // console.log(data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const deleteReglement = async (IdRegBL) => { 
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'deleteReg',
        token:sessionStorage.getItem("token"),
        IdRegBL: IdRegBL,
    })

    // console.log(data)
    if (data.data.message && data.status===200){return data.data.message}
    else {deconnect();}
    
};



export const getSingleReglement = async (IdBL) => {    
    const data = await axios.post(`${baseApi}/VentesBons`, {
        action: 'single',
        token:sessionStorage.getItem("token"),
        IdBL:IdBL,        
    });
    //  console.log(data.data)
     if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const getReglementList = async (periode) => {
    const data = await axios.post(`${baseApi}/ReglementClient`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,       
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const searchReglement = async (seachValue,periode) => {              
    const data = await axios.post(`${baseApi}/ReglementClient`, {
        action: 'searchItem',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,
        search: seachValue
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const AddReglement = async (current) => {     
    const data = await axios.post(`${baseApi}/ReglementClient`, {  
        action: 'insert',
        token:sessionStorage.getItem("token"),      
        CodeClt: current.CodeClt,
        DateReg: current.DateReg,
        DateEch: current.DateEch,
        Montant: current.Montant,
        ModeReg: current.ModeReg,
        Banque: current.Banque,
        NumDoc: current.NumDoc,
        Type: current.Type,
        Sens: current.Sens,
        idBon: 0,
        Etat: current.Etat,
        TypeDoc: current.TypeDoc,
    })
        // console.log(data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const UpdateReglement = async (current) => { 
    const data = await axios.post(`${baseApi}/ReglementClient`, {
        action: 'update',
        token:sessionStorage.getItem("token"),
        
        CodeClt: current.CodeClt,
        DateReg: current.DateReg,
        DateEch: current.DateEch,
        Montant: current.Montant,
        ModeReg: current.ModeReg,
        IdRegBL: current.IdRegBL,
        Banque: current.Banque,
        NumDoc: current.NumDoc,
    })

    // console.log(data)
    if (data.data.message && data.status===200){return data.data.message}
    else {deconnect();}
    
};

//Detail BL

export const updateBon = async (current) => { 
// console.log(current)
    const data = await axios.post(`${baseApi}/VentesBonsDetail`, {
        action: 'updateBon',
        token:sessionStorage.getItem("token"),        
        IdBL: current.IdBL,
        NumBL: current.NumBL,
        DateBL: current.DateBL,
        CodeClt: current.CodeClt,
        MontantTTC: current.MontantTTC,
        Mod_HT: current.Mod_HT,
        Avoir: current.Avoir,
        Tva: current.Tva,        
        Description: current.Description,       
        
    })

    //   console.log(data.data)
    if (data.data && data.status===200){return data.data.message}
    else {deconnect();}
}

export const searchClients = async (seachValue) => {              
    const data = await axios.post(`${baseApi}/VentesClients`, {
        action: 'searchItem',
        token:sessionStorage.getItem("token"),
        search: seachValue
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const getDetailData = async (IdBL) => {
    const data = await axios.post(`${baseApi}/VentesBonsDetail`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
        IdBL: IdBL,       
    });
    // console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const AddDetailData = async (Detail) => {    
    const data = await axios.post(`${baseApi}/VentesBonsDetail`, {
        action: 'insert',
        token:sessionStorage.getItem("token"),        
        CodeBL:Detail.codeBL, 
        CodeArticle:Detail.CodeArticle,
        Quantite:Detail.Quantite,        
        Prix:Detail.Prix,
        TTva:Detail.TTva,
        Remise:Detail.Remise,
        Sens:Detail.sens       
    });
    //  console.log(data.data.message)
    if (data.data && data.status===200){return data.data.message}
    else {deconnect();}
};

export const updateDetailData = async (Detail) => {
    const data = await axios.post(`${baseApi}/VentesBonsDetail`, {
        action: 'update',
        token:sessionStorage.getItem("token"),
        IdDetailBL:Detail.IdDetailBL,
        CodeBL:Detail.CodeBL, 
        CodeArticle:Detail.CodeArticle,
        Quantite:Detail.Quantite,        
        Prix:Detail.Prix,
        TTva:Detail.TTva,
        Remise:Detail.Remise,        
    });
    //  console.log(data.data.message)
    if (data.data && data.status===200){return data.data.message}
    else {deconnect();}
};

export const deleteDetailData = async (IdDetailBL) => {
    const data = await axios.post(`${baseApi}/VentesBonsDetail`, {
        action: 'delete',
        token:sessionStorage.getItem("token"),
        IdDetailBL:IdDetailBL,
             
    });
    //  console.log(data.data.message)
    if (data.data && data.status===200){return data.data.message}
    else {deconnect();}
};

