import { useState,forwardRef, useRef, useImperativeHandle } from "react";
import { AddUser, UpdateUser } from '../../../services/params';


const EditUserModal = forwardRef((props, ref) => {

    const OpenUserModalBtn = useRef();
    const FormContainer = useRef(); 
    
    const [FromErrors, setFromErrors] = useState({});   
    const [currentUser, setCurrentUser] = useState({});

    const initialUser  = {
        "idUser":0,
        "userName" : '',
        "email" : '',
        "password" : ''        
      }   
      
    useImperativeHandle(ref,  () => ({
        
        async OpenModal (User = initialUser) {             
            OpenUserModalBtn.current.click();           
            // console.log(User)
            setCurrentUser(User); 

             const form = FormContainer.current.getElementsByTagName('form')[0]; 

                form.userName.value = User.userName;
                form.email.value = User.email;
                form.password.value = User.password;               
            
                
            setFromErrors({})


            
        }

    }))

    const VerifData = () => {        

        let verif = {};        
        if(currentUser.password ===0) verif = {...verif,password:'Erreur password'}                           

        setFromErrors(verif);        
        if(Object.keys(verif).length === 0){ SaveData()}

    }
    const SaveData = () =>  {
         if (currentUser.idUser===0) {
            AddUser(currentUser).then( async (data) => {
                props.getData();

                currentUser.idUser = data.lastId;
    
                console.log(data.message);
           })
         }
         else
         {
            UpdateUser(currentUser).then((message) => {
                props.getData();
            });
         }
         
        
    }

  
    
   
      
    return (
        <>
            <button type="button" ref={OpenUserModalBtn} className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#myModal">
                OpenUserModalBtn
            </button>
            
            <div ref={FormContainer} className="modal fade" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Edition User</h5>
                            <button  type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form className="modal-body">
                        
                            <div className="row my-2">
                                
                                <div className="col-6">
                                <div className="form-group">
                                    <label>Nom de l'utilisateur</label>
                                    <input className="form-control" type="text" id="userName"
                                    onChange={e => currentUser.userName = e.target.value } />
                                </div>
                                </div>

                                <div className="col-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input className="form-control" type="email" id="email" 
                                    onChange={e => currentUser.email = e.target.value } />                                
                                </div>
                                </div>

                            
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>password</label>
                                        <input className="form-control" type="text" id="password" 
                                        onChange={e => currentUser.password = e.target.value }
                                        />
                                    </div>
                                    <span className="text-danger">{FromErrors.password || ''}</span>
                                </div>
                                
                                
                            </div>
                        
                          
                            
                            
                        </form>
                        <div className="modal-footer">                           
                            
                                <button type="button" className="btn btn-outline-secondary mx-1" data-bs-dismiss="modal">Fermer</button>
                                <button  onClick={() => VerifData()} type="button" className="btn btn-outline-success" >Enregistrer</button>
    
                            
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    );
});

export default EditUserModal;

