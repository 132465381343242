import axios from "axios";
import { deconnect } from "./global";

const baseApi = "https://api.gstok.com";

export const AddBonAchat = async (Avoir) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "AddNew",
    token: sessionStorage.getItem("token"),
    Avoir: Avoir,
  });

  // console.log(data)
  if (data.data.LastID && data.status === 200) {
    return data.data.LastID;
  } else {
    deconnect();
  }
};

export const getSingleBA = async (IdBA) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "single",
    token: sessionStorage.getItem("token"),
    IdBA: IdBA,
  });
  //  console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getBonAchatListe = async (periode) => {
  sessionStorage.setItem("Debut", periode.debut);
  sessionStorage.setItem("Fin", periode.fin);
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
  });
    // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const searchBonAchat = async (seachValue, periode) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
    search: seachValue,
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const deleteBonAchat = async (IdBA) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "delete",
    token: sessionStorage.getItem("token"),
    IdBA: IdBA,
  });

  // console.log(data)
  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

// Reglement Fournisseur
export const getDataListReg = async (IdBA) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "GetReglementList",
    token: sessionStorage.getItem("token"),
    idBon: IdBA,
  });
  // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const AddReglementBA = async (current, IdBA, CodeFour) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "insertReg",
    token: sessionStorage.getItem("token"),
    CodeFour: CodeFour,
    DateReg: current.DateReg,
    DateEch: current.DateEch,
    Montant: current.Montant,
    ModeReg: current.ModeReg,
    Banque: current.Banque,
    NumDoc: current.NumDoc,
    Type: current.Type,
    Sens: current.Sens,
    idBon: IdBA,
    Etat: current.Etat,
    TypeDoc: current.TypeDoc,
  });
  // console.log(data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const deleteReglement = async (IdRegBA) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "deleteReg",
    token: sessionStorage.getItem("token"),
    IdRegBA: IdRegBA,
  });

  // console.log(data)
  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const getSingleReglement = async (IdBA) => {
  const data = await axios.post(`${baseApi}/AchatsBons`, {
    action: "single",
    token: sessionStorage.getItem("token"),
    IdBA: IdBA,
  });
  //  console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getReglementList = async (periode) => {
  const data = await axios.post(`${baseApi}/ReglementFournisseur`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
  });
  // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const searchReglement = async (seachValue, periode) => {
  const data = await axios.post(`${baseApi}/ReglementFournisseur`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
    search: seachValue,
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const AddReglement = async (current) => {
  const data = await axios.post(`${baseApi}/ReglementFournisseur`, {
    action: "insert",
    token: sessionStorage.getItem("token"),
    CodeFour: current.CodeFour,
    DateReg: current.DateReg,
    DateEch: current.DateEch,
    Montant: current.Montant,
    ModeReg: current.ModeReg,
    Banque: current.Banque,
    NumDoc: current.NumDoc,
    Type: current.Type,
    Sens: current.Sens,
    idBon: 0,
    Etat: current.Etat,
    TypeDoc: current.TypeDoc,
  });
  // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const UpdateReglement = async (current) => {
  const data = await axios.post(`${baseApi}/ReglementFournisseur`, {
    action: "update",
    token: sessionStorage.getItem("token"),

    CodeFour: current.CodeFour,
    DateReg: current.DateReg,
    DateEch: current.DateEch,
    Montant: current.Montant,
    ModeReg: current.ModeReg,
    IdRegBA: current.IdRegBA,
    Banque: current.Banque,
    NumDoc: current.NumDoc,
  });

  // console.log(data)
  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

//Detail BA

export const updateBon = async (current) => {
  //  console.log(current)
  const data = await axios.post(`${baseApi}/AchatsBonsDetail`, {
    action: "updateBon",
    token: sessionStorage.getItem("token"),
    IdBA: current.IdBA,
    NumBA: current.NumBA,
    DateBA: current.DateBA,
    CodeFour: current.CodeFour,
    MontantTTC: current.MontantTTC,
    Mod_HT: current.Mod_HT,
    Avoir: current.Avoir,    
    Description: current.Description   
  });

  // console.log(data.data.message)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const searchFournisseurs = async (seachValue) => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
    search: seachValue,
  });

  // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getDetailData = async (IdBA) => {
  const data = await axios.post(`${baseApi}/AchatsBonsDetail`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    IdBA: IdBA,
  });
  // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const AddDetailData = async (Detail) => {
  // console.log(Detail)
  const data = await axios.post(`${baseApi}/AchatsBonsDetail`, {
    action: "insert",
    token: sessionStorage.getItem("token"),
    CodeBA: Detail.CodeBA,
    CodeArticle: Detail.CodeArticle,
    Quantite: Detail.Quantite,
    Prix: Detail.Prix,
    TTva: Detail.TTva,
    Remise: Detail.Remise,
    Sens: Detail.sens,
  });
  //  console.log(data.data.message)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const updateDetailData = async (Detail) => {
  const data = await axios.post(`${baseApi}/AchatsBonsDetail`, {
    action: "update",
    token: sessionStorage.getItem("token"),
    IdDetailBA: Detail.IdDetailBA,
    CodeBA: Detail.CodeBA,
    CodeArticle: Detail.CodeArticle,
    Quantite: Detail.Quantite,
    Prix: Detail.Prix,
    TTva: Detail.TTva,
    Remise: Detail.Remise,
  });
  //  console.log(data.data.message)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const deleteDetailData = async (IdDetailBA) => {
  const data = await axios.post(`${baseApi}/AchatsBonsDetail`, {
    action: "delete",
    token: sessionStorage.getItem("token"),
    IdDetailBA: IdDetailBA,
  });
  //  console.log(data.data.message)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

