import axios from "axios";
// import { deconnect } from "./global";

const baseApi = "https://api.gstok.com";

export const getExtraChamp = async (Tble) => {
  const data = await axios.post(`${baseApi}/ExtraChamp`, {
    action: "getExtraChamp",
    token: sessionStorage.getItem("token"),
    Tble:Tble,
  });

  // console.log(data.data);  
  return data.data;
  
};

export const getExtraChampDetail = async (Tble,idOrigine) => {
  const data = await axios.post(`${baseApi}/ExtraChamp`, {
    action: "getExtraChampDetail",
    token: sessionStorage.getItem("token"),
    Tble:Tble,
    idOrigine:idOrigine,
  });

  // console.log(data.data);  
  return data.data;
  
};

export const SaveExtraChamp = async (IdChampDetail,IdOrigine,ExtraValue) => { 
  
     const data = await axios.post(`${baseApi}/ExtraChamp`, {
         action: 'SaveExtraChamp',
         token:sessionStorage.getItem("token"),        
         IdChampDetail: IdChampDetail,
         ExtraValue: ExtraValue,
         IdOrigine: IdOrigine,
         
         
         
     })
 
    //  console.log(data.data)
     if (data.data && data.status===200){return data.data.message}
    
 }



