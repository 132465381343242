
import axios from "axios";
import { deconnect } from './global';

const baseApi="https://api.gstok.com"

export const AddCommande = async () => { 
    const data = await axios.post(`${baseApi}/AchatsCommandes`, {
        action: 'AddNew',
        token:sessionStorage.getItem("token"),        
    })

    //   console.log(data.data)
    if (data.data.LastID && data.status===200){return data.data.LastID}
    else {deconnect();}
    
};

export const getSingleCommande = async (IdCommande) => {    
    const data = await axios.post(`${baseApi}/AchatsCommandes`, {
        action: 'single',
        token:sessionStorage.getItem("token"),
        IdCommande:IdCommande,        
    });
    //  console.log(data.data)
     if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const getCommandeListe = async (periode) => {
    sessionStorage.setItem('Debut',periode.debut);
    sessionStorage.setItem('Fin',periode.fin);
    const data = await axios.post(`${baseApi}/AchatsCommandes`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,        
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const searchCommande = async (seachValue,periode) => {              
    const data = await axios.post(`${baseApi}/AchatsCommandes`, {
        action: 'searchItem',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,
        search: seachValue
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};


export const deleteCommande = async (IdCommande) => { 
    const data = await axios.post(`${baseApi}/AchatsCommandes`, {
        action: 'delete',
        token:sessionStorage.getItem("token"),
        IdCommande: IdCommande,
    })

    // console.log(data)
    if (data.data.message && data.status===200){return data.data.message}
    else {deconnect();}
    
};

//Detail Commande

export const updateCommande = async (current) => { 
    //  console.log(current)
        const data = await axios.post(`${baseApi}/AchatsCommandes`, {
            action: 'updateCommande',
            token:sessionStorage.getItem("token"),        
            IdCommande: current.IdCommande,
            NumCommande: current.NumCommande,
            DateCommande: current.DateCommande,
            CodeFour: current.CodeFour,
            MontantTTC: current.MontantTTC,
            Mod_HT: current.Mod_HT,            
            Tva: current.Tva,
            BA: current.BA,
            Type: current.Type,            
            Description: current.Description,
            
            
        })
    
    //   console.log(data.data)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    }
    
    export const searchFournisseurs = async (seachValue) => {
        const data = await axios.post(`${baseApi}/Fournisseurs`, {
          action: "searchItem",
          token: sessionStorage.getItem("token"),
          search: seachValue,
        });
      
        // console.log(data.data)
        if (data.data && data.status === 200) {
          return data.data;
        } else {
          deconnect();
        }
      };
    
    export const getDetailData = async (IdCommande) => {

        const data = await axios.post(`${baseApi}/AchatsCommandesDetail`, {
            action: 'fetchall',
            token:sessionStorage.getItem("token"),
            IdCommande: IdCommande,       
        });
        //  console.log(IdCommande)
        if (data.data && data.status===200){return data.data}
        else {deconnect();}
    };
    
    export const AddDetailData = async (Detail) => {          
        const data = await axios.post(`${baseApi}/AchatsCommandesDetail`, {
            action: 'insert',
            token:sessionStorage.getItem("token"),

            CodeCommande:Detail.codeCommande, 
            CodeArticle:Detail.CodeArticle,
            Quantite:Detail.Quantite,        
            Prix:Detail.Prix,
            TTva:Detail.TTva,
            Remise:Detail.Remise,
                  
        });
        //  console.log(data.data)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    };
    
    export const updateDetailData = async (Detail) => {
        const data = await axios.post(`${baseApi}/AchatsCommandesDetail`, {
            action: 'update',
            token:sessionStorage.getItem("token"),
            IdDetailCommande:Detail.IdDetailCommande,
            CodeCommande:Detail.CodeCommande, 
            CodeArticle:Detail.CodeArticle,
            Quantite:Detail.Quantite,        
            Prix:Detail.Prix,
            TTva:Detail.TTva,
            Remise:Detail.Remise,        
        });
        //  console.log(data.data.message)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    };
    
    export const deleteDetailData = async (IdDetailCommande) => {
        const data = await axios.post(`${baseApi}/AchatsCommandesDetail`, {
            action: 'delete',
            token:sessionStorage.getItem("token"),
            IdDetailCommande:IdDetailCommande,
                 
        });
        //  console.log(data.data.message)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    };

    //commande to bon


export const commandeToBon = async (numCom,numBon,dateBon,IdCommande,CodeFour) => {
    const data = await axios.post(`${baseApi}/AchatsBonsDetail`, {
      action: "commandeToBon",
      token: sessionStorage.getItem("token"),
      NumBA:numBon,
      NumCom:numCom,
      DateBA :dateBon,
      IdCommande :IdCommande,
      CodeFour :CodeFour,
          
  
    });
    //  console.log(data.data.message)
    if (data.data && data.status === 200) {
      return data.data;
    } else {
     deconnect();
    }
  };

  export const getNumBon = async (IdBA) => {    
    const data = await axios.post(`${baseApi}/AchatsBonsDetail`, {
        action: 'getNumBon',
        token:sessionStorage.getItem("token"),
        IdBA :IdBA ,        
    });
    //  console.log(data.data)
     if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

