import { useDispatch } from 'react-redux';
import { SetPageTitle } from '../../../redux/slices/LayoutSlice';

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { getSingleClient, getDataMouvement } from '../../../services/clients';
import { GetDate, FormattNumber, reformatDate, formatDate, padNumber } from '../../../services/global';
import { isLogin } from '../../../services/global';

import Spinner from '../../../components/widgets/spinner/spinner';
import TableOne from '../../../components/widgets/tables/TableOne';

const ClientMouvement = () => {
    const NumPage = 28;
    const dispatch = useDispatch();

    const [CurrentClient, setCurrentClient] = useState({});
    const [DataList, setDataList] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const [Periode, setPeriode] = useState({ debut: formatDate(new Date()), fin: formatDate(new Date()) });

    const params = useParams();
    const navigate = useNavigate();

    const getData = async () => {
        if (Periode.debut > Periode.fin) { alert('Période Non Valide') }
        else {
            setLoading(true);
            const data = await getDataMouvement(Periode, CurrentClient.IdClient);
            setDataList(data);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!isLogin()) { navigate("/login"); }
        dispatch(SetPageTitle('Mouvement Client'));

        const getFistData = async () => {
            const periode = await GetDate();
            setPeriode(periode);

            const response = await getSingleClient(params.id);
            if (response.Client) {
                setCurrentClient(response);
                const data = await getDataMouvement(periode, response.IdClient);
                setDataList(data);
                setLoading(false);

            }
            else {
                alert('?')
            }

        }

        getFistData();

    }, [dispatch, params.id, navigate]);

    return (
        isLogin() && DataList && (
            <>
                <div className="row mb-3 small align-items-center not-print">
                    <div className="col-12 col-md-7 justify-content-between d-flex gap-2  mb-1">
                        <input className="form-control" type="date" value={Periode.debut}
                            onChange={(e) => setPeriode({ debut: e.target.value, fin: Periode.fin })} />

                        <input className="form-control" type="date" value={Periode.fin}
                            onChange={(e) => setPeriode({ debut: Periode.debut, fin: e.target.value })} />

                        <button className="btn btn-outline-primary btn-sm" onClick={() => getData()} type="button">
                            <i className="fa fa-calendar-o" aria-hidden="true"></i>
                        </button>


                    </div>
                    <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                        <b className="text-capitalize">{CurrentClient.Client}</b>
                        <button className="btn btn-outline-primary btn-sm">{FormattNumber(CurrentClient.Solde - 0, 2)}</button>
                    </div>
                </div>
                <Spinner isLoading={isLoading} />
                <TableOne>
                    {
                        DataList.map((row, i) =>
                            <tr key={i} >
                                <td className={(row.Doc === 'Reg') ? "bg-success text-white" : row.Montant < 0 ? "text-danger" : ""}>
                                    <div className="row">
                                        <div className="col-6 col-md-2" >
                                            {FormattNumber(row.report - 0, 2)}
                                        </div>
                                        <div className="col-6  col-md-2 " >
                                            <b>{reformatDate(row.DateOP)}</b>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            {row.Doc === 'BL' && row.Montant < 0 ?
                                                <b>Re : {row.NumDoc}</b>
                                                :
                                                <b>{row.Doc} : {row.NumDoc} </b>
                                            }

                                        </div>

                                        <div className="col-6 col-md-2 text-end">
                                            <b>{FormattNumber(row.Montant - 0, 2)} </b>
                                        </div>
                                        <div className="col-6 col-md-3 text-end">
                                            {FormattNumber((row.report + (row.Montant * row.sens)) - 0, 2)}
                                        </div>


                                    </div>

                                </td>
                            </tr>

                        )
                    }
                </TableOne>
                <table className="border w-100 mt-1 not-print">
                    <tbody>
                        <tr>
                            <td>
                                <div className="row align-items-center px-2">
                                    <div className="col-6">
                                        Total Doc : {DataList.length}
                                    </div>
                                    <div className="col-6 text-end">
                                        <span className="mx-2">
                                            <b>Solde </b> : {DataList.length !== 0 ? FormattNumber(DataList[DataList.length - 1].report + (DataList[DataList.length - 1].Montant * DataList[DataList.length - 1].sens), 2)
                                                :
                                                FormattNumber(CurrentClient.Solde - 0, 2)
                                            }
                                        </span>

                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="table w-100 table-bordered small d-none print">
                    <tbody>
                        {DataList.map((row, i) => (
                            <>
                                {(i % NumPage === 0 || i === 0) && (
                                    <>
                                        <tr
                                            key={Math.round((DataList.length - 1) / NumPage) + 1}
                                            className="text-center border-0 "
                                            styles={{ pageBreakAfter: i < DataList.length - 1 ? "always" : "" }}
                                        >
                                            <th
                                                colSpan={7}
                                                className="border-0"

                                            >
                                                <div className="d-flex justify-content-between">
                                                    <span>Mouvement Client : {CurrentClient.Client}</span>
                                                    <span>
                                                        Page N° : {i / NumPage + 1} /
                                                        {Math.round((DataList.length - 1) / NumPage) + 1}
                                                    </span>
                                                    <span> {reformatDate(formatDate(Periode.debut))}/{reformatDate(formatDate(Periode.fin))}</span>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr className="text-center">
                                            <th className=" bg-secondary text-white">N°</th>
                                            <th className=" bg-secondary text-white w-25">Report</th>
                                            <th className=" bg-secondary text-white">Date</th>
                                            <th className=" bg-secondary text-white">Document</th>
                                            <th className=" bg-secondary text-white">Bon Livraison</th>
                                            <th className=" bg-secondary text-white">Réglement</th>
                                            <th className=" bg-secondary text-white w-25">Solde</th>
                                        </tr>
                                    </>
                                )}

                                <tr
                                    key={i}
                                    style={{
                                        pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                                    }}
                                >
                                    <td>
                                        <b>{padNumber(i + 1, 3)}</b>
                                    </td>
                                    
                                    <td>
                                        {FormattNumber(row.report - 0, 2)}
                                    </td>
                                    <td>{reformatDate(row.DateOP)}</td>
                                    <td className="w-50">
                                        {row.Doc === 'BL' && row.Montant < 0 ?
                                            <b>Re : {row.NumDoc}</b>
                                            :
                                            <b>{row.Doc} : {row.NumDoc} </b>
                                        }
                                    </td>
                                    <td className="text-end">
                                        {(row.Doc !== 'Reg') && FormattNumber(row.Montant - 0, 2)}
                                    </td>
                                    <td className="text-end">
                                        {(row.Doc === 'Reg') && FormattNumber(row.Montant - 0, 2)}
                                    </td>
                                    
                                    <td className="text-end">
                                        {FormattNumber((row.report + (row.Montant * row.sens)) - 0, 2)}
                                    </td>
                                </tr>
                            </>
                        ))}

                        <tr>
                            <th colSpan={3}>Total Doc : {DataList.length}</th>
                            <th className="text-end" colSpan={4}>
                                <b>Solde </b> : {DataList.length !== 0 ? FormattNumber(DataList[DataList.length - 1].report + (DataList[DataList.length - 1].Montant * DataList[DataList.length - 1].sens), 2)
                                    :
                                    FormattNumber(CurrentClient.Solde - 0, 2)
                                }
                            </th>
                        </tr>
                    </tbody>
                </table>


            </>
        )
    );
};

export default ClientMouvement;