import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { updateDevis, devisToBon,getNumBon } from "../../../../services/devis";
import {
  padNumber,
  FormattNumber,
  formatDate,
  isAuth
} from "../../../../services/global";
import DetailDevis from "./DetailDevis";
import ComboClient from "../../Clients/ComboClient";

const EditModal = forwardRef((props, ref) => {
  const OpenModalBtn = useRef();
  const FormDevisRef = useRef();
  const FormDevisRef2 = useRef();
  const ClientText = useRef();

  const ModalToBon = useRef(null);
  const ComboClient_Ref = useRef(null);
  const closeModalRef = useRef(null);
  
  const dateBonRef = useRef(null);

  const [FromErrors, setFromErrors] = useState({});
  const [currentDevis, setcurrentDevis] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [ShowClients, setShowClients] = useState(false);
  const [NumBL, setNumBL] = useState("");

  const setDetailPrint = (data) => {
    props.setDetailCurrentPrint(data);
  };

  const initialDevis = {
    IdDevis: 0,
    NumDevis: 0,
    CodeClt: "",
    DateDevis: formatDate(new Date()),
    Mod_HT: "0",
    MontantTTC: 0,
    Tva: 0,
    BL: 0,
    Type: "A",
    Etat: "C",
    Description: "",
    UserID: 0,
    Client: "Comptant",
  };

  useImperativeHandle(ref, () => ({
    async OpenModal(Devis = initialDevis) {
      OpenModalBtn.current.click();

      setShowClients(false);

      setcurrentDevis(Devis);
      if(Devis.BL*1!==0){
        getNumBonFct(Devis.BL)
       
      }

      const FormDevis = FormDevisRef.current;
      const FormDevis2 = FormDevisRef2.current;

      FormDevis.NumDevis.value = padNumber(Devis.NumDevis, 4);
      FormDevis.DateDevis.value = Devis.DateDevis;
      ClientText.current.value = Devis.Client;

      FormDevis2.Description.value = Devis.Description;

      setFromErrors({});

      window.onafterprint = function () {
        try {
          const div = document.querySelectorAll(".bg-transparent");
          div[0].classList.remove("bg-transparent");
          div[0].classList.add("modal-backdrop");
        } catch {}
      };
    },
  }));

  const setTotalDevis = (V) => {
    setcurrentDevis({ ...currentDevis, MontantTTC: V }); // selement pour raffrichir le Montant TTC
    props.setCurrentPrint({ ...currentDevis, MontantTTC: V });
  };

  const imprimer = () => {
    const div = document.querySelectorAll(".modal-backdrop");
    div[0].classList.add("bg-transparent");
    div[0].classList.remove("modal-backdrop");
    window.print();
  };

  const VerifData = () => {
    if (isAuth(props.upte)) {
      let verif = {};
      // if(currentDevis.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}

      // setFromErrors(verif);
      if (Object.keys(verif).length === 0) {
        SaveData();
      }
    }
  };
  const SaveData = () => {
    if (currentDevis.IdDevis === "") {
      //     AddDevis(currentDevis).then((data) => {
      //         props.getData();
      //         currentDevis.IdDevis=data.lastID;
      //         console.log(data.message);
      //    })
    } else {
      updateDevis(currentDevis)
        .then((message) => {
          //  props.getData();
          props.setCurrentPrint({ ...currentDevis });
          console.log(message);
          setIsUpdate(false)
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const doYouSaveBefore = (action) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Voulez Vous enregister les Modifications Avant ") === true) {
      VerifData();
      setTimeout(() => {
        closeModalRef.current.click();
      }, "1000");

    } else {
     setIsUpdate(false);
     setTimeout(() => {
      closeModalRef.current.click();
    }, "1000");
     
    }
  }

  const SelectClient = (idClient, DesignationClient) => {
    if(idClient !== currentDevis.CodeClt){setIsUpdate(true)}
    setShowClients(false);
    currentDevis.CodeClt = idClient;
    currentDevis.Client = DesignationClient;
    ClientText.current.value = DesignationClient;
  };

  const TriggertoBon = () => {
    if (isAuth(props.upte)) {
      if (ModalToBon.current.classList.contains("d-none")) {
        ModalToBon.current.classList.remove("d-none");
      } else {
        ModalToBon.current.classList.add("d-none");
      }
    }
  };

  const getNumBonFct = async (IdBL) =>{
    const data = await getNumBon(IdBL);        
            setNumBL(data.NumBL);
           
  }

  const createBon =  () => {
    if (isAuth(props.upte)) {
      if(dateBonRef.current.value !==''){
        
        devisToBon(currentDevis.NumDevis,dateBonRef.current.value,currentDevis.IdDevis,currentDevis.CodeClt)
        .then((rep) => {
          setcurrentDevis({ ...currentDevis, BL:rep.LastID })    
          getNumBonFct(rep.LastID) ;          
              
        })
        .catch((e) => alert("Erreur Modification"));

        
        dateBonRef.current.value = ''
        
        TriggertoBon();
      }
      else
      {
        alert('Champs Obligatoire !')
      }

    }
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        type="button"
        ref={OpenModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModalList"
      >
        OpenModalBtn
      </button>

      <div
        className="modal fade not-print"
        id="myModalList"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-"
        aria-labelledby="myModalListLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          {/* Devisoc Devis Détail */}

          <div className="modal-content py-2 rounded-0 rounded-bottom" style={{borderTop:'3px #0d6efd solid', boxSizing:'border-box'}}>
          <span className="btn btn-primary  rounded-0 rounded-top modal-edition-title">Edition Devis  Client</span>
            <button
              type="button"
              onClick={() => {
                isUpdate && doYouSaveBefore('close');
                !isUpdate && props.switchPrint("List");
                !isUpdate && props.getData();
                !isUpdate && setShowClients(false);
              }}
              className="btn btn-light position-absolute top-0 start-100 translate-middle rounded-circle"
              data-bs-dismiss={isUpdate ? '' : 'modal'}
              aria-label="Close"
              ref={closeModalRef}
            >
              X
            </button>
            {/* modal créee Bon */}
            <div
              ref={ModalToBon}
              className="position-fixed w-100 d-flex justify-content-center bg-secondary bg-opacity-25 d-none "
              style={{
                height: "100vh",
                zIndex: "10",
                top: "0px",
                left: "0px",
                paddingTop: "20%",
              }}
            >
              <div
                className="border bg-white w-50 rounded-3 p-3 pt-4 row"
                style={{ height: "120px" }}
              >
                
                <div className="col-6">
                  <label forhtml="dateBON" className="form-label">
                    N° Bon Livraison :
                  </label>
                  <input
                    type="date"
                    ref={dateBonRef}
                    className="form-control"
                    id="dateBON"
                  ></input>
                </div>
                <div className="col-6 text-end mt-5">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm me-1"
                    id="numBON"
                    onClick={() => createBon()}
                  >
                    Crée Bon
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    id="numBON"
                    onClick={() => TriggertoBon()}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
            <form ref={FormDevisRef} className="modal-header">
              <div className="row w-100 m-auto">
                <div className="col-12 col-md-6 d-flex gap-2 mb-1 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control text-center"
                    disabled={true}
                    type="text"
                    id="NumDevis"
                    readOnly
                  />
                  <input
                    className="form-control py-0"
                    ref={ClientText}
                    onFocus={() => props.upte && setShowClients(true)}
                    placeholder="Selectionner Client"
                    onChange={async (e) => {
                      props.upte && ComboClient_Ref.current.SerachDataClt(e.target.value);
                    }}
                  />
                  {ShowClients && (
                    <>
                      <button
                        className="btn btn-success btn-sm edit mx-1"
                        onClick={() => ComboClient_Ref.current.NewFour()}
                        name="edit"
                        type="button"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        className="btn btn-light btn-xs"
                        type="button"
                        onClick={() => {
                          setShowClients(false);
                          SelectClient(
                            currentDevis.CodeClt,
                            currentDevis.Client
                          );
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>
                      </button>
                    </>
                  )}
                </div>

                <div className="col-6 col-md-4 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control"
                    type="date"
                    id="DateDevis"
                    onChange={(e) =>
                      {currentDevis.DateDevis = e.target.value; setIsUpdate(true)}
                    }
                  />
                </div>
                
                <div className="col-6 col-md-2 justify-content-end d-flex gap-2 py-1 ">
                  
                  <input
                    onClick={() => VerifData()}
                    value="Enregistrer"
                    className="btn btn-primary btn-sm"
                    type="button"
                    disabled={!props.upte}
                  />
                  <input
                    onClick={() => TriggertoBon()}
                    value={ currentDevis.BL*1 === 0 ? "Créer BL"  : "Bon N° " + NumBL}
                    className="btn btn-danger btn-sm"
                    type="button"
                    disabled={!props.upte || isUpdate || currentDevis.BL*1 !== 0 }
                  />
                </div>
              </div>
            </form>
            {ShowClients && (
              <ComboClient
                ref={ComboClient_Ref}
                SelectClient={SelectClient}
              />
            )}
            <div
              className="modal-body m-0 py-0"
              onClick={() => {
                setShowClients(false);
                SelectClient(
                  currentDevis.CodeClt,
                  currentDevis.Client
                );
              }}
            >
              <DetailDevis
                IdDevis={currentDevis.IdDevis}
                getData={props.getData}
                setTotalDevis={setTotalDevis}
                setDetailPrint={setDetailPrint}
                imprimer={imprimer} 
                upte={props.upte}
              />
            </div>
            <form
              ref={FormDevisRef2}
              className="modal-footer d-flex flex-column"
            >
              <div className="row w-100">
                <div className="col-8 col-md-6 input-group-sm">
                  <input
                    className="form-control"
                    type="text"
                    id="Description"
                    placeholder="Edtion Commentaire"
                    onChange={(e) =>
                      {currentDevis.Description = e.target.value; setIsUpdate(true)}
                    }
                  />
                </div>
                <div className="col-4 col-md-2 py-1">
                  
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-end align-items-center small">
                  <b className="mx-2 h4 text-primary">
                    Total : {FormattNumber(currentDevis.MontantTTC * 1, 2)}
                  </b>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditModal;
