import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { updateBon } from "../../../../services/achats";
import { FormattNumber, formatDate } from "../../../../services/global";
import ReglementBA from "./ReglementBA";
import DetailBA from "./DetailBA";
import ComboFournisseur from "../../Fournisseurs/ComboFournisseur";

const EditBAModal = forwardRef((props, ref) => {
  const OpenModalBtn = useRef();
  const FormHeader = useRef();
  const FormFooter = useRef();
  const FournisseurText = useRef();

  const ComboFournisseur_Ref = useRef(null);    
  const closeModalRef = useRef(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const [FromErrors, setFromErrors] = useState({});
  const [currentDetail, setcurrentDetail] = useState({});  
  const [ShowFournisseurs, setShowFournisseurs] = useState(false);
  const [ShowReglement, setShowReglement] = useState(false);

  const setDetailPrint = (data) => {
    props.setDetailCurrentPrint(data);
  };

  const initialBon = {
    IdBA: 0,
    NumBA: 0,
    CodeFour: "",
    DateBA: formatDate(new Date()),
    Mod_HT: "0",
    Avoir: 1,
    MontantTTC: 0,
    Tva: 0,
    Facture: 0,
    Type: "A",
    Etat: "C",
    Description: "",
    BonNew: "",
    UserID: 0,
    Fournisseur: "Comptant",
    Reglement: 0,
  };

  useImperativeHandle(ref, () => ({
    async OpenModal(Bon = initialBon) {
      setShowReglement(false);
      OpenModalBtn.current.click();

      setShowFournisseurs(false);

      setcurrentDetail(Bon);      

      FormHeader.current.NumBA.value = Bon.NumBA;
      FormHeader.current.DateBA.value = Bon.DateBA;

      FormFooter.current.Description.value = Bon.Description;

      FournisseurText.current.value = Bon.Fournisseur;

      
      setFromErrors({});

      window.onafterprint = function () {
        try {
          const div = document.querySelectorAll(".bg-transparent");
          div[0].classList.remove("bg-transparent");
          div[0].classList.add("modal-backdrop");
        } catch {}
      };
      
    },
  }));

  const setTotalReg = (V) => {
    currentDetail.Reglement = V;
  };

  const setTotalDetail = (V) => {
    // currentDetail.MontantTTC = V;
    setcurrentDetail({ ...currentDetail, MontantTTC: V }); // selement pour raffrichir le Montant TTC
    props.setCurrentPrint({ ...currentDetail, MontantTTC: V });
  };

  const imprimer = () => {
    const div = document.querySelectorAll(".modal-backdrop");
    div[0].classList.add("bg-transparent");
    div[0].classList.remove("modal-backdrop");
    window.print();
  };

  const VerifData = () => {
    if(!props.upte){
      alert('Accès Non Autorisé')
    }else
    {
    let verif = {};
    // if(currentDetail.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}

    // setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  }
  };
  const SaveData = () => {
    if (currentDetail.idBon === "") {
      //     AddBon(currentDetail).then((data) => {
      //         props.getData();
      //         currentDetail.idBon=data.lastID;
      //         console.log(data.message);
      //    })
    } else {
      updateBon(currentDetail)
        .then((message) => {
          // props.getData();
          props.setCurrentPrint({ ...currentDetail });
          console.log(message);
          setIsUpdate(false)
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const doYouSaveBefore = (action) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Voulez vous enregister les modifications avant de quitter ") === true) {
      VerifData();
      setTimeout(() => {
        closeModalRef.current.click();
      }, "1000");

    } else {
     setIsUpdate(false);
     setTimeout(() => {
      closeModalRef.current.click();
    }, "1000");
     
    }
  }

  const SelectFournisseur = (idFournisseur, DesignationFournisseur) => {
    setShowFournisseurs(false);
    if(idFournisseur !== currentDetail.CodeFour){setIsUpdate(true)}
    currentDetail.CodeFour = idFournisseur;
    currentDetail.Fournisseur = DesignationFournisseur;
    FournisseurText.current.value = DesignationFournisseur;
    
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        type="button"
        ref={OpenModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModalList"
      >
        OpenModalBtn
      </button>
      

      <div
        className="modal fade not-print"
        id="myModalList"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-"
        aria-labelledby="myModalListLabel"
        aria-hidden="true"
      >
        <div
          className={
            "modal-dialog modal-dialog-centered " +
            (ShowReglement ? "" : "modal-xl")
          }
        >
          {/* bloc BL Détail */}
          <div 
          className={ShowReglement ? "d-none" : "modal-content py-2 rounded-0 rounded-bottom"}
          style={{borderTop:'3px #dc3545 solid', boxSizing:'border-box'}}
          >
            <span className="btn btn-danger rounded-0 rounded-top modal-edition-title d-none d-lg-block">Edition Bon d'Achat</span>
            <button
              type="button"
              onClick={() => {
                isUpdate && doYouSaveBefore('close');
                !isUpdate && props.switchPrint("List");
                !isUpdate && props.getData();
                !isUpdate && setShowFournisseurs(false);
              }}
              className="btn btn-light position-absolute top-0 start-100 translate-middle rounded-circle"
              data-bs-dismiss={isUpdate ? '' : 'modal'}
              aria-label="Close"
              ref={closeModalRef}
            >
              X
            </button>

            <form ref={FormHeader} className="modal-header px-0 pb-2">
              <div className="row w-100 m-auto">
                <div className="col-12 col-lg-6 d-flex gap-2 mb-1 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control text-center"
                    type="text"
                    id="NumBA"
                    onChange={(e) => {currentDetail.NumBA = e.target.value; setIsUpdate(true)}}
                  />
                  <input
                    className="form-control py-0"
                    ref={FournisseurText}
                    onFocus={() => props.upte && setShowFournisseurs(true)}
                    placeholder="Selectionner Fournisseur"
                    onChange={async (e) => {
                      props.upte && ComboFournisseur_Ref.current.SerachDatafr(e.target.value);
                    }}
                  />
                  {ShowFournisseurs && (
                    <>
                      <button
                        className="btn btn-success btn-sm edit mx-1"
                        onClick={() => ComboFournisseur_Ref.current.NewFour()}
                        name="edit"
                        type="button"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        className="btn btn-light btn-xs"
                        type="button"
                        onClick={() => {
                          setShowFournisseurs(false);
                          SelectFournisseur(
                            currentDetail.CodeFour,
                            currentDetail.Fournisseur
                          );
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>
                      </button>
                    </>
                  )}
                </div>

                <div className="col-6 col-lg-4 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control"
                    type="date"
                    id="DateBA"
                    onChange={(e) =>
                      {currentDetail.DateBA = e.target.value; setIsUpdate(true)}
                    }
                  />
                </div>

                <div className="col-6 col-lg-2 justify-content-end d-flex gap-2 py-1 ">
                  <input
                    onClick={() => VerifData()}
                    value="Enregistrer"
                    className={
                      currentDetail.Avoir === "1"
                        ? "btn btn-primary btn-sm"
                        : "btn btn-danger btn-sm"
                    }
                    type="button"
                    disabled={!props.upte}
                  />
                </div>
              </div>
            </form>
            {ShowFournisseurs && (
        
        <ComboFournisseur
          ref={ComboFournisseur_Ref}
          SelectFournisseur={SelectFournisseur}
        />
      
    )}
            <div
              className="modal-body  m-0 py-0"
              onClick={() => {
                setShowFournisseurs(false);
                SelectFournisseur(
                  currentDetail.CodeFour,
                  currentDetail.Fournisseur
                );
              }}
            >
              <DetailBA
                IdBA={currentDetail.IdBA}
                Sens={currentDetail.Avoir}
                getData={props.getData}
                setTotalDetail={setTotalDetail}
                setDetailPrint={setDetailPrint}
                imprimer={imprimer}
                upte={props.upte}
              />

             
            </div>
            <form ref={FormFooter} className="modal-footer d-flex flex-column py-0 border-0">
              <div className="row w-100">
                <div className="col-12 col-lg-6 input-group-sm d-flex p-0">
                  <input
                    className="form-control"
                    type="text"
                    id="Description"
                    placeholder="Edtion Commentaire"
                    onChange={(e) =>
                      {currentDetail.Description = e.target.value; setIsUpdate(true)}
                    }
                  />
               
                  
                  <button
                    className="btn btn-success btn-sm mx-2"
                    onClick={() => props.upte && setShowReglement(true)}
                    type="button"
                    disabled={!props.upte}
                  >
                    <i className="fa fa-usd"></i>
                  </button>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-between align-items-center small px-0 mt-1">
                  <b className="text-nowrap">
                    Réglement : {FormattNumber(currentDetail.Reglement * 1, 2)}
                  </b>
                  <b className="mx-2 h6 text-primary text-end text-nowrap">
                    Total : {FormattNumber(currentDetail.MontantTTC * 1, 2)}
                  </b>
                </div>
              </div>
            </form>
          </div>

          {/* bloc Réglement BL */}
          <div className={!ShowReglement ? "d-none" : "modal-content"}>
            <ReglementBA
              idBon={currentDetail.IdBA}
              CodeFour={currentDetail.CodeFour}
              getData={props.getData}
              setTotalReg={setTotalReg}
              setShowReglement={setShowReglement}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditBAModal;
