import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  getDevisListe,
  AddDevis,
  getSingleDevis,  
  deleteDevis,
} from "../../../services/devis";
import {
  isLogin,
  GetDate,
  padNumber,
  FormattNumber,
  reformatDate,
  formatDate,
  sortBy,
  SerachData
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";

import EditModal from "./EditModal";
import PeriodeDateur from "../../../components/widgets/periode/periode";

let sortParm = "";
let sortType = "S";
let sortSens = 1;

const DevisVentes = ({ pageRoles }) => {

  const NumPage = 28;

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [OrderText, SetOrderText] = useState("Ordre ");
  const [searchText, setSearchText] = useState('');
  const [currentPrint, setCurrentPrint] = useState({
    IdDevis: 0,
    NumDevis: 0,
    CodeClt: "",
    DateDevis: formatDate(new Date()),
    Mod_HT: "0",
    MontantTTC: 0,
    Tva: 0,
    BA: 0,
    Type: "A",
    Etat: "C",
    Description: "",
    UserID: 0,
    Client: "Client Particulier",
  });  
  const [currentDetailPrint, setDetailCurrentPrint] = useState([]);

  const search = useRef();
  const EditModal_Ref = useRef();
  const PeriodeDateurRef = useRef();
  const ListePrintRef = useRef();
  const DetailPrintRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getSortedData = (
    param = sortParm,
    TypeData = sortType,
    data = [...DataList],
    sens = 0
  ) => {
    if (param !== "") {
      sens = sens === 0 ? (sortParm === param ? -1 * sortSens : 1) : sortSens;
      sortParm = param;
      sortType = TypeData;
      sortSens = sens;

      setDataList(sortBy(param, TypeData, data, sens));
    }
  };

  const switchPrint = (doc) => {
    if (doc === "Detail") {
      ListePrintRef.current.classList.remove("print");
      DetailPrintRef.current.classList.add("print");
    } else {
      ListePrintRef.current.classList.add("print");
      DetailPrintRef.current.classList.remove("print");
    }
  };

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      
      setLoading(true);
      const data = await getDevisListe(Periode);
      setDataList(data);
      setLoading(false);
      getSortedData(undefined, undefined, data, sortSens);
      
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/liste");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getDevisListe(periode);
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Liste des Devis"));
  }, [dispatch, navigate]);

const AddNewDevis = async () => {
    setLoading(true);
    AddDevis()
      .then((LastID) => {
        setLoading(false);
        return getSingleDevis(LastID);
      })
      .then((Devis) => EditModal_Ref.current.OpenModal({ ...Devis }))
      .catch((e) => {
        alert("Erreur Ajout");
        console.log(e);
      });
  };

  const delelteDevis = (Devis) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Êtes vous sûr de vouloir supprimer le Devis N°: \n ${padNumber(
          Devis.NumDevis,
          4
        )} ?`
      )
    ) {
      setLoading(true);
      deleteDevis(Devis.IdDevis)
        .then((message) => {
          setLoading(false);
          getData();
          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  return (
    isLogin() &&
    DataList && (
      <>
        {/* Header Liste */}
        <div className="row mb-3 not-print">
          <div className="col-12 col-md-6 text-end d-flex gap-2 mb-1">
            <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />

            
            <div className="dropdown">
              <button
                className="btn dropdown-toggle btn-sm"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {OrderText}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("NumDevis", "N", undefined);
                      SetOrderText("Numéro");
                    }}
                  >
                    Numéro
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("Client", "S", undefined);
                      SetOrderText("Client");
                    }}
                  >
                    Client
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("DateDevis", "D", undefined);
                      SetOrderText("Date");
                    }}
                  >
                    Date Devis
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("MontantTTC", "N", undefined);
                      SetOrderText("Montant");
                    }}
                  >
                    Montant Total
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 text-end d-flex gap-2 ">
            <div className="input-group">
              <input
                // ref={search}
                className="form-control"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Clients"
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => pageRoles.upte && AddNewDevis()}
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
        
        <TableOne vh="vh-scroled-1">
          {DataList.map((row, i) => (
          SerachData(row.Client,searchText) &&
            <tr key={i}>
              <td
                onDoubleClick={() => {
                  switchPrint("Detail");
                  setCurrentPrint({ ...row });
                  EditModal_Ref.current.OpenModal({ ...row });
                }}
              >
                <div className="row align-items-center">
                  <div className="col-6 col-md-2">
                    <b>Num : {padNumber(row.NumDevis, 4)}</b>
                  </div>

                  <div className="col-6 col-md-2">
                    {reformatDate(row.DateDevis)}
                  </div>
                  <div className="col-12 col-md-4">
                    <div>
                      <b>{row.Client} </b>
                    </div>
                    <div className="text-end text-secondary">
                      {row.Description}
                    </div>
                  </div>

                  <div className="col-6 col-md-2 text-end">
                    <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-100 mb-1">
                      Total{" "}
                      <span className="badge bg-white w-50 text-end text-dark">
                        {FormattNumber(row.MontantTTC * 1, 2)}
                      </span>
                    </button>
                  </div>
                  <div className="col-6 col-md-2 text-end">
                    <button
                      className="btn btn-primary btn-sm mx-1"
                      onClick={() => {
                        switchPrint("Detail");
                        setCurrentPrint({ ...row });
                        EditModal_Ref.current.OpenModal({ ...row });
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </button>

                    <button
                      className="btn btn-warning btn-sm delete"
                      onClick={() => pageRoles.del && delelteDevis(row)}
                      disabled={!pageRoles.del}
                    >
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </TableOne>

        {/* Footer Liste */}
        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-50">
                    Doc :{" "}
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.filter(row => SerachData(row.Client,searchText)).length}
                    </span>
                  </button>

                  <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-50">
                    Total :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {FormattNumber(
                        DataList.reduce((q, row) =>  SerachData(row.Client,searchText) ?q + row.MontantTTC * 1:q, 0),
                        2
                      )}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <EditModal
          ref={EditModal_Ref}
          getData={getData}
          switchPrint={switchPrint}
          setCurrentPrint={setCurrentPrint}
          setDetailCurrentPrint={setDetailCurrentPrint}
          upte={pageRoles.upte}
        />

        <Spinner isLoading={isLoading} />

        {/* Impression Liste                */}
        <table
          ref={ListePrintRef}
          className="table w-100  table-bordered small d-none print"
        >         

            {DataList.filter(row => SerachData(row.Client, searchText)).map((row, i) => (
              <tbody key={i}>
                {(i % NumPage === 0 || i === 0) && (
                  <>
                    <tr className="w-100 text-center border-0">
                      <th colSpan={4} className="border-0 px-0">
                        <div className="d-flex justify-content-between">
                          
                          <span>Devsi Clients : {reformatDate(formatDate(PeriodeDateurRef.current.getPeriode().debut))} / {reformatDate(formatDate(PeriodeDateurRef.current.getPeriode().fin))}</span>
                          <span>
                            Page N° : {i / NumPage + 1} /
                            {Math.round((DataList.filter(row => SerachData(row.Client, searchText)).length - 1) / NumPage) + 1}
                          </span>
                         
                        </div>
                      </th>
                    </tr>
                    <tr className="w-100 text-center ">
                      <th>Num</th>
                      <th>Date</th>
                      <th>Clients</th>
                      <th>Total</th>
                    </tr>
                  </>
                )}
                <tr
                  
                  style={{
                    pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                  }}
                >
                  <td className="text-center">
                    <b>{padNumber(row.NumDevis, 4)}</b>
                  </td>
                  <td className="text-center" style={{ minWidth: "100px" }}>
                    {row.DateDevis}
                  </td>
                  <td className="w-75">{row.Client}</td>
                  <td className="text-end" style={{ minWidth: "120px" }}>
                    {FormattNumber(row.MontantTTC * 1, 2)}
                  </td>
                </tr>              
              </tbody>
            ))}
            <tbody>
            <tr>
              <th colSpan={3}>Nombre Commandes : {DataList.filter(row => SerachData(row.Client, searchText)).length}</th>
              <th className="text-end">
                <b>Total </b> :{" "}
                {FormattNumber(
                  DataList.filter(row => SerachData(row.Client, searchText)).reduce(
                    (total, row) => total + row.MontantTTC * 1,
                    0
                  ),
                  2
                )}
              </th>
            </tr>
            </tbody>          
        </table>

        {/* Impression Détail */}
        <table ref={DetailPrintRef} className="table table-borderless d-none">
          <thead>
            <tr className="w-100 text-center ">
              <td colSpan={6}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end " colSpan={2}>
                        Date Devis : {reformatDate(currentPrint.DateDevis)}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "200px", height: "100px" }}
                        className="border"
                      >
                        Logo
                      </td>
                      <td>
                        Client : <b>{currentPrint.Client}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-end">
                        N° Devis :{" "}
                        <b>
                          {" "}
                          {padNumber(currentPrint.NumDevis * 1, 4)} /{" "}
                          {currentPrint.DateDevis.substr(-2)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="text-center  bg-secondary text-white  rounded-start">
                N°
              </td>
              <th className="w-50 text-center bg-secondary text-white border border-0 border-top border-white">
                Désignation
              </th>

              <th
                className="text-center  bg-secondary text-white  "
                style={{ minWidth: "60px" }}
              >
                Qté
              </th>
              <th
                className="text-center bg-secondary text-white "
                style={{ minWidth: "80px" }}
              >
                PU
              </th>
              <th
                className="text-center  bg-secondary text-white "
                style={{ minWidth: "40px" }}
              >
                R%
              </th>
              <th
                className="text-center  bg-secondary text-white rounded-end"
                style={{ minWidth: "120px" }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody className="small">
            {currentDetailPrint.map((row, i) => (
              <tr key={i} className="border">
                <td className="text-center border">
                  <b>{i + 1}</b>
                </td>
                <td className="w-75" style={{maxWidth:'200px'}}>
                  {row.RefArticle} | {row.designation}
                </td>

                <td className="text-center border">{row.Quantite}</td>
                <td className="text-end">{FormattNumber(row.Prix - 0, 2)}</td>
                <td className="text-center border">{row.Remise}%</td>
                <td className="text-end border">
                  {FormattNumber(row.Total - 0, 2)}
                </td>
              </tr>
            ))}
            <tr className="w-100 text-center ">
              <td colSpan={6}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end">
                        Total Devis :{" "}
                        {FormattNumber(currentPrint.MontantTTC * 1, 2)}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
          <tfoot className="page-break">
            <tr>
              <th colSpan={6}>
                <div className="d-flex justify-content-between">
                  <span></span>
                  <span id="pageFooter"></span>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    )
  );
};

export default DevisVentes;
