
import Menu from '../layouts/menu';
import logo from '../../assets/LOGO-IG.png';


const LeftSideOffcanvas = () => { 
    return (
        <>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvas-LettSide" aria-labelledby="offcanvas-LettSideLabel">
                <div className="offcanvas-header border-bottom py-1 bg-dark-2">
                    <h5 className="offcanvas-title d-flex align-items-center gap-2 text-white" id="offcanvas-LettSideLabel">
                        <img src={logo} alt="Gesom logo" className="logo m-auto" style={{width:'40px'}}/> Com 24
                    </h5>
                    <button type="button" className="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-1">                
                        <Menu isMobile={true} />                    
                </div>
            </div>
        </>
    );
};

export default LeftSideOffcanvas;