import {useState,useEffect,forwardRef,useImperativeHandle} from 'react';
import { GetDate, formatDate } from '../../../services/global';



const Periode = forwardRef((props, ref) => {
    
    const [Periode,setPeriode] = useState({debut: formatDate(new Date()),fin: formatDate(new Date())});

    useImperativeHandle(ref,  () => ({           
            getPeriode(){ return Periode }
    }))
   

    useEffect(() => {
                
        const getFistData = async () => {
            const periode  = await GetDate();
            setPeriode(periode);
          }

         getFistData();
              
      },[]);

    return (
        
           <div className="input-group" style={{minWidth:'280px'}}>  
                <input className="form-control form-control-sm" type="date" value={Periode.debut} 
                onChange={(e) => setPeriode({debut:e.target.value,fin:Periode.fin})} />

                <input className="form-control form-control-sm" type="date" value={Periode.fin}
                onChange={(e) => setPeriode({debut:Periode.debut,fin:e.target.value})} />
           
            <button className="btn btn-primary btn-sm px-2" onClick={() => props.getData(Periode)}>
                <i className="fa fa-check" aria-hidden="true"></i>
            </button> 
            </div>
        
    );
});

export default Periode;