import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { searchProduct } from "../../../services/produits";
import EditProduct from "./EditProduct";

const ComboProduit = forwardRef((props, ref) => {
  const [DataProduct, setDataProduct] = useState([]);
  const [ShowProducts, setShowProducts] = useState(false);
  const [Product, setProduct] = useState({});

  const inputProductText = useRef();
  const EditProduct_Ref = useRef();
  const backBtn = useRef();
  const [search, setsearch] = useState("");

  useImperativeHandle(ref, () => ({
    SetProdText(idArticle, DesignationProduct, RefArticle, PU) {
      // console.log(idArticle===0)
      setProduct({
        idArticle: idArticle,
        DesignationProduct: DesignationProduct,
        RefArticle: RefArticle,
        PU: PU,
      });
      inputProductText.current.value =
        idArticle === 0 ? "" : DesignationProduct + " ( " + RefArticle + " )";
    },

    callBackBtn () {      
    try{
      if( EditProduct_Ref.current.getShowDetail() === false ) {
        SelectProduct(
          Product.idArticle,
          Product.DesignationProduct,
          Product.RefArticle,
          Product.PU
        );
       }
       else{
        EditProduct_Ref.current.setShowDetail(false)
       }
    }
    catch{
      console.log('no action')
    }
    }
  }));

  const getData = async () => {
    setDataProduct(await searchProduct(search));
  };

  useEffect(() => {
    const getFistData = async () => {
      setDataProduct(await searchProduct());
    };

    getFistData();
  }, []);

  const SelectProduct = (
    idArticle,
    DesignationProduct,
    RefArticle,
    Prix = 0
  ) => {
    setShowProducts(false);
    props.updateProduct(idArticle, DesignationProduct, Prix);
    inputProductText.current.value =
      idArticle === 0 ? "" : DesignationProduct + " ( " + RefArticle + " )";
  };

  return (
    
    <div className="col-12 col-md-12">
      
      <div className="d-flex gap-2 mb-1 input-group-sm">
        <input
          className="form-control py-1"
          ref={inputProductText}
          
          placeholder="Selectionner Produit"
          onChange={async (e) => {
            setsearch(e.target.value);
            setDataProduct(await searchProduct(e.target.value));
          }}

          onFocus={() => setShowProducts(true)}
        />
        {ShowProducts && (
          <button
          ref={backBtn}
            className="btn btn-light btn-xs"
            type="button"
            onClick={() => {
              setShowProducts(false);
              SelectProduct(
                Product.idArticle,
                Product.DesignationProduct,
                Product.RefArticle,
                Product.PU
              );
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </button>
        )}
      </div>

      {ShowProducts && (
        <>
          <div
            className="vh-scroled-modal rounded-2 border bg-white"
            style={{
              position: "absolute",
              zIndex: "99",
              top: props.top  ? props.top:"84px",
              width: "92%",
              maxWidth:'670px'
            }}
          >
           
            <table className="table bg-light" >
              <tbody className=" small">
                {DataProduct.map((row, i) => (
                  <tr key={i} onDoubleClick={() =>
                    SelectProduct(
                      row.idArticle,
                      row.Designation,
                      row.RefArticle,
                      row.PrixVTTC
                    )
                  }>
                    <td className="text-center bg-primary bg-opacity-25">
                      <b>{row.Stock}</b>
                    </td>
                    <td className="bg-primary bg-opacity-25">
                      {row.RefArticle} | {row.Designation}
                      <b> - {row.PrixVTTC}</b>
                    </td>
                    <td className="text-end bg-primary bg-opacity-50 ">
                      <div className="d-flex">
                      <button
                        className="btn btn-outline-dark btn-sm border-0"
                        type="button"
                        onClick={() =>
                          EditProduct_Ref.current.OpenEditData({ ...row })
                        }
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button
                        className="btn btn-outline-light btn-sm border-0"
                        type="button"
                        onClick={() =>
                          SelectProduct(
                            row.idArticle,
                            row.Designation,
                            row.RefArticle,
                            row.PrixVTTC
                          )
                        }
                      >
                        <i className="fa fa-check"></i>
                      </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <EditProduct ref={EditProduct_Ref} getData={getData} />
        </>
      )}
    </div>
  );
});

export default ComboProduit;
