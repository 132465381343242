import { createSlice } from "@reduxjs/toolkit"
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { store } from '../store';
// import axios from "axios";


const initialState={
   showLeftSide:true,   
   pageTitle:'Home',
   winSize:window.innerWidth,
}

const LayoutSlice= createSlice({
    name:'LayoutSlice',
    initialState,
    reducers:{      
      ToggelLeftSide(state) {
        state.showLeftSide = ! state.showLeftSide;       
      },
      SetPageTitle(state, action) {
        state.pageTitle = action.payload;       
      },
      setWinSize(state, action) {
        state.winSize = action.payload;       
      },
    },

    extraReducers: (builder) => {      
      
        
      
    },
      
    
})

export const {  ToggelLeftSide, SetPageTitle ,setWinSize} = LayoutSlice.actions;
export default LayoutSlice.reducer
