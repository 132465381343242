import { useDispatch,useSelector } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDownloadExcel } from "react-export-table-to-excel";

import { getPeriodiqueStockListe } from "../../../services/produits";
import {
  isLogin,
  GetDate,
  reformatDate,
  formatDate,
  FormattNumber,
  sortBy,
  SerachData,
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";

import PeriodeDateur from "../../../components/widgets/periode/periode";

let sortParm = "";
let sortType = "S";
let sortSens = 1;

const Etatperiodique = () => {
  const NumPage = 29;
  let winSize = useSelector((state) => state.LayoutReducer.winSize);

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [OrderText, SetOrderText] = useState("Ordre ");
  const [searchText, SetSearchText] = useState("");
  const [searchMode, setSearchMode] = useState(false);

  const PeriodeDateurRef = useRef();
  const ListePrintRef = useRef();
  const searchInput = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { onDownload } = useDownloadExcel({
    currentTableRef: ListePrintRef.current,
    filename: "Etat Stock Periodique",
    // sheet: 'Users'
  });

  const getSortedData = (
    param = sortParm,
    TypeData = sortType,
    data = [...DataList],
    sens = 0
  ) => {
    if (param !== "") {
      sens = sens === 0 ? (sortParm === param ? -1 * sortSens : 1) : sortSens;
      sortParm = param;
      sortType = TypeData;
      sortSens = sens;

      setDataList(sortBy(param, TypeData, data, sens));
    }
  };

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      setLoading(true);
      const data = await getPeriodiqueStockListe(Periode);
      setDataList(data);
      setLoading(false);
      getSortedData(undefined, undefined, data, sortSens);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/produits/etatperiodique");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getPeriodiqueStockListe(periode);
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Stock Periodique"));
  }, [dispatch, navigate]);

  return (
    isLogin() && (
      <>
        <div className="row mb-2 not-print">
          <div className={!searchMode || winSize > 768  ? "col-10 col-md-6 text-end mt-1 d-flex gap-1 " : 'd-none'}>
            <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />
            <div className="btn-group">
              <button
                className="btn btn-light  text-success"
                onClick={() => onDownload()}
              >
                <i className="fa fa-file-excel-o"></i>
                <div className="infobulle btn bg-light border border-2 btn-sm">
                  Export Excel
                </div>
              </button>
            </div>

            <div className="dropdown d-none d-md-block ">
              <button
                className="btn dropdown-toggle btn-sm"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {OrderText}
              </button>
              <ul className="dropdown-menu bg-light">
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("DesignationFamille", "S", undefined);
                      SetOrderText("Famille");
                    }}
                  >
                    Famille
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("Designation", "S", undefined);
                      SetOrderText("Designation");
                    }}
                  >
                    Designation
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("PrixVTTC", "N", undefined);
                      SetOrderText("Prix de Vente");
                    }}
                  >
                    Prix de Vente
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className={!searchMode || winSize > 768 ? "col-1 col-md-6 mt-1 text-end d-flex gap-2" : 'col-12 text-end mt-1 d-flex gap-2'}>
            <div className="input-group">
              <input
                className={searchMode || winSize >= 768 ? "form-control" : 'd-none'}
                ref={searchInput}
                onChange={(e) => SetSearchText(e.target.value)}
                placeholder="Recherche"
              />
              <span className="input-group-text"
              onClick={() => { setSearchMode(!searchMode ); SetSearchText(""); searchInput.current.focus(); }}>
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>

        <TableOne vh="vh-scroled">
          {DataList.map(
            (row, i) =>
              SerachData(row.designation + row.RefArticle, searchText) && (
                <tr key={i}>
                  <td className="py-0">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <b>{row.RefArticle}</b>
                        <br />
                        {row.designation}
                      </div>
                      <div className="col-6 col-md-2 p-1">
                        <button className="btn btn-outline-secondary text-dark btn-sm d-flex justify-content-between  w-100 mb-1 ">
                          Achat
                          <br />
                          Vente
                          <span className="badge bg-white text-end text-dark">
                            <span className="text-danger">{row.QteAchat}</span>
                            <br />
                            <br />
                            <span className="text-success">{row.QteVente}</span>
                          </span>
                        </button>
                      </div>
                      <div className="col-6 col-md-2 p-1">
                        <button className="btn btn-outline-secondary text-dark btn-sm d-flex justify-content-between  w-100 mb-1 ">
                          Initial
                          <br />
                          Final .
                          <span className="badge bg-white text-end text-dark">
                            <span>{row.QteIni}</span>
                            <br />
                            <br />
                            <span className="text-primary">
                              {row.QteAchat * 1 -
                                row.QteVente * 1 +
                                row.QteIni * 1}
                            </span>
                          </span>
                        </button>
                      </div>

                      <div className="col-6 col-md-2 p-1">
                        <button className="btn btn-outline-secondary text-dark btn-sm d-flex justify-content-between  w-100 mb-1 ">
                          T Achat
                          <br />T Vente
                          <span className="badge bg-white text-end text-dark">
                            <span className="text-danger">
                              {FormattNumber(row.PrixAchat * row.QteVente, 2)}
                            </span>
                            <br />
                            <br />
                            <span className="text-success">
                              {FormattNumber(row.TVente * 1, 2)}
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="col-6 col-md-2 p-1">
                        <button className="btn btn-outline-secondary text-dark btn-sm d-flex justify-content-between  w-100 mb-1 ">
                          Prix Vente
                          <br />T Marge ..
                          <span className="badge bg-white text-end text-dark">
                            <span>
                              {row.QteVente * 1 !== 0
                                ? FormattNumber(row.TVente / row.QteVente, 2)
                                : ""}
                            </span>
                            <br />
                            <br />
                            <span className="text-primary">
                              {FormattNumber(
                                row.TVente - row.PrixAchat * row.QteVente,
                                2
                              )}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
          )}
        </TableOne>

        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm text-nowrap d-flex justify-content-between align-items-center w-50">
                    Doc :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {
                        DataList.filter((row) =>
                          SerachData(
                            row.designation + row.RefArticle,
                            searchText
                          )
                        ).length
                      }
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Spinner isLoading={isLoading} />

        <table
          ref={ListePrintRef}
          className="table w-100 table-bordered small print "
        >
          {DataList.filter((row) =>
                          SerachData(
                            row.designation + row.RefArticle,
                            searchText
                          )
                        ).map((row, i) => (
            <tbody key={i}>
              {(i % NumPage === 0 || i === 0) && (
                <>
                  <tr className="w-100 text-center border-0">
                    <th colSpan={5} className="border-0">
                      <div className="d-flex justify-content-between">
                        <span>
                          Stock Périodique :{" "}
                          {reformatDate(
                            formatDate(
                              PeriodeDateurRef.current.getPeriode().debut
                            )
                          )}{" "}
                          /{" "}
                          {reformatDate(
                            formatDate(
                              PeriodeDateurRef.current.getPeriode().fin
                            )
                          )}
                        </span>
                        <span>
                          Page N° : {i / NumPage + 1} /
                          {Math.round((DataList.filter((row) =>
                          SerachData(
                            row.designation + row.RefArticle,
                            searchText
                          )
                        ).length - 1) / NumPage) + 1}
                        </span>
                      </div>
                    </th>
                  </tr>
                  <tr className="w-100 text-center ">
                    <th className=" bg-secondary text-white">Désignation</th>
                    <th className=" bg-secondary text-white">INI</th>
                    <th className=" bg-secondary text-white">Entrée</th>
                    <th className=" bg-secondary text-white">Sortie</th>
                    <th className=" bg-secondary text-white">Final</th>
                  </tr>
                </>
              )}
              <tr
                style={{
                  pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                }}
              >
                <td className="w-100">
                  <b>{row.designation}</b>
                </td>
                <td className="text-center">{row.QteIni}</td>
                <td className="text-center">{row.QteAchat}</td>
                <td className="text-center">{row.QteVente}</td>
                <td className="text-center">
                  {row.QteAchat * 1 - row.QteVente * 1 + row.QteIni * 1}
                </td>
              </tr>
            </tbody>
          ))}
          <tbody>
            <tr>
              <th colSpan={5} className="text-end">
                Nombre d'Articles : {DataList.filter((row) =>
                          SerachData(
                            row.designation + row.RefArticle,
                            searchText
                          )
                        ).length}
              </th>
            </tr>
          </tbody>
        </table>
      </>
    )
  );
};

export default Etatperiodique;
