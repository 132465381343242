import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { getUsersList } from "../../../services/params";
import { isLogin, padNumber } from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import EditUserModal from "./EditUser";

const UsersList = () => {
  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [SearchText, SetSearchText] = useState("");

    const ListePrintRef = useRef();
  const UserModal_Ref = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = async () => {
    
      setLoading(true);
      const data = await getUsersList();
      setDataList(data);
      setLoading(false);
    
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/params/users");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      setLoading(true);
      const data = await getUsersList();
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Stock Periodique"));
  }, [dispatch, navigate]);

  return (
    isLogin() && (
      <>
        <div className="row mb-3 not-print">
          <div className="col-10 col-md-6 text-end d-flex gap-2 ">
            <div className="input-group">
              <input
                className="form-control"
                onChange={(e) => SetSearchText(e.target.value)}
                placeholder="Recherche"
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
          <div className="col-2 col-md-6 pt-1">
          <button
              className="btn btn-outline-primary btn-sm"
              onClick={() =>UserModal_Ref.current.OpenModal()}
            >
              <i className="fa fa-user-plus"></i>
            </button>
            
          </div>
        </div>

        <TableOne vh="vh-scroled-1">
          {DataList.map((row, i) => (
            <tr key={i}>
              <td className="py-0" 
              onDoubleClick={() => UserModal_Ref.current.OpenModal({ ...row })}
                >
                <div className="row align-items-center">
                  <div className="col-1 text-center border">
                    <b>{padNumber(i + 1, 2)}</b>
                  </div>
                  <div className="col-9 py-3">
                    Nom Utilisateur :<b>{row.userName}</b>
                  </div>
                  <div className="col-2 text-end">
                    <button
                      className="btn btn-outline-info btn-sm me-1 "
                      onClick={() =>UserModal_Ref.current.OpenModal({ ...row })}
                    >
                      <i className="fa fa-user"></i>
                    </button>
                    <button
                      className="btn btn-outline-info btn-sm "
                      onClick={() => navigate("/params/users/" + row.IdUser)}
                    >
                      <i className="fa fa-sliders"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </TableOne>

        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-25">
                    Doc :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.length}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Spinner isLoading={isLoading} />

        <EditUserModal ref={UserModal_Ref} getData={getData} />

        <table
          ref={ListePrintRef}
          className="table w-100 border table-bordered small print "
        >
          {/* <thead>
              <tr className="w-100 text-center">
                <th colSpan={5}>
                  <div className="d-flex justify-content-between">
                    <span>Stock Périodique </span>

                    <span>le {reformatDate(formatDate(Date()))}</span>
                  </div>
                </th>
              </tr>
              <tr className="w-100 text-center ">
                <th>Désignation</th>
                <th>INI</th>
                <th>Entrée</th>
                <th>Sortie</th>
                <th>Final</th>
              </tr>
            </thead>
            <tbody>
              {DataList.map((row, i) => (
                <tr key={i}>
                  <td className="w-100">
                    <b>{row.designation}</b>
                  </td>
                  <td className="text-center">{row.QteIni}</td>
                  <td className="text-center">{row.QteAchat}</td>
                  <td className="text-center">{row.QteVente}</td>
                  <td className="text-center">
                    {row.QteAchat * 1 - row.QteVente * 1 + row.QteIni * 1}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="page-break">
              <tr>
                <th colSpan={5} className="text-end">
                  Nombre Inventaire : {DataList.length}
                </th>
              </tr>
            </tfoot> */}
        </table>
      </>
    )
  );
};

export default UsersList;
