const Page404 = () => {
    return (
        <>
            <main>
                404
            </main>
        </>
    );
};

export default Page404;