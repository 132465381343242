import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  getBonAchatListe,
  AddBonAchat,
  getSingleBA,
  deleteBonAchat,
} from "../../../services/achats";
import {
  isLogin,
  GetDate,
  FormattNumber,
  formatDate,
  reformatDate,
  SerachData,
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import EditReglementModal from "./EditReglementBA";
import EditBAModal from "./EditBAModal";
import PeriodeDateur from "../../../components/widgets/periode/periode";

const BonsAchats = ({ pageRoles }) => {
  const NumPage = 28;

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [OrderText, SetOrderText] = useState("Ordre ");

  const [currentPrint, setCurrentPrint] = useState({
    IdBA: 0,
    NumBA: 0,
    CodeFour: "",
    DateBA: formatDate(new Date()),
    Mod_HT: "0",
    Avoir: 1,
    MontantTTC: 0,
    Tva: 0,
    Facture: 0,
    Type: "A",
    Etat: "C",
    Description: "",
    UserID: 0,
    Fournisseur: "Comptant",
    Reglement: 0,
  });
  const [currentDetailPrint, setDetailCurrentPrint] = useState([]);

  const seach = useRef();
  const ReglementModal_Ref = useRef();
  const EditBAModal_Ref = useRef();
  const PeriodeDateurRef = useRef();

  const ListePrintRef = useRef();
  const DetailPrintRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const switchPrint = (doc) => {
    if (doc === "Detail") {
      ListePrintRef.current.classList.remove("print");
      DetailPrintRef.current.classList.add("print");
    } else {
      ListePrintRef.current.classList.add("print");
      DetailPrintRef.current.classList.remove("print");
    }
  };

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      setLoading(true);
      const data = await getBonAchatListe(Periode);
      setDataList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/liste");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getBonAchatListe(periode);
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Bons Des Achats"));
  }, [dispatch, navigate]);

  const AddBon = async (Avoir) => {
    setLoading(true);
    AddBonAchat(Avoir)
      .then((LastID) => {
        setLoading(false);
        return getSingleBA(LastID);
      })
      .then((Bon) => EditBAModal_Ref.current.OpenModal({ ...Bon }))
      .catch((e) => {
        alert("Erreur Ajout");
        console.log(e);
      });
  };

  const delelteBon = (Bon) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(`Êtes vous sûr de vouloir supprimer le Bon N°: \n ${Bon.NumBA} ?`)
    ) {
      setLoading(true);
      deleteBonAchat(Bon.IdBA)
        .then((message) => {
          setLoading(false);
          getData();
          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  return (
    isLogin() &&
    DataList && (
      <>
        <div className="row mb-lg-2 not-print">
          <div className="col-12 col-md-5 text-end d-flex gap-2 mb-1">
            <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />
          </div>
          <div className="col-12 col-md-7 text-end d-flex gap-2 mb-1 ">
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => pageRoles.upte && AddBon(-1)}
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-minus"></i>
            </button>
            <div className="input-group">
              <input
                ref={seach}
                className="form-control form-control-sm"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Fournisseurs"
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => pageRoles.upte && AddBon(1)}
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <TableOne vh="vh-scroled-1">
          {DataList.map(
            (row, i) =>
              SerachData(row.Fournisseur, searchText) && (
                <tr key={i}>
                  <td
                    onDoubleClick={() => {
                      switchPrint("Detail");
                      setCurrentPrint({ ...row });
                      EditBAModal_Ref.current.OpenModal({ ...row });
                    }}
                  >
                    <div className="row align-items-end align-items-lg-center">
                      {row.MontantTTC * row.Avoir >= 0 ? (
                        <div className="col-6 col-md-2">
                          <b>BA : {row.NumBA}</b>
                        </div>
                      ) : (
                        <div className="col-6 col-md-2 btn btn-danger btn-sm">
                          Retour : {row.NumBA}
                        </div>
                      )}
                      <div className="col-6 col-md-2 text-lg-center text-end">
                        {reformatDate(row.DateBA)}
                      </div>
                      <div className="col-12 col-md-3">
                        <div>
                          <b>{row.Fournisseur} </b>
                        </div>
                        <div className="text-end text-secondary">
                          {row.Description}
                        </div>
                      </div>

                      <div className="col-7 col-md-3 text-end">
                        <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-100 mb-1 ">
                          Total
                          <span className="badge bg-white w-50 text-end text-dark">
                            {FormattNumber(row.MontantTTC * row.Avoir, 2)}
                          </span>
                        </button>
                        <button className="btn btn-outline-success btn-sm d-flex justify-content-between align-items-center w-100">
                          Reglé
                          <span className="badge bg-white w-50 text-end text-dark">
                            {FormattNumber(row.Reglement - 0, 2)}
                          </span>
                        </button>
                      </div>
                      <div className="col-5 col-md-2 text-end">
                        <button
                          className="btn btn-success btn-sm "
                          onClick={() =>
                            pageRoles.upte &&
                            ReglementModal_Ref.current.OpenModal(
                              row.IdBA,
                              row.CodeFour
                            )
                          }
                          disabled={!pageRoles.upte}
                        >
                          <i className="fa fa-usd"></i>
                        </button>

                        <button
                          className="btn btn-primary btn-sm mx-1"
                          onClick={() => {
                            switchPrint("Detail");
                            setCurrentPrint({ ...row });
                            EditBAModal_Ref.current.OpenModal({ ...row });
                          }}
                        >
                          <i className="fa fa-edit"></i>
                        </button>

                        {row.Reglement - 0 === 0 ? (
                          <button
                            className="btn btn-warning btn-sm delete"
                            onClick={() => pageRoles.del && delelteBon(row)}
                            disabled={!pageRoles.del}
                          >
                            <i className="fa fa-trash-o"></i>
                          </button>
                        ) : (
                          <button className="btn btn-warning btn-sm " disabled>
                            <i className="fa fa-trash-o"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )
          )}
        </TableOne>

        <table className="w-100 mt-lg-2 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-between align-items-center flex-wrap gap-1">
                  <button className="btn flex-fill btn-outline-secondary text-nowrap btn-sm d-flex justify-content-between align-items-center ">
                    Doc :
                    <span className=" bg-white  text-end text-dark">
                      {
                        DataList.filter((row) =>
                          SerachData(row.Fournisseur, searchText)
                        ).length
                      }
                    </span>
                  </button>

                  <button className="btn flex-fill btn-outline-primary text-nowrap btn-sm d-flex justify-content-between align-items-center text-danger">
                    Achat :
                    <span className=" badge bg-white  text-end text-dark">
                      {FormattNumber(
                        DataList.reduce(
                          (q, row) =>
                            SerachData(row.Fournisseur, searchText)
                              ? q + row.MontantTTC * row.Avoir
                              : q,
                          0
                        ),
                        2
                      )}
                    </span>
                  </button>
                  <button className="btn flex-fill btn-outline-success text-nowrap btn-sm d-flex justify-content-between align-items-center ">
                    Réglé :
                    <span className="badge bg-white text-end text-dark">
                      {FormattNumber(
                        DataList.reduce(
                          (q, row) =>
                            SerachData(row.Fournisseur, searchText)
                              ? q + row.Reglement * 1
                              : q,
                          0
                        ),
                        2
                      )}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <EditBAModal
          ref={EditBAModal_Ref}
          getData={getData}
          switchPrint={switchPrint}
          setCurrentPrint={setCurrentPrint}
          setDetailCurrentPrint={setDetailCurrentPrint}
          upte={pageRoles.upte}
        />
        <Spinner isLoading={isLoading} />
        <EditReglementModal
          ref={ReglementModal_Ref}
          getData={getData}
          upte={pageRoles.upte}
        />

        {/* impression */}

        <table
          ref={ListePrintRef}
          className="table w-100 table-bordered small d-none print"
        >
          {DataList.filter(row => SerachData(row.Fournisseur,searchText)).map((row, i) => (
            <tbody key={i}>
              {(i % NumPage === 0 || i === 0) && (
                <>
                  <tr className="w-100 text-center border-0">
                    <th colSpan={4} className="border-0 px-0">
                      <div className="d-flex justify-content-between">
                        <span>
                          Bons d'Achats :{" "}
                          {reformatDate(
                            formatDate(
                              PeriodeDateurRef.current.getPeriode().debut
                            )
                          )}{" "}
                          /{" "}
                          {reformatDate(
                            formatDate(
                              PeriodeDateurRef.current.getPeriode().fin
                            )
                          )}
                        </span>
                        <span>
                          Page N° : {i / NumPage + 1} /
                          {Math.round((DataList.filter(row => SerachData(row.Fournisseur,searchText)).length - 1) / NumPage) + 1}
                        </span>
                      </div>
                    </th>
                  </tr>
                  <tr className="w-100 text-center ">
                    <th>Num</th>
                    <th>Date</th>
                    <th>Fournisseur</th>
                    <th>Total</th>
                  </tr>
                </>
              )}
              <tr
                style={{
                  pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                }}
              >
                {row.MontantTTC * row.Avoir >= 0 ? (
                  <td className="small" style={{ minWidth: "150px" }}>
                    <b>BA : {row.NumBA}</b>
                  </td>
                ) : (
                  <td className="text-danger small">Rt : {row.NumBA}</td>
                )}
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {reformatDate(row.DateBA)}
                </td>
                <td className="w-75">{row.Fournisseur}</td>
                <td className="text-end" style={{ minWidth: "120px" }}>
                  {FormattNumber(row.MontantTTC * row.Avoir, 2)}
                </td>
              </tr>
            </tbody>
          ))}
          <tbody>
            <tr>
              <th colSpan={3}>Nombre Achats : {DataList.filter(row => SerachData(row.Fournisseur,searchText)).length}</th>
              <th className="text-end samll">
                <b>Total </b> :
                {FormattNumber(
                  DataList.filter(row => SerachData(row.Fournisseur,searchText)).reduce(
                    (q, row) => q + row.MontantTTC * row.Avoir,
                    0
                  ),
                  2
                )}
              </th>
            </tr>
          </tbody>
        </table>

        <table ref={DetailPrintRef} className="table table-borderless d-none">
          <thead>
            <tr className="w-100 text-center ">
              <td colSpan={6}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end" colSpan={2}>
                        Date Achat : {reformatDate(currentPrint.DateBA)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "200px", height: "100px" }}
                        className="border"
                      >
                        Logo
                      </td>
                      <td>
                        Fournisseur : <b>{currentPrint.Fournisseur}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-end">
                        N° Achat :
                        <b>
                          {currentPrint.NumBA} /{currentPrint.DateBA.substr(-2)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="text-center  bg-secondary text-white  rounded-start">
                N°
              </td>
              <th className="w-50 text-center bg-secondary text-white border border-0 border-top border-white">
                Désignation
              </th>

              <th
                className="text-center  bg-secondary text-white  "
                style={{ minWidth: "60px" }}
              >
                Qté
              </th>
              <th
                className="text-center bg-secondary text-white "
                style={{ minWidth: "80px" }}
              >
                PU
              </th>
              <th
                className="text-center  bg-secondary text-white "
                style={{ minWidth: "40px" }}
              >
                R%
              </th>
              <th
                className="text-center  bg-secondary text-white rounded-end"
                style={{ minWidth: "120px" }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody className="small">
            {currentDetailPrint.map((row, i) => (
              <tr key={i} className="border">
                <td className="text-center border">
                  <b>{i + 1}</b>
                </td>
                <td className="w-75">
                  {row.RefArticle} | {row.designation}
                </td>

                <td className="text-center border">{row.Quantite}</td>
                <td className="text-end">{FormattNumber(row.Prix - 0, 2)}</td>
                <td className="text-center border">{row.Remise}%</td>
                <td className="text-end border">
                  {FormattNumber(row.Total - 0, 2)}
                </td>
              </tr>
            ))}
            <tr className="w-100 text-center ">
              <td colSpan={6}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end">
                        Total Achat :
                        {FormattNumber(currentPrint.MontantTTC * 1, 2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
          <tfoot className="page-break">
            <tr>
              <th colSpan={6}>
                <div className="d-flex justify-content-between">
                  <span></span>
                  <span id="pageFooter"></span>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    )
  );
};
export default BonsAchats;
