import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getBanqueJournal, getBanqueCompte } from "../../../services/tresorie";
import {
  isLogin,
  GetDate,
  FormattNumber,
  reformatDate,
} from "../../../services/global";
import PeriodeDateur from "../../../components/widgets/periode/periode";
import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import EditCaisseModal from "./EditCaisse";

const BanquesOperations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const PeriodeDateurRef = useRef();
  const CaisseModal_Ref = useRef();

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [CompteList, setCompteList] = useState([]);
  const [CurrentBanque, setCurrentBanque] = useState(0);

  const getDataCompteListe = async () => {
    const data = await getBanqueCompte();
    setCompteList(data);
    setCurrentBanque(data.length>0?data[0].IdBanque:0);
  };

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      setLoading(true);

      sessionStorage.setItem("Debut", Periode.debut);
      sessionStorage.setItem("Fin", Periode.fin);

      const data = await getBanqueJournal(Periode);
      setDataList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/tresorie/banques/operations");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getBanqueJournal(periode);
      setDataList(data);
      setLoading(false);
    };

    getDataCompteListe();
    getFistData();

    dispatch(SetPageTitle("Banques Opérations"));
  }, [dispatch, navigate]);

  return (
    isLogin() &&
    DataList && (
      <>
        {/* entete */}
        <div className="row mb-3 not-print">
          <div className="d-flex justify-content-between">
            <div className="col-12 col-md-6 text-end d-flex gap-2 mb-1">
              <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />

              <button
                className="btn btn-outline-secondary d-none d-sm-block"
                onClick={() => window.print()}
              >
                <i className="fa fa-print" aria-hidden="true"></i>
              </button>
            </div>
            <div className="form-group">
              <select
                className="form-select"
                 onChange={(e) => (setCurrentBanque(e.target.value))}
              >
                {CompteList.map((row, i) => (
                  <option key={i} value={row.IdBanque}>
                    {row.Nom} : {row.Compte}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* liste des Caisse */}
        <TableOne vh="vh-scroled-1">
          {DataList.map((row, i) => (
            row.IdBanque*1 === CurrentBanque*1 &&
            <tr key={i}>
              <td
                onDoubleClick={() =>
                  CaisseModal_Ref.current.OpenModal({ ...row })
                }
              >
                <div className="row" style={{ fontSize: "14px" }}>
                  <div className="col-6 col-md-2 text-center border py-2">
                    {reformatDate(row.DateOp)}<br/> 
                  </div>
                  <div className="col-6 col-md-2">
                    <b>OP :</b> {row.TypeOP}
                    <br />
                    <b>N ° :</b> {row.NumDoc}
                    
                  </div>
                  <div className="col-6 col-md-5">
                  <b>Au Profit</b> : {row.AuProfit}
                    <br />
                    {row.Commentaire}
                  </div>
                  <div className="col-6 col-md-2">
                  {row.sens * 1 === 1 ? (
                   
                      <button className="btn btn-outline-success btn-sm border d-flex justify-content-between align-items-center w-100 mb-1">
                        Crédit
                        <span className="badge bg-white w-50 text-end text-dark">
                          {FormattNumber(row.Montant * 1, 2)}
                        </span>
                      </button>
                    
                  ) : (
                    
                      <button className="btn btn-outline-danger btn-sm border d-flex justify-content-between align-items-center w-100 mb-1">
                        Débit
                        <span className="badge bg-white w-50 text-end text-dark">
                          {FormattNumber(row.Montant * 1, 2)}
                        </span>
                      </button>
                   
                  )}
                  </div>
                  <div className="col-6 col-md-1 text-end">
                    <button
                      className="btn bg-primary-1 btn-sm "
                      onClick={() =>
                        CaisseModal_Ref.current.OpenModal({ ...row })
                      }
                      type="button"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </TableOne>

        {/* footer */}
        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-50">
                    Doc :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.length}
                    </span>
                  </button>

                  <button className="btn btn-outline-success btn-sm d-flex justify-content-between align-items-center w-50">
                    Solde :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.length > 0
                        ? FormattNumber(
                            DataList[DataList.length - 1].Solde * 1,
                            2
                          )
                        : 0}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* modal edition */}
        <EditCaisseModal ref={CaisseModal_Ref} getData={getData} />
        <Spinner isLoading={isLoading} />

        {/* impression */}
        {/* <table className="table w-100 border table-bordered small ">
          <thead>
            <tr className="w-100 text-center">
              <th colSpan={4}>
                <div className="d-flex justify-content-between">
                  <span>Liste des Caisse</span>

                  <span>Tétouan le 12/12/31</span>
                </div>
              </th>
            </tr>
            <tr className="w-100 text-center ">
              <th style={{minWidth:'120px'}}>Date</th>
              <th>Mode</th>
              <th>Client</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>
            {DataList.map((row, i) => (
              <tr key={i}>                
                <td className="text-center">{reformatDate(row.DateReg)}</td>
                <td className="text-center">{row.DateOp}</td>
                <td className="text-start w-100">{row.Client}</td>
                <td className="text-end">{FormattNumber(row.Montant - 0, 2)}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={6}>Fin</th>
            </tr>
          </tbody>
          <tfoot className="page-break">
            <tr>
              <th colSpan={4}>
                <div className="d-flex justify-content-between">
                  <span>Liste des Caisses</span>
                  <span id="pageFooter"></span>
                </div>
              </th>
            </tr>
          </tfoot>
        </table> */}
      </>
    )
  );
};

export default BanquesOperations;
