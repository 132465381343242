import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import {
  updateFournisseur,
  AddFournisseur,
} from "../../../services/fournisseurs";

const EditFournisseur = forwardRef((props, ref) => {
  const FormContainer = useRef();

  const [FromErrors, setFromErrors] = useState({});
  const [currentFournisseur, setCurrentFournisseur] = useState({});
  const [ShowDetail, setShowDetail] = useState(false); 

  const initialFournisseur = {
    IdFournisseur: "",
    Fournisseur: "",
    ICE: "",
    Adresse: "",
    Tel: "",
    Mobile: "",
    Solde: "0",
    Etat: "A",
  };

  useImperativeHandle(ref, () => ({
    OpenEditData(Fournisseur = initialFournisseur) {
      setShowDetail(true);
      setCurrentFournisseur(Fournisseur);

      const form = FormContainer.current;
      form.Fournisseur.value = Fournisseur.Fournisseur;
      form.ICE.value = Fournisseur.ICE;
      form.Adresse.value = Fournisseur.Adresse;
      form.Tel.value = Fournisseur.Tel;
      form.Mobile.value = Fournisseur.Mobile;      
    },
  }));

  const VerifData = () => {
    let verif = {};
    if (currentFournisseur.Fournisseur === "")
      verif = { ...verif, Fournisseur: "Erreur Fournisseur" };

    setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    console.log(currentFournisseur.IdFournisseur);
    if (currentFournisseur.IdFournisseur === "") {
      AddFournisseur(currentFournisseur).then((data) => {
        props.getData();
        currentFournisseur.IdFournisseur = data.lastID;

        console.log(data.message);
      });
    } else {
      console.log(currentFournisseur);
      updateFournisseur(currentFournisseur)
        .then((message) => {
          props.getData();

          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const Newbtn = () => {
    setCurrentFournisseur(initialFournisseur);
    const form = FormContainer.current;
    form.Fournisseur.value = initialFournisseur.Fournisseur;
    form.ICE.value = initialFournisseur.ICE;
    form.Adresse.value = initialFournisseur.Adresse;
    form.Tel.value = initialFournisseur.Tel;
    form.Mobile.value = initialFournisseur.Mobile;
  };

  return (    
    <div
      className={
        ShowDetail
          ? "bg-dark bg-opacity-25 modal-fullscreen w-100 fixed-top d-flex align-items-center justify-content-center "
          : "d-none"
      }
      style={{ position: "fixed", zIndex: "99", left: "0px", top: "-91px" }}
    >
      <div
        className="bg-white border rounded-3 position-relative shadow-lg p-3"
        style={{ width: "500px", minHeight: "230px" }}
      >
        <div className="d-flex py-2 border-bottom justify-content-between">
          <h5 className="modal-title" id="myModalLabel">
            Edition Fournisseur
          </h5>

          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setShowDetail(false)}
          ></button>
        </div>
        <form ref={FormContainer} className="my-2">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>Fournisseur</label>
                <input
                  id="Fournisseur"
                  className="form-control"
                  type="text"
                  onChange={(e) =>
                    (currentFournisseur.Fournisseur = e.target.value)
                  }
                />
              </div>
              <span className="text-danger">
                {FromErrors.Fournisseur || ""}
              </span>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="mb-1">ICE </label>
                <input
                  id="ICE"
                  className="form-control"
                  type="text"
                  onChange={(e) => (currentFournisseur.ICE = e.target.value)}
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label>Tél</label>
                <input
                  className="form-control"
                  type="text"
                  id="Tel"
                  onChange={(e) => (currentFournisseur.Tel = e.target.value)}
                />
              </div>
              <span className="text-danger">{FromErrors.Tel || ""}</span>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Mobile</label>
                <input
                  className="form-control"
                  type="text"
                  id="Mobile"
                  onChange={(e) => (currentFournisseur.Mobile = e.target.value)}
                />
              </div>
              <span className="text-danger">{FromErrors.Tel || ""}</span>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>Adresse</label>
                <input
                  className="form-control"
                  type="text"
                  id="Adresse"
                  onChange={(e) =>
                    (currentFournisseur.Adresse = e.target.value)
                  }
                />
              </div>
              <span className="text-danger">{FromErrors.Adresse || ""}</span>
            </div>
          </div>
        </form>
        <div
          className="d-flex justify-content-between"
          style={{ maxHeight: "30px" }}
        >
          <button
            onClick={() => {
              props.upte && Newbtn();
            }}
            type="button"
            className="btn btn-outline-warning text-black btn-sm"
            disabled={!props.upte}
          >
            Nouveau
          </button>
          <div>
            <button
              type="button"
              className="btn btn-secondary mx-1 btn-sm"
              onClick={() => setShowDetail(false)}
            >
              Fermer
            </button>

            <button
              onClick={() =>props.upte &&  VerifData()}
              type="button"
              className="btn btn-primary btn-sm"
              disabled={!props.upte}
              
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditFournisseur;
