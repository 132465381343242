import { useEffect, useState, useRef } from "react";
import { getExtraChamp, getExtraChampDetail, SaveExtraChamp } from "./service";

const ExtraForm = (props) => {
  

  const ExtraForm = useRef();

  const [ListExtraChamp, setListExtraChamp] = useState([]);
  const [ListExtraChampDetail, setListExtraChampDetail] = useState([]);

  const closeExtra = () => {
    ExtraForm.current.style.display = "none";
  };

  const getData = async () => {
    try {
      for (let row of ListExtraChamp) {
        document.getElementById(row.IdChamp).value = ListExtraChampDetail.find(
          ({ IdChamp }) => IdChamp === row.IdChamp
        )
          ? ListExtraChampDetail.find(({ IdChamp }) => IdChamp === row.IdChamp)
              .ExtraValue
          : row.Valeur;
      }
      if (ExtraForm.current.style.display === "block") {
        ExtraForm.current.style.display = "none";
      } else {
        ExtraForm.current.style.display = "block";
      }
    } catch (exceptionVar) {}
  };

  const SaveSingleExtraChamp = (IdChampDetail, IdOrigine, ExtraValue) => {
    SaveExtraChamp(IdChampDetail, IdOrigine, ExtraValue);
  };

  const UpdateExtra = (IdChamp2, newVal) => {
    if (ListExtraChampDetail.find(({ IdChamp }) => IdChamp === IdChamp2)) {
      ListExtraChampDetail.find(
        ({ IdChamp }) => IdChamp === IdChamp2
      ).ExtraValue = newVal;
    } else {
      ListExtraChampDetail.push({ IdChamp: IdChamp2, ExtraValue: newVal });
    }

    SaveSingleExtraChamp(IdChamp2, props.idOrigine, newVal);
  };

  useEffect(() => {
    ExtraForm.current.style.display = "none";
   

    const getFistData = async () => {
      setListExtraChampDetail(
        await getExtraChampDetail(props.Tble, props.idOrigine)
      );
      setListExtraChamp(await getExtraChamp(props.Tble));

      try {
        for (let row of ListExtraChamp) {
          document.getElementById(row.IdChamp).value = row.Valeur;
        }
      } catch (exceptionVar) {}
    };
    getFistData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.idOrigine]);

  return (
    <>
      <div className="w-100 text-end">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={(e) => getData()}
        >
          Extra
        </button>
      </div>

      <form
        ref={ExtraForm}
        className="border border-3 border-secondary p-2 bg-light rounded-2 my-2 shadow-lg"
        style={{
          position: "fixed",
          zIndex: "999",
          width: "380px",
          maxWidth: "98%",
          left: "calc(50% - 190px)",
        }}
      >
        <div className="text-end w-100">
          <span
            className="btn btn-outline-secondary btn-sm "
            onClick={(e) => closeExtra()}
          >
            X
          </span>
        </div>
        <div className="d-flex justify-content-between flex-wrap small ">
          {ListExtraChamp.map((row, i) => (
            <div className="my-2" style={{ width: "30%" }} key={i}>
              <label htmlFor={row.IdChamp}>{row.Champ}</label>

              <input
                className="form-control"
                id={row.IdChamp}
                type="text"
                onBlur={(e) => UpdateExtra(row.IdChamp, e.target.value)}
              />
            </div>
          ))}
        </div>
        
      </form>
    </>
  );
};

export default ExtraForm;
