import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getCaisseSuivi,
} from "../../../services/tresorie";
import {
  isLogin,
  GetDate,
  FormattNumber,
  reformatDate,
} from "../../../services/global";

import PeriodeDateur from "../../../components/widgets/periode/periode";
import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import CaisseFiltre from "./CaisseFiltre";

const CaisseSuivi = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const PeriodeDateurRef = useRef();
  const CaisseModal_Ref = useRef();

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [CompteFiltre, setCompteFiltre] = useState([]);

  const setFiltres = (Filtre) =>{
    setCompteFiltre(Filtre);
  }

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      setLoading(true);

      sessionStorage.setItem("Debut", Periode.debut);
      sessionStorage.setItem("Fin", Periode.fin);

      const data = await getCaisseSuivi(Periode);
      setDataList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/tresorie/caisse/suivi");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getCaisseSuivi(periode);
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Suivi de Caisse"));
  }, [dispatch, navigate]);


  return (
    isLogin() &&
    DataList && (
      <>
        {/* entete */}
        <div className="row mb-3 not-print">
          <div className="d-flex justify-content-between">
          <div className="col-12 col-md-6 text-end d-flex gap-2 mb-1">
            <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />

            <button
              className="btn btn-outline-secondary d-none d-sm-block"
              onClick={() => window.print()}
            >
              <i className="fa fa-print" aria-hidden="true"></i>
            </button>
            
          </div>
          <button
              className="btn btn-outline-secondary d-none d-sm-block text-nowrap"
              onClick={() => CaisseModal_Ref.current.OpenModal()}
            >
              <i className="fa fa-filter py-1" aria-hidden="true"> Filtres</i>
            </button>
            </div>
        </div>

        {/* liste des Caisse */}
        <TableOne vh="vh-scroled-1">
          {DataList.map((row, i) => (
             (CompteFiltre.length === 0 || CompteFiltre.includes(row.idCaisse*1)) &&
            <tr key={i} onDoubleClick={() => CaisseModal_Ref.current.OpenModal()}>
              <td>
                <div className="row">                  
                  <div className="col-6 col-md-3">{reformatDate(row.DateOp)} : <b>{row.Hr}</b></div>
                  <div className="col-12 col-md-3 ">{row.Compte}</div>
                  <div className="col-6 col-md-3"> {row.Commentaire}</div>
                  <div className={"col-6 col-md-3 text-end " + (row.Montant*row.sens<0 ? "text-danger" : "text-primary")}><b>{FormattNumber(row.Montant*row.sens,2)}</b></div>
                </div>
              </td>
            </tr>
          ))}
        </TableOne>

        {/* footer */}
        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-50">
                    Doc :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.length}
                    </span>
                  </button>

                  <button className="btn btn-outline-success btn-sm d-flex justify-content-between align-items-center w-50">
                    Solde :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.length > 0 ? FormattNumber(
                        DataList[DataList.length-1].Solde*1,2):0
                      }
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* modal edition */}
        <CaisseFiltre ref={CaisseModal_Ref} setFiltres={setFiltres} />
        <Spinner isLoading={isLoading} />

        {/* impression */}
        {/* <table className="table w-100 border table-bordered small ">
          <thead>
            <tr className="w-100 text-center">
              <th colSpan={4}>
                <div className="d-flex justify-content-between">
                  <span>Liste des Caisse</span>

                  <span>Tétouan le 12/12/31</span>
                </div>
              </th>
            </tr>
            <tr className="w-100 text-center ">
              <th style={{minWidth:'120px'}}>Date</th>
              <th>Mode</th>
              <th>Client</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>
            {DataList.map((row, i) => (
              <tr key={i}>                
                <td className="text-center">{reformatDate(row.DateReg)}</td>
                <td className="text-center">{row.DateOp}</td>
                <td className="text-start w-100">{row.Client}</td>
                <td className="text-end">{FormattNumber(row.Montant - 0, 2)}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={6}>Fin</th>
            </tr>
          </tbody>
          <tfoot className="page-break">
            <tr>
              <th colSpan={4}>
                <div className="d-flex justify-content-between">
                  <span>Liste des Caisses</span>
                  <span id="pageFooter"></span>
                </div>
              </th>
            </tr>
          </tfoot>
        </table> */}
      </>
    )
  );
};

export default CaisseSuivi;
