
import axios from "axios";
import { deconnect } from './global';

const baseApi="https://api.gstok.com"



export const getCaisseJournal = async (periode) => {
    const data = await axios.post(`${baseApi}/TresorieCaisseJournal`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,       
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const getCaisseduJour = async (DateOp) => {
    const data = await axios.post(`${baseApi}/TresorieCaisseJournal`, {
        action: 'getCaisseduJour',
        token:sessionStorage.getItem("token"),
        DateOp: DateOp,               
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
}

export const getCaisseCompte = async () => {
    const data = await axios.post(`${baseApi}/TresorieCaisseJournal`, {
        action: 'getCaisseCompte',
        token:sessionStorage.getItem("token")                       
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
}

export const UpdateCaisseOperation = async (current) => { 
    // console.log(current)
    const data = await axios.post(`${baseApi}/TresorieCaisseJournal`, {
        action: 'UpdateCaisseOperation',
        token:sessionStorage.getItem("token"),
        
        idOp : current.idOp,
        idCaisse : current.idCaisse,
        DateOp : current.DateOp,
        Montant: current.Montant,
        sens : current.sens,
        Commentaire: current.Commentaire,
        idBanque : current.idBanque,
    })

    // console.log(data)
    if (data.data.message && data.status===200){return data.data.message}
    else {deconnect();}
    
};

export const AddCaisseOperation = async (current) => {
    // console.log(current)
    const data = await axios.post(`${baseApi}/TresorieCaisseJournal`, {
        action: 'AddCaisseOperation',
        token:sessionStorage.getItem("token"),
        idCaisse : current.idCaisse,
        DateOp : current.DateOp,
        Montant: current.Montant,
        sens : current.sens,
        Commentaire: current.Commentaire,        
    })

    // console.log(data)
    if (data.data.message && data.status===200){return data.data}
    // else {deconnect();}
    
};

export const getCaisseSuivi = async (periode) => {
    const data = await axios.post(`${baseApi}/TresorieCaisseJournal`, {
        action: 'getCaisseSuivi',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,       
    });
    //   console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

// Banques
export const getBanqueCompte = async () => {
    const data = await axios.post(`${baseApi}/TresorieBanques`, {
        action: 'getBanqueCompte',
        token:sessionStorage.getItem("token")                       
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
}

export const getBanqueJournal = async (periode) => {
    const data = await axios.post(`${baseApi}/TresorieBanques`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,       
    });
    //  console.log(data.data)
    if (data.data && data.status===200){return data.data}
    // else {deconnect();}
};




