import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle

} from "react";
import {
  getCaisseCompte
} from "../../../services/tresorie";




const CaisseFiltre = forwardRef((props, ref) => {

  const OpenCaisseModalBtn = useRef();

  useImperativeHandle(ref, () => ({
    async OpenModal(CaisseDay) {           
      OpenCaisseModalBtn.current.click(); 
    },
  }));

  const [CompteList, setCompteList] = useState([]);
  const [StatChange, setStatChange] = useState(false);

  const setFiltres = () => {
    const Filtre =[];
    CompteList.forEach((element) => {
      if(element.checked*1===1){
        Filtre.push(element.IdComptCaisse*1);
      }
    });
    console.log(Filtre)
    props.setFiltres(Filtre);
     setStatChange(!StatChange);
    
  }

  const getDataCompteListe = async () => {
    const data = await getCaisseCompte();
    setCompteList(data);
  };

  useEffect(() => {
    getDataCompteListe()
  }, []);

  return (
    <>
      <button
        type="button"
        ref={OpenCaisseModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModal"
      >
        OpenCaisseModalBtn
      </button>

      <div
        className="modal fade"
        id="myModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              header
              <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
            {CompteList.map((row, i) => (
                           
                <p key={i}>
                  {row.Compte} / {row.checked}
                  <input type="checkbox" checked={row.checked*1===1} 
                  onChange={() => {row.checked = row.checked*1 === 1 ? 0:1;setFiltres() }} />
                </p>
                
              
              ))}
            </div>
            <div className="modal-footer">footer</div>
          </div>
        </div>
      </div>

    </>
  );
});

export default CaisseFiltre;
