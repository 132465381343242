import axios from "axios";
import { deconnect } from "./global";

const baseApi = "https://api.gstok.com";

export const getClientListe = async () => {
  const data = await axios.post(`${baseApi}/VentesClients`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getSingleClient = async (IdClient) => {
  const data = await axios.post(`${baseApi}/VentesClients`, {
    action: "fetchSingle",
    token: sessionStorage.getItem("token"),
    IdClient: IdClient,
  });
  // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const searchClient = async (seachValue) => {
  const data = await axios.post(`${baseApi}/VentesClients`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
    search: seachValue,
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const updateClient = async (current) => {
  const data = await axios.post(`${baseApi}/VentesClients`, {
    action: "update",
    token: sessionStorage.getItem("token"),
    ICE: current.ICE,
    Client: current.Client,
    IdClient: current.IdClient,
    Adresse: current.Adresse,
    Tel: current.Tel,
    Ville: current.Ville,
  });

  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const AddClient = async (current) => {
  const data = await axios.post(`${baseApi}/VentesClients`, {
    action: "insert",
    token: sessionStorage.getItem("token"),
    ICE: current.ICE,
    Client: current.Client,
    IdClient: current.IdClient,
    Adresse: current.Adresse,
    Tel: current.Tel,
    Ville: current.Ville,
    Solde: current.Solde,
  });
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const delelteClient = async (current) => {
  const data = await axios.post(`${baseApi}/VentesClients`, {
    action: "delete",
    token: sessionStorage.getItem("token"),
    IdClient: current.IdClient,
    user: 0,
  });

  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

// Mouvement
export const getDataMouvement = async (periode, IdClient) => {
  sessionStorage.setItem("Debut", periode.debut);
  sessionStorage.setItem("Fin", periode.fin);
  const Solde = await axios.post(`${baseApi}/VentesClientMouvement`, {
    action: "getSolde",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
    CurrentId: IdClient,
  });

  let reportDebut = 0;
  if (Solde.data && Solde.status === 200) {
    reportDebut = Solde.data[0].reportDebut;
  } else {
    deconnect();
    return;
  }

  const data = await axios.post(`${baseApi}/VentesClientMouvement`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
    CurrentId: IdClient,
  });

  let dataMouvement = data.data;

  if (dataMouvement.length > 0) {
    dataMouvement[0].report = reportDebut * 1;
    for (let i = 1; i < dataMouvement.length; i++) {
      dataMouvement[i].report =
        dataMouvement[i - 1].report +
        dataMouvement[i - 1].Montant * dataMouvement[i - 1].sens;
    }
  }

  return dataMouvement;
};
