import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDownloadExcel } from "react-export-table-to-excel";

import { getProductListe, delelteArticle } from "../../../services/produits";

import {
  isLogin,
  formatDate,
  reformatDate,
  FormattNumber,
  sortBy,
  SerachData,
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import FooterTable from "../../../components/widgets/tables/FooterTable";
import EditProductModal from "./EditProductModal";

let sortParm = "";
let sortType = "S";
let sortSens = 1;

const Liste = ({ pageRoles }) => {
  const NumPage = 28;

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [SwitchEtat, SetSwitchEtat] = useState("A");
  const [OrderText, SetOrderText] = useState("Ordre ");
  const [SwitchType, SetSwitchType] = useState("A");
  const [searchText, setSearchText] = useState("");

  const ProductModal_Ref = useRef();
  const ListePrintRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { onDownload } = useDownloadExcel({
    currentTableRef: ListePrintRef.current,
    filename: "Liste des Produits",
    // sheet: 'Users'
  });

  const getData = () => {
    setLoading(true);
    getProductListe()
      .then((data) => {
        setLoading(false);
        setDataList(data);
        getSortedData(undefined, undefined, data, sortSens);
      })
      .catch(() => alert("test"));
  };

  const getSortedData = (
    param = sortParm,
    TypeData = sortType,
    data = [...DataList],
    sens = 0
  ) => {
    if (param !== "") {
      sens = sens === 0 ? (sortParm === param ? -1 * sortSens : 1) : sortSens;
      sortParm = param;
      sortType = TypeData;
      sortSens = sens;

      setDataList(sortBy(param, TypeData, data, sens));
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/produits/liste");
    if (!isLogin()) {
      navigate("/login");
    } else {
      const getData = () => {
        setLoading(true);
        getProductListe()
          .then((data) => {
            setLoading(false);
            setDataList(data);
          })
          .catch(() => alert("test"));
      };
      getData();
      dispatch(SetPageTitle("Liste des Produits"));
    }
  }, [dispatch, navigate]);

  const delelteProduct = (Article) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Êtes vous sûr de vouloir supprimer\n ${Article.RefArticle}\n ${Article.Designation} ?`
      )
    ) {
      setLoading(true);
      delelteArticle(Article)
        .then((message) => {
          setLoading(false);
          getData();
          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  return (
    isLogin() &&
    DataList && (
      <>
        <div className="row mb-1 not-print">
          <div className="col-12 col-md-6 mb-2">
            <div className="input-group">
              <input
                className="form-control"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Réference | Famille | Désignation"
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
          <div className="col-4 col-md-2 d-flex align-items-center">
            <div className="btn-group">
              <button
                className="btn btn-light  text-success"
                onClick={() => onDownload()}
              >
                <i className="fa fa-file-excel-o"></i>
                <div className="infobulle btn bg-light border border-2 btn-sm">
                  Export Excel
                </div>
              </button>
            </div>
            <div className="dropdown small">
              <button
                className="btn dropdown-toggle btn-sm"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {OrderText}
              </button>
              <ul className="dropdown-menu bg-light">
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("DesignationFamille", "S", undefined);
                      SetOrderText("Famille");
                    }}
                  >
                    Famille
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("Designation", "S", undefined);
                      SetOrderText("Designation");
                    }}
                  >
                    Designation
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => {
                      getSortedData("PrixVTTC", "N", undefined);
                      SetOrderText("Prix de Vente");
                    }}
                  >
                    Prix de Vente
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-3 col-md-2 d-flex align-items-center mt-2">
            <div className="form-check form-switch">
              <input
                className={
                  SwitchType === "A"
                    ? "form-check-input bg-darkg border-0"
                    : "form-check-input "
                }
                id="SwitchType"
                type="checkbox"
                checked={SwitchType === "A"}
                onChange={() => SetSwitchType(SwitchType === "A" ? "S" : "A")}
              />
              <label className="form-check-label fw-bold " htmlFor="SwitchType">
                {SwitchType === "A" ? (
                  <span className="text-dark-2">Articles</span>
                ) : (
                  <span className="text-success">Services</span>
                )}
              </label>
            </div>
          </div>
          <div className="col-3 col-md-1 d-flex align-items-center mt-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input border-0"
                id="SwitchEtat"
                type="checkbox"
                checked={SwitchEtat === "A"}
                onChange={() => SetSwitchEtat(SwitchEtat === "A" ? "S" : "A")}
              />
              <label className="form-check-label fw-bold" htmlFor="SwitchEtat">
                {SwitchEtat === "A" ? (
                  <span>Active</span>
                ) : (
                  <span className="text-secondary">Archive</span>
                )}
              </label>
            </div>
          </div>
          <div className="col-2 col-md-1 text-end">
            <button
              className="btn btn-primary btn-sm "
              disabled={!pageRoles.upte}
              onClick={() =>
                pageRoles.upte && ProductModal_Ref.current.OpenModal()
              }
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <TableOne vh="vh-scroled-2">
          {DataList.map(
            (row, i) =>
              SwitchEtat === row.Etat &&
              SwitchType === row.Type &&
              SerachData(
                row.Designation + row.DesignationFamille + row.RefArticle,
                searchText
              ) && (
                <tr key={i}>
                  <td>
                    <div className="row border-light-subtle">
                      <div
                        onDoubleClick={() =>
                          ProductModal_Ref.current.OpenModal({ ...row })
                        }
                        className="col-12 col-md-4"
                      >
                        <b className={row.Etat === "S" ? "text-danger" : ""}>
                          {row.RefArticle}
                        </b>
                        | {row.DesignationFamille}
                        <div className="text-center">{row.Designation}</div>
                      </div>

                      <div
                        onDoubleClick={() =>
                          ProductModal_Ref.current.OpenModal({ ...row })
                        }
                        className="col-6  col-md-2"
                      >
                        Prix A : <b>{row.PrixATTC}</b>
                      </div>
                      <div
                        onDoubleClick={() =>
                          ProductModal_Ref.current.OpenModal({ ...row })
                        }
                        className="col-6  col-md-2"
                      >
                        <b>
                          {SwitchType === "A" ? "Stock : " + row.Stock : ""}
                        </b>
                      </div>
                      <div
                        onDoubleClick={() =>
                          ProductModal_Ref.current.OpenModal({ ...row })
                        }
                        className="col-6  col-md-2"
                      >
                        Prix V : <b>{row.PrixVTTC}</b>
                      </div>
                      <div className="col-6  col-md-2 text-end">
                        <button
                          className="btn btn-info btn-sm mx-1"
                          onClick={() => navigate("/produits/" + row.idArticle)}
                        >
                          <i className="fa fa-location-arrow"></i>
                        </button>
                        <button
                          className="btn btn-primary btn-sm border-0 mx-1"
                          onClick={() =>
                            ProductModal_Ref.current.OpenModal({ ...row })
                          }
                        >
                          <i className="fa fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-warning btn-sm mx-1 "
                          disabled={!pageRoles.del}
                          name="delete"
                          onClick={() => pageRoles.del && delelteProduct(row)}
                        >
                          <i className="fa fa-trash-o"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
          )}
        </TableOne>

        <FooterTable>
          <div className="d-col-12">
            <div className="d-flex justify-content-between ">
              <span className="d-none d-md-block ">
                Total Articles :
                {
                  DataList.filter(
                    (row) =>
                      row.Etat === SwitchEtat &&
                      row.Type === SwitchType &&
                      SerachData(
                        row.Designation +
                          row.DesignationFamille +
                          row.RefArticle,
                        searchText
                      )
                  ).length
                }
              </span>

              <span>
                <b className="text-danger">Achats</b> :
                {FormattNumber(
                  DataList.reduce(
                    (total, row) =>
                      total +
                      (row.Etat === SwitchEtat &&
                      SerachData(
                        row.Designation +
                          row.DesignationFamille +
                          row.RefArticle,
                        searchText
                      )
                        ? row.Stock * row.PrixATTC
                        : 0),
                    0
                  ),
                  2
                )}
              </span>
              <span>
                <b>Ventes</b> :
                {FormattNumber(
                  DataList.reduce(
                    (total, row) =>
                      total +
                      (row.Etat === SwitchEtat &&
                      SerachData(
                        row.Designation +
                          row.DesignationFamille +
                          row.RefArticle,
                        searchText
                      )
                        ? row.Stock * row.PrixVTTC
                        : 0),
                    0
                  ),
                  2
                )}
              </span>
            </div>
          </div>
        </FooterTable>

        <EditProductModal
          ref={ProductModal_Ref}
          getData={getData}
          TypeProduit={SwitchType}
          upte={pageRoles.upte}
        />

        <Spinner isLoading={isLoading} />

        <table
          ref={ListePrintRef}
          className="table w-100 table-bordered small  print"
          id="printTable"
        >
          {DataList.filter(
                    (row) =>
                      row.Etat === SwitchEtat &&
                      row.Type === SwitchType &&
                      SerachData(
                        row.Designation +
                          row.DesignationFamille +
                          row.RefArticle,
                        searchText
                      )
                  ).map(
            (row, i) =>
              SwitchEtat === row.Etat &&
              SwitchType === row.Type && (
                <tbody key={i}>
                  {(i % NumPage === 0 || i === 0) && (
                    <>
                      <tr className="text-center border-0 ">
                        <th colSpan={6} className="border-0">
                          <div className="d-flex justify-content-between">
                            <span>
                              {SwitchType === "A"
                                ? "Liste des Articles"
                                : "Liste des Services"}
                            </span>
                            <span>
                              Page N° : {i / NumPage + 1} /
                              {Math.round(
                                (DataList.filter(
                                  (row) =>
                                    row.Etat === SwitchEtat &&
                                    row.Type === SwitchType &&
                                    SerachData(
                                      row.Designation +
                                        row.DesignationFamille +
                                        row.RefArticle,
                                      searchText
                                    )
                                ).length -
                                  1) /
                                  NumPage
                              ) + 1}
                            </span>
                            <span>le {reformatDate(formatDate(Date()))}</span>
                          </div>
                        </th>
                      </tr>
                      <tr className="text-center ">
                        <th className=" bg-secondary text-white" >Réference</th>
                        <th>Famille</th>
                        <th>Désignation</th>
                        <th>PA</th>
                        <th>PV</th>
                        {SwitchType === "A" ? <th>Stock</th> : ""}
                      </tr>
                    </>
                  )}
                  <tr
                    style={{
                      pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                    }}
                  >
                    <td style={{ maxWidth: "150px", width:'150px' }}>
                      <b className={row.Etat === "S" ? "text-danger" : ""}>
                        {row.RefArticle}
                      </b>
                    </td>
                    <td style={{ maxWidth: "120px", width:'120px' }}>{row.DesignationFamille}</td>
                    <td className="" style={{ maxWidth: "260px", width:'260px' }}>
                      {row.Designation}
                    </td>
                    <td className="text-end" style={{ maxWidth: "80px", width:'80px' }}>{row.PrixATTC}</td>
                    <td className="text-end" style={{ maxWidth: "80px", width:'80px' }}>{row.PrixVTTC}</td>
                    {SwitchType === "A" ? (
                      <td className="text-center" style={{ maxWidth: "80px", width:'80px' }}>{row.Stock}</td>
                    ) : (
                      ""
                    )}
                  </tr>
                </tbody>
              )
          )}

          <tbody>
            <tr>
              <th colSpan={2}>
                Total Articles :{" "}
                {
                 DataList.filter(
                  (row) =>
                    row.Etat === SwitchEtat &&
                    row.Type === SwitchType &&
                    SerachData(
                      row.Designation +
                        row.DesignationFamille +
                        row.RefArticle,
                      searchText
                    )
                ).length
                }
              </th>
              <th className="text-end" colSpan={4}>
                <span className="mx-2">
                  <b className="text-danger">Achats </b> :{" "}
                  {FormattNumber(
                    DataList.reduce(
                      (total, row) =>
                        total +
                        ((row.Etat === SwitchEtat &&
                          row.Type === SwitchType &&
                          SerachData(
                            row.Designation +
                              row.DesignationFamille +
                              row.RefArticle,
                            searchText
                          ))
                          ? row.Stock * row.PrixATTC
                          : 0),
                      0
                    ),
                    2
                  )}
                </span>
              </th>
            </tr>
          </tbody>
        </table>
      </>
    )
  );
};

export default Liste;
