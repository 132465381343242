import {
    useState,
    useEffect,
    forwardRef,
    useRef,
    useImperativeHandle,
  } from "react";
  import {
    getCaisseduJour    
  } from "../../../services/tresorie";
  import Spinner from "../../../components/widgets/spinner/spinner";

  import EditOperation from './EditOperation';
  
  // import {
  //     isLogin,
  //     GetDate,
  //     FormattNumber,
  //     reformatDate,
  //   } from "../../../services/global";
  
  const EditCaisseModal = forwardRef((props, ref) => {
    const OpenCaisseModalBtn = useRef();
    const DateOpInput = useRef();
    const EditModal = useRef();
    const EditOperationRef = useRef();
    
  
    const [DateOp, setDateOp] = useState(new Date().toJSON().slice(0, 10));
    const [DataList, setDataList] = useState([]);    
    const [isLoading, setLoading] = useState(false);
  //   const [SwitchSens, SetSwitchSens] = useState(1);
  
   
  
  
  
    const getData = async (Jour) => {
      setLoading(true);
      const data = await getCaisseduJour(Jour);
      setDataList(data);
      setLoading(false);
    };
  
     
    useEffect(() => {
      getData(DateOp);
    }, [DateOp]);
  
    useImperativeHandle(ref, () => ({
      async OpenModal(CaisseDay) {
        setDateOp(CaisseDay.DateOp);
        DateOpInput.current.value = CaisseDay.DateOp;
        getData(CaisseDay.DateOp);       
        OpenCaisseModalBtn.current.click();
      },
    }));


    
  
    const TriggerModal = () => {      
      const Modal = EditModal.current;
  
      if (Modal.classList.contains("d-none")) { 
        Modal.classList.remove("d-none");
      } else {
        Modal.classList.add("d-none");       
      }
    };
  
    return (
      <>
        <button
          type="button"
          ref={OpenCaisseModalBtn}
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
        >
          OpenCaisseModalBtn
        </button>
  
        <div
          className="modal fade"
          id="myModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex">
                  <h5 className="modal-title text-nowrap px-1" id="myModalLabel">
                    Date Caisse
                  </h5>
                  <input
                    className="form-control py-0"
                    type="date"
                    ref={DateOpInput}
                    onChange={(e) => setDateOp(e.target.value)}
                  />
                  <button
                    onClick={() => {EditOperationRef.current.OpenModal()
                    TriggerModal();}}
                    className="btn btn-success btn-sm mx-2 "
                    type="button"
                  >
                    +
                  </button>
                </div>
  
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="border vh-scroled-detail">
                  <table className="table table-hover w-100 border">
                    <thead>
                      <tr className="text-center ">
                        <td
                          className="border-end text-light bg-primary"
                          style={{ width: "10%" }}
                        >
                          Hr
                        </td>
                        <td
                          className="border-end text-light bg-primary "
                          style={{ width: "20%" }}
                        >
                          Compte
                        </td>
                        <td
                          className="border-end text-light bg-primary"
                          style={{ width: "40%" }}
                        >
                          Commentaire
                        </td>
                        <td
                          className="border-end text-light bg-primary"
                          style={{ width: "15%" }}
                        >
                          Ecaissement
                        </td>
                        <td
                          className="border-end text-light bg-primary"
                          style={{ width: "15%" }}
                        >
                          Décaissements
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {DataList.map((row, i) => (
                        <tr
                          key={i}
                          onDoubleClick={() => {
                            if (row.Type ==='A') {
                              EditOperationRef.current.OpenModal({ ...row })
                              TriggerModal();
                            }
                          }}
                        >
                          <td
                            className="text-center border-end"
                            style={{ width: "10%" }}
                          >
                            {i + 1}
                          </td>
                          <td className="border-end" style={{ width: "20%" }}>
                            {row.Compte}
                          </td>
                          <td className="border-end" style={{ width: "40%" }}>
                            {row.Commentaire}
                          </td>
                          <td
                            className="text-end border-end"
                            style={{ width: "15%" }}
                          >
                            {row.Montant}
                          </td>
                          <td
                            className="text-end border-end"
                            style={{ width: "15%" }}
                          >
                            {row.Montant}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Spinner
                    isLoading={isLoading}
                    StyleClass="spinner-positionDetail"
                  />
                </div>
              </div>
              <div className="modal-footer">footer</div>
            </div>
          </div>
  
          {/* <!-- Modal --> */}
          <div
            ref={EditModal}
            className=" bg-dark bg-opacity-25 modal-fullscreen w-100 d-none fixed-top d-flex align-items-center justify-content-center"
            style={{ zIndex: "3000" }}
          >
            <EditOperation ref={EditOperationRef} TriggerModal={TriggerModal} getData ={getData}/>
          </div>
        </div>
      </>
    );
  });
  
  export default EditCaisseModal;
  