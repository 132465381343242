import "./LSide.css";
import Menu from "../layouts/menu";
import { useSelector } from "react-redux";
// import logo from "../../assets/logo-4.png";

const LSides = () => {
  let showLeftSide = useSelector((state) => state.LayoutReducer.showLeftSide);
  let pageTitle = useSelector((state) => state.LayoutReducer.pageTitle);
  let winSize = useSelector((state) => state.LayoutReducer.winSize);

  return (
    showLeftSide &&
    pageTitle !== "Login" &&
    winSize >= 992 && (
      <div id="accordion-LSide" className="border-end not-print" style={{ background: "var(--dark-1)" }}>
        

        <Menu isMobile={false} />
      </div>
    )
  );
};

export default LSides;
