import {useState,useRef,useEffect,forwardRef} from 'react';

import { searchClients } from '../../../services/ventes';

const Combo_Client = forwardRef((props, ref) => {

    const ClientText = useRef();

    const [ShowClients, setShowClients] = useState(false);
    const [DataClient, setDataClient] = useState([]);
    const [Client,setClient] = useState({CodeClt: 1,Client: 'Client Comptant'});


   
    useEffect(() => {
                
        setClient(props.Client);
        ClientText.current.value = props.Client.Client; 
              
      },[props.Client]);

    
    const SelectClient = (idClient, DesignationClient) => {        
        setShowClients(false);
        setClient({CodeClt: idClient,Client: DesignationClient})
        props.setcurrentClient(idClient, DesignationClient);
        ClientText.current.value = DesignationClient;          
    }

    return (
        <>
           <div className='d-flex gap-1'>
           <input className="form-control m-auto" ref={ClientText}  
            onFocus={() => setShowClients(true)} placeholder="Selectionner Client" 
            onChange={async (e) =>   setDataClient(await searchClients(e.target.value))}                                    
            />
            { 
                ShowClients &&            
                    <button className="btn btn-light btn-xs" 
                    type="button" 
                    onClick={  () => {
                        setShowClients(false);
                        SelectClient(Client.CodeClt,Client.Client)
                    }} >
                        <i className="fa fa-arrow-left"></i>
                    </button>
            }
             </div>   
            { 
                ShowClients && 
                <div className="vh-scroled-modal bg-white border" style={{position:'absolute',zIndex:'99',top:'60px', width:'90%'}}> 
                    <table className="table"  >
                        <tbody className=" "  >
                            {
                                DataClient.map((row,i) => 
                                    <tr key={i}>
                                    <td className="text-center" data-style="width:50px;"> {i+1} </td>
                                    <td data-style="width:100%;"> { row.Client } </td>
                                    <td className="text-end" data-style="width:50px;">
                                    <button className="btn btn-secondary btn-sm" type="button"
                                        onClick={() => SelectClient(row.IdClient ,row.Client)}>
                                            <i className="fa fa-check"></i>
                                    </button>
                                </td>
                            </tr>

                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
              
            }
        </>
    );
})

export default Combo_Client;