import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getReglementList,  
  deleteReglement,
} from "../../../services/achats";

import {
  isLogin,
  GetDate,
  FormattNumber,
  formatDate,
  reformatDate,
  SerachData,
} from "../../../services/global";

import PeriodeDateur from "../../../components/widgets/periode/periode";
import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import EditReglementModal from "./EditReglement";

const ReglementList = ({ pageRoles }) => {
  const NumPage = 28;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const seach = useRef();
  const PeriodeDateurRef = useRef();
  const ReglementModal_Ref = useRef();

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      setLoading(true);

      sessionStorage.setItem("Debut", Periode.debut);
      sessionStorage.setItem("Fin", Periode.fin);

      const data = await getReglementList(Periode);
      setDataList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/achats/reglements");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getReglementList(periode);
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Reglements Fournisseurs"));
  }, [dispatch, navigate]);

  
  const delelteReg = (Reg) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Êtes vous sûr de vouloir supprimer ce Reglement de : \n ${Reg.Montant} ?`
      )
    ) {
      setLoading(true);
      deleteReglement(Reg.IdRegBL)
        .then((message) => {
          setLoading(false);
          getData();
          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  return (
    isLogin() &&
    DataList && (
      <>
        {/* entete */}
        <div className="row mb-2 not-print">
          <div className="col-12 col-md-6 text-end d-flex gap-2 mb-1">
            <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />
          </div>
          <div className="col-12 col-md-6 text-end d-flex gap-2 ">
            <div className="input-group">
              <input
                ref={seach}
                className="form-control"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Fournisseurs"
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() =>
                pageRoles.upte && ReglementModal_Ref.current.OpenModal()
              }
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>

        {/* liste des reglement */}
        <TableOne vh="vh-scroled-2">
          {DataList.map((row, i) => (
            SerachData(row.Fournisseur, searchText) &&
            <tr key={i}>
              <td
                onDoubleClick={() =>
                  ReglementModal_Ref.current.OpenModal({ ...row })
                }
              >
                <div
                  className={row.idBon === "00" ? "row text-primary" : "row align-items-end align-items-lg-center"}
                >
                  <div className="col-6 col-md-2">Mode : {row.ModeReg}</div>
                  <div
                    className="col-6 col-md-2 text-end text-lg-center"                    
                  >
                    Date : {reformatDate(row.DateReg)}
                  </div>
                  <div className="col-12 col-md-4 mt-1 mt-lg-0">
                    Fr : <b>{row.Fournisseur} </b>
                  </div>
                  <div className="col-4 col-md-1">Total :</div>
                  <div className="col-4 col-md-1 text-end">
                    <b>{FormattNumber(row.Montant - 0, 2)} </b>
                  </div>
                  <div
                    className="col-4 col-md-2 text-end"
                    styles="border:0px red solid;text-align:right;"
                  >
                    <button
                      className="btn bg-primary-1 btn-sm "
                      onClick={() =>
                        ReglementModal_Ref.current.OpenModal({ ...row })
                      }
                      type="button"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-warning btn-sm mx-1"
                      name="delete"
                      type="button"
                      onClick={() => pageRoles.del && delelteReg(row)}
                      disabled={!pageRoles.del}
                    >
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </TableOne>

        {/* footer */}
        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-50">
                    Doc :{" "}
                    <span className="badge bg-white w-50 text-end text-dark">
                      {DataList.filter(row => SerachData(row.Fournisseur, searchText)).length}
                    </span>
                  </button>

                  <button className="btn btn-outline-success btn-sm d-flex justify-content-between align-items-center w-50">
                    Réglé :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {FormattNumber(
                        DataList.filter(row => SerachData(row.Fournisseur, searchText)).reduce((q, row) => q + row.Montant * 1, 0),
                        2
                      )}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* modal edition */}
        <EditReglementModal
          ref={ReglementModal_Ref}
          getData={getData}
          upte={pageRoles.upte}
        />
        <Spinner isLoading={isLoading} />

        {/* impression */}

        <table className="table w-100 table-bordered small d-none print border">

          {DataList.filter(row => SerachData(row.Fournisseur, searchText)).map((row, i) => (
            <tbody key={i}>
              {(i % NumPage === 0 || i === 0) && (
                <>
                  <tr className="text-center border-0 ">
                    <th colSpan={4} className="border-0">
                      <div className="d-flex justify-content-between">
                        <span>Liste Réglements Clients</span>
                        <span>
                          Page N° : {i / NumPage + 1} /
                          {Math.round((DataList.filter(row => SerachData(row.Fournisseur, searchText)).length - 1) / NumPage) + 1}
                        </span>
                        <span>le {reformatDate(formatDate(Date()))}</span>
                      </div>
                    </th>
                  </tr>
                  <tr className="text-center">
                    <th className=" bg-secondary text-white">Date</th>
                    <th className=" bg-secondary text-white">Mode</th>
                    <th className=" bg-secondary text-white">Fournisseur</th>
                    <th className=" bg-secondary text-white">Montant</th>
                  </tr>
                </>
              )}

              <tr
                key={i}
                style={{
                  pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                }}
              >
                <td>
                  <b>{reformatDate(row.DateReg)}</b>
                </td>
                <td className="text-center">{row.ModeReg}</td>
                <td className="w-75" >
                  {row.Fournisseur}
                </td>
                <td className="text-end">
                  {FormattNumber(row.Montant - 0, 2)}
                </td>
              </tr>
            </tbody>
          ))}
          <tbody>
            <tr>
              <th colSpan={2}>Total Réglements : {DataList.filter(row => SerachData(row.Fournisseur, searchText)).length}</th>
              <th className="text-end" colSpan={2}>
                <b>Total </b> : 
                {FormattNumber(
                  DataList.filter(row => SerachData(row.Fournisseur, searchText)).reduce((total, row) => total + row.Montant * 1, 0),
                  2
                )}
              </th>
            </tr>
          </tbody>
        </table>
      </>
    )
  );
};

export default ReglementList;
