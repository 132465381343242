import { useState, forwardRef, useRef, useEffect } from "react";

import Spinner from "../../../../components/widgets/spinner/spinner";
import { getFamillesInventaire,SetZeroInvFamilles } from "../../../../services/inventaires";

const FamillesInevnatire = forwardRef((props, ref) => {
  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [Commentaire, setCommentaire] = useState("");
  const [FamillesRef, setFamillesRef] = useState([]);
  const [TotalArticles, setTotalArticles] = useState(0); 

  const getData = () => {
    
    setLoading(true);
    getFamillesInventaire()
      .then((data) => {
        setLoading(false);
        setDataList(data);
      })
      .catch(() => alert("erreur"));
  };

  useEffect(() => {
    const getFistData = () => {
      try{        
        setLoading(true);
        getFamillesInventaire()
        .then((data) => {
          setLoading(false);
          data.map((item) => item.checked = props.OldFamilles.split(",").includes(item.Ref)? '1':'0');
          
          setFamillesRef(props.OldFamilles.split(","));
          setTotalArticles(data.reduce((total, item) => total + (item.checked === '1' ? (item.TotalArticle*1):0), 0)); 

          setDataList(data);
        })
        .catch((e) => console.log('split Erreur'));
      }
      catch{}

      
      
    };

    getFistData();    
   
  }, [props.OldFamilles]);

  const selectFamille = (row) => {
    // console.log(OldFamilles)  
    let NewCommentaire = "";
    let newTotalArticle = 0;
    const ListFamilles = [];

    if (row.INV !== "1") {
      if (row.checked !== "0") {
        row.checked = "0";
      } else {
        row.checked = "1";
      }
    } else {
      
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm( "Attention cette Familles est déja Compté, voullez vous comme même l'Ajouté !") 
      ) {
        row.INV = "0";
        if (row.checked !== "0") {
          row.checked = "0";
          SetZeroInvFamilles(row.Ref)
        } else {
          row.checked = "1";
        }
      } else {
        return;
      }
    }

    for (let item of DataList) {
      if (item.checked === "1") {
        NewCommentaire = NewCommentaire + "-" + item.Designation;
        ListFamilles.push(item.Ref);
        newTotalArticle = newTotalArticle * 1 + item.TotalArticle * 1;
      }
    }

    setCommentaire(NewCommentaire);
    setFamillesRef(ListFamilles);
    setTotalArticles(newTotalArticle);
  };

  const LoadInventaire = () => {
    props.LoadInventaire(Commentaire, FamillesRef, TotalArticles);
    setCommentaire("");
    setFamillesRef([]);
    setTotalArticles(0);
    props.TriggerModal();
    getData();
  };

  return (
    <div
      className="bg-white border rounded-3 position-relative shadow-lg"
      style={{ width: "500px", minHeight: "230px" }}
    >
      <div className="row border-bottom m-auto w-100 py-3">
        <div className="col-4">Familles Inventaire</div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-success position-relative btn-sm"
          >
            Total Familles
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning text-dark">
              {FamillesRef.length}
            </span>
          </button>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary position-relative btn-sm"
          >
            Total Articles
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {TotalArticles}
            </span>
          </button>
        </div>
      </div>

      {DataList && (
        <div className={"border  not-print vh-scroled-detail border"}>
          <table className="table small ">
            <tbody>
              {DataList.map((row, i) => (
                <tr
                  key={i}
                  className="border"
                  onClick={() => selectFamille(row)}
                >
                  {row.INV === "0" ? (
                    <>
                      <td className="px-2">{row.Designation}</td>
                      <td className="text-center">{row.TotalArticle}</td>
                    </>
                  ) : (
                    <>
                      <td className="px-2">
                        <s>{row.Designation}</s>
                      </td>
                      <td className="text-center">
                        <s>{row.TotalArticle}</s>
                      </td>
                    </>
                  )}
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={row.checked !== "0"}
                      // onChange={(e) => (row.checked = e.target.checked)}
                      readOnly
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="row m-auto border-top w-100 py-2">
        <div className="col-12 overflow-auto" style={{ height: "70px" }}>
          <p className="d-none">{FamillesRef.toString()}</p>
          {Commentaire}
        </div>
        <div className="col-12">
          <div className="form-group text-end">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => props.TriggerModal()}
            >
              Fermer
            </button>
            <input
              className="btn btn-success btn-sm mx-2"
              type="button"
              value="Charger Inventaire"
              onClick={() => LoadInventaire()}
            />
          </div>
        </div>
      </div>
      <Spinner isLoading={isLoading} />
    </div>
  );
});

export default FamillesInevnatire;
