import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  getSingleArticle,
  getDataMouvement,
  getStockIni,
} from "../../../services/produits";
import {
  GetDate,
  FormattNumber,
  reformatDate,
  formatDate,
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";

const ProdMouvement = () => {
  const dispatch = useDispatch();

  const [CurrentArticle, setCurrentArticle] = useState({});
  const [DataList, setDataList] = useState([]);
  const [stockIni, setStockIni] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const [Periode, setPeriode] = useState({
    debut: formatDate(new Date()),
    fin: formatDate(new Date()),
  });
  const params = useParams();

  const getData = async () => {
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      setLoading(true);
      const data = await getDataMouvement(Periode, CurrentArticle.idArticle);
      const SIni = await getStockIni(Periode, CurrentArticle.idArticle);
      setStockIni(SIni);
      setDataList(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(SetPageTitle("Mouvement Produit"));

    const getFistData = async () => {
      const periode = await GetDate();
      setPeriode(periode);

      const response = await getSingleArticle(params.id);

      if (response.RefArticle) {
        setCurrentArticle(response);

        const data = await getDataMouvement(periode, response.idArticle);
        const SIni = await getStockIni(periode, response.idArticle);
        setStockIni(SIni);
        setDataList(data);
        setLoading(false);
      } else {
        alert("?");
      }
    };

    getFistData();
  }, [dispatch, params.id]);

  return (
    <>
      <div className="row mb-3 small align-items-center not-print">
        <div className="col-12 col-md-7 justify-content-between d-flex gap-2  mb-1">
          <input
            className="form-control form-control-sm"
            type="date"
            value={Periode.debut}
            onChange={(e) =>
              setPeriode({ debut: e.target.value, fin: Periode.fin })
            }
          />

          <input
            className="form-control form-control-sm"
            type="date"
            value={Periode.fin}
            onChange={(e) =>
              setPeriode({ debut: Periode.debut, fin: e.target.value })
            }
          />

          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => getData()}
            type="button"
          >
            <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
          </button>
        </div>
        <div className="col-12 col-md-5 text-end">
          <b className="text-capitalize">
            {CurrentArticle.Designation} - [ {CurrentArticle.Stock} ]
          </b>
        </div>
      </div>
      <Spinner isLoading={isLoading} />
      <TableOne vh="vh-scroled">
        {DataList.map((row, i) => (
          <tr key={i} className="small">
            <td className={row.sens === "-1" ? "bg-danger text-white" : ""}>
              <div className="row">
                <div className="col-8 col-md-2">
                  <b>{reformatDate(row.DateBL)}</b>
                </div>
                <div className="col-4  col-md-2 ">
                  {row.Quantite < 0 ? "R" : "B"} : <b>{row.NumBL}</b>
                </div>
                <div
                  className={
                    row.Quantite < 0
                      ? "col-8 col-md-2 btn btn-danger btn-sm"
                      : "col-8 col-md-2"
                  }
                >
                  Qté : <b>{row.Quantite}</b>
                </div>
                <div className="col-4 col-md-2">
                  Prix : <b>{FormattNumber(row.Prix - 0, 2)} </b>
                </div>
                <div className="col-12 col-md-4">{row.Client}</div>
              </div>
            </td>
          </tr>
        ))}
      </TableOne>
      <table className="w-100 mt-1 not-print">
        <tbody>
          <tr>
            <td>
              <div className="d-flex justify-content-between align-items-center gap-2">
                <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-25">
                  <span className="small">INI:</span>
                  <span className="badge bg-white w-50 text-end text-dark ">
                    {stockIni}
                  </span>
                </button>
                <button className="btn btn-outline-danger btn-sm d-flex justify-content-between align-items-center w-25">
                  <span className="small">Achat:</span>
                  <span className="badge bg-white w-50 text-end text-dark ">
                    {DataList.reduce(
                      (QA, row) =>
                        QA + (row.sens === "-1" ? row.Quantite * 1 : 0),
                      0
                    )}
                  </span>
                </button>
                <button className="btn btn-outline-success btn-sm d-flex justify-content-between align-items-center w-25">
                  <span className="small">Vente:</span>
                  <span className="badge bg-white w-50 text-end text-dark">
                    {DataList.reduce(
                      (QA, row) =>
                        QA + (row.sens === "1" ? row.Quantite * 1 : 0),
                      0
                    )}
                  </span>
                </button>
                <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-25">
                  <span className="small">Fin:</span>
                  <span className="badge bg-white w-50 text-end text-dark">
                    {stockIni * 1 +
                      DataList.reduce(
                        (QF, row) => QF + row.Quantite * -1 * row.sens,
                        0
                      )}
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table w-100 border table-bordered small print">
        <thead>
          <tr className="w-100 text-center">
            <th colSpan={6}>
              <div className="d-flex justify-content-between">
                <span>
                  Mouvement Article :{" "}
                  <b className="text-capitalize">
                    {CurrentArticle.Designation} - [ {CurrentArticle.Stock} ]
                  </b>{" "}
                </span>

                <span>
                  {reformatDate(formatDate(Periode.debut))}/
                  {reformatDate(formatDate(Periode.fin))}
                </span>
              </div>
            </th>
          </tr>
          <tr className="w-100 text-center ">
            <th>Date</th>
            <th>Num</th>
            <th className="text-nowrap">Qté + </th>
            <th className="text-nowrap">Qté - </th>
            <th>Prix</th>
            <th>Clients / Fournisseurs</th>
          </tr>
        </thead>
        <tbody>
          {DataList.map((row, i) => (
            <tr
              key={i}
              className={row.sens === "-1" ? "bg-danger text-white" : ""}
            >
              <td className="text-center" style={{ minWidth: "100px" }}>
                {reformatDate(row.DateBL)}
              </td>
              <td className="text-center">
                <b>{row.NumBL}</b>
              </td>
              <td className="text-center">
                <b>{row.sens === "-1" ? row.Quantite : ""}</b>
              </td>
              <td className="text-center">
                <b>{row.sens === "1" ? row.Quantite : ""}</b>
              </td>
              <td className="text-end">{FormattNumber(row.Prix - 0, 2)}</td>
              <td className="w-100">{row.Client}</td>
            </tr>
          ))}
        </tbody>
        <tfoot className="page-break">
          <tr>
            <th colSpan={6}>
              <div className="d-flex justify-content-between align-items-center gap-2">
                <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-25">
                  Initiale:
                  <span className="badge bg-white w-50 text-end text-dark">
                    {stockIni}
                  </span>
                </button>
                <button className="btn btn-outline-danger btn-sm d-flex justify-content-between align-items-center w-25">
                  Achat:
                  <span className="badge bg-white w-50 text-end text-dark">
                    {DataList.reduce(
                      (QA, row) =>
                        QA + (row.sens === "-1" ? row.Quantite * 1 : 0),
                      0
                    )}
                  </span>
                </button>
                <button className="btn btn-outline-success btn-sm d-flex justify-content-between align-items-center w-25">
                  Vente:
                  <span className="badge bg-white w-50 text-end text-dark">
                    {DataList.reduce(
                      (QA, row) =>
                        QA + (row.sens === "1" ? row.Quantite * 1 : 0),
                      0
                    )}
                  </span>
                </button>
                <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-25">
                  Finale:
                  <span className="badge bg-white w-50 text-end text-dark">
                    {stockIni * 1 +
                      DataList.reduce(
                        (QF, row) => QF + row.Quantite * -1 * row.sens,
                        0
                      )}
                  </span>
                </button>
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default ProdMouvement;
