import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { updateCommande, commandeToBon,getNumBon } from "../../../../services/commandes";
import {
  padNumber,
  FormattNumber,
  formatDate,
  isAuth
} from "../../../../services/global";
import DetailCommande from "./DetailCommande";
import ComboFournisseur from "../../Fournisseurs/ComboFournisseur";

const EditModal = forwardRef((props, ref) => {
  const OpenModalBtn = useRef();
  const FormCommandeRef = useRef();
  const FormCommandeRef2 = useRef();
  const FournisseurText = useRef();


  const ComboFournisseur_Ref = useRef(null);
  const ModalToBon = useRef(null);
  const numBonRef = useRef(null);
  const dateBonRef = useRef(null);
  const closeModalRef = useRef(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const [FromErrors, setFromErrors] = useState({});
  const [currentCommande, setcurrentCommande] = useState({});
  const [ShowFournisseurs, setShowFournisseurs] = useState(false);
  const [NumBA, setNumBA] = useState("");

  const setDetailPrint = (data) => {
    props.setDetailCurrentPrint(data);
  };

  const initialCommande = {
    IdCommande: 0,
    NumCommande: 0,
    CodeFour: "",
    DateCommande: formatDate(new Date()),
    Mod_HT: "0",
    MontantTTC: 0,
    Tva: 0,
    BA: 0,
    Type: "A",
    Etat: "C",
    Description: "",
    UserID: 0,
    Fournisseur: "Comptant",
  };

  useImperativeHandle(ref, () => ({
    async OpenModal(Commande = initialCommande) {
      OpenModalBtn.current.click();

      setShowFournisseurs(false);

      setcurrentCommande(Commande);
      if(Commande.BA*1!==0){
        getNumBonFct(Commande.BA)
       
      }
      

      const FormCommande = FormCommandeRef.current;
      const FormCommande2 = FormCommandeRef2.current;

      FormCommande.NumCommande.value = padNumber(Commande.NumCommande, 4);
      FormCommande.DateCommande.value = Commande.DateCommande;
      FournisseurText.current.value = Commande.Fournisseur;

      FormCommande2.Description.value = Commande.Description;

      setFromErrors({});

      window.onafterprint = function () {
        try {
          const div = document.querySelectorAll(".bg-transparent");
          div[0].classList.remove("bg-transparent");
          div[0].classList.add("modal-backdrop");
        } catch { }
      };
    },
  }));

  const setTotalCommande = (V) => {
    setcurrentCommande({ ...currentCommande, MontantTTC: V }); // selement pour raffrichir le Montant TTC
    props.setCurrentPrint({ ...currentCommande, MontantTTC: V });
  };

  const imprimer = () => {
    const div = document.querySelectorAll(".modal-backdrop");
    div[0].classList.add("bg-transparent");
    div[0].classList.remove("modal-backdrop");
    window.print();
  };

  const VerifData = () => {
    if (isAuth(props.upte)) {
      let verif = {};
      // if(currentCommande.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}

      // setFromErrors(verif);

      if (Object.keys(verif).length === 0) {
        SaveData();
      }
    }
  };
  const SaveData = () => {
    if (currentCommande.IdCommande === "") {
      //     AddCommande(currentCommande).then((data) => {
      //         props.getData();
      //         currentCommande.IdCommande=data.lastID;
      //         console.log(data.message);
      //    })
    } else {
      updateCommande(currentCommande)
        .then((message) => {
          //  props.getData();
          props.setCurrentPrint({ ...currentCommande });
          console.log(message);
          setIsUpdate(false)
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  
  const doYouSaveBefore = (action) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Voulez Vous enregister les Modifications Avant ") === true) {
      VerifData();
      setTimeout(() => {
        closeModalRef.current.click();
      }, "1000");

    } else {
     setIsUpdate(false);
     setTimeout(() => {
      closeModalRef.current.click();
    }, "1000");
     
    }
  }

  const SelectFournisseur = (idFournisseur, DesignationFournisseur) => {
    setShowFournisseurs(false);
    if(idFournisseur !== currentCommande.CodeFour){setIsUpdate(true)}
    currentCommande.CodeFour = idFournisseur;
    currentCommande.Fournisseur = DesignationFournisseur;
    FournisseurText.current.value = DesignationFournisseur;
    
  };

  const TriggertoBon = () => {
    if (isAuth(props.upte)) {
      if (ModalToBon.current.classList.contains("d-none")) {
        ModalToBon.current.classList.remove("d-none");
      } else {
        ModalToBon.current.classList.add("d-none");
      }
    }
  };

  const getNumBonFct = async (IdBA) =>{
    const data = await getNumBon(IdBA);        
            setNumBA(data.NumBA)
  } 

  const createBon =  () => {
    if (isAuth(props.upte)) {
      if(numBonRef.current.value !=='' && dateBonRef.current.value !==''){
        
        commandeToBon(currentCommande.NumCommande,numBonRef.current.value,dateBonRef.current.value,currentCommande.IdCommande,currentCommande.CodeFour)
        .then((rep) => {
          setcurrentCommande({ ...currentCommande, BA:rep.LastID })    
          getNumBonFct(rep.LastID)           
              
        })
        .catch((e) => alert("Erreur Modification"));

        numBonRef.current.value = ''
        dateBonRef.current.value = ''
        
        TriggertoBon();
      }
      else
      {
        alert('Champs Obligatoire !')
      }

    }
  };

  

  return (
    <div style={{ position: "relative" }}>
      <button
        type="button"
        ref={OpenModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModalList"
      >
        OpenModalBtn
      </button>

      <div
        className="modal fade not-print"
        id="myModalList"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-"
        aria-labelledby="myModalListLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          {/* Commandeoc Commande Détail */}

          <div className="modal-content py-2 rounded-0 rounded-bottom" style={{borderTop:'3px #dc3545 solid', boxSizing:'border-box'}}>
          <span className="btn btn-danger rounded-0 rounded-top modal-edition-title d-none d-lg-block">Edition Commande Fournisseur</span>
            <button
              type="button"
              onClick={() => {
                isUpdate && doYouSaveBefore('close');
                !isUpdate && props.switchPrint("List");
                !isUpdate && props.getData();
                !isUpdate && setShowFournisseurs(false);
              }}
              className="btn btn-light position-absolute top-0 start-100 translate-middle rounded-circle"
              data-bs-dismiss={isUpdate ? '' : 'modal'}
              aria-label="Close"
              ref={closeModalRef}
            >
              X
            </button>
            {/* modal créee Bon */}
            <div
              ref={ModalToBon}
              className="position-fixed w-100 d-flex justify-content-center bg-secondary bg-opacity-25 d-none"
              style={{
                height: "100vh",
                zIndex: "10",
                top: "0px",
                left: "0px",
                paddingTop: "20%",
              }}
            >
              <div
                className="border bg-white w-50 rounded-3 p-3 pt-4 row "
                style={{ height: "120px" }}
              >
                <div className="col-4">
                  <label forhtml="numBON" className="form-label">
                    N° Bon Achat :
                  </label>
                  <input
                    type="text"
                    ref={numBonRef}
                    className="form-control"
                    id="numBON"                    
                  ></input>
                </div>
                <div className="col-4">
                  <label forhtml="dateBON" className="form-label">
                    N° Bon Achat :
                  </label>
                  <input
                    type="date"
                    ref={dateBonRef}
                    className="form-control"
                    id="dateBON"
                  ></input>
                </div>
                <div className="col-4 text-end mt-5">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm me-1"
                    id="numBON"
                    onClick={() => createBon()}

                    //  disabled={numBonRef.current.value ==='' }
                  >
                    Crée Bon
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    id="numBON"
                    onClick={() => TriggertoBon()}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
            <form ref={FormCommandeRef} className="modal-header p-0  ">
              <div className="row w-100 m-auto">
                <div className="col-12 col-md-6 d-flex gap-2 mb-1 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control text-center"
                    disabled={true}
                    type="text"
                    id="NumCommande"
                    readOnly
                  /> 
                  <input
                    className="form-control py-0"
                    ref={FournisseurText}
                    onFocus={() => props.upte && setShowFournisseurs(true)}
                    placeholder="Selectionner Fournisseur"
                    onChange={async (e) => {
                      props.upte &&
                        ComboFournisseur_Ref.current.SerachDatafr(
                          e.target.value
                        );
                    }}
                  />
                  {ShowFournisseurs && (
                    <>
                      <button
                        className="btn btn-success btn-sm edit mx-1"
                        onClick={() => ComboFournisseur_Ref.current.NewFour()}
                        name="edit"
                        type="button"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        className="btn btn-light btn-xs"
                        type="button"
                        onClick={() => {
                          setShowFournisseurs(false);
                          SelectFournisseur(
                            currentCommande.CodeFour,
                            currentCommande.Fournisseur
                          );
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>
                      </button>
                    </>
                  )}
                </div>

                <div className="col-6 col-md-4 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control"
                    type="date"
                    id="DateCommande"
                    onChange={(e) =>
                      {currentCommande.DateCommande = e.target.value; setIsUpdate(true)}
                    }
                  />
                </div>

                <div className="col-6 col-md-2 justify-content-end d-flex gap-1 py-1 ">
                  <input
                    onClick={() => VerifData()}
                    value="Enregistrer"
                    className="btn btn-primary btn-sm"
                    type="button"
                    disabled={!props.upte}
                  />
                  <input
                    onClick={() => TriggertoBon()}
                    value={ currentCommande.BA*1 === 0 ? "-> Achat"  : "Bon N° " + NumBA}
                    className="btn btn-danger btn-sm "
                    type="button"
                    disabled={!props.upte || isUpdate || currentCommande.BA*1 !== 0 }
                  />
                </div>
              </div>
            </form>
            {ShowFournisseurs && (
              <ComboFournisseur
                ref={ComboFournisseur_Ref}
                SelectFournisseur={SelectFournisseur}
              />
            )}
            <div
              className="modal-body  m-0 py-0"
              onClick={() => {
                setShowFournisseurs(false);
                SelectFournisseur(
                  currentCommande.CodeFour,
                  currentCommande.Fournisseur
                );
              }}
            >
              <DetailCommande
                IdCommande={currentCommande.IdCommande}
                getData={props.getData}
                setTotalCommande={setTotalCommande}
                setDetailPrint={setDetailPrint}
                imprimer={imprimer}
                upte={props.upte}
              />
            </div>
            <form
              ref={FormCommandeRef2}
              className="modal-footer d-flex flex-column py-0 border-0">
              <div className="row w-100">
                <div className="col-12 col-lg-8 input-group-sm d-flex p-0">
                  <input
                    className="form-control"
                    type="text"
                    id="Description"
                    placeholder="Edtion Commentaire"
                    onChange={(e) =>
                      {currentCommande.Description = e.target.value; setIsUpdate(true)}
                    }
                  />
                </div>
                
                <div className="col-12 col-lg-4 d-flex justify-content-end align-items-center small p-0">
                  <b className="mx-2 h4 text-primary">
                    Total : {FormattNumber(currentCommande.MontantTTC * 1, 2)}
                  </b>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditModal;
