import { useState,forwardRef, useRef, useImperativeHandle } from "react";
import { deleteReglement,AddReglementBL,getDataListReg } from '../../../services/ventes';
import { FormattNumber,reformatDate } from '../../../services/global';



const EditReglementModal = forwardRef((props, ref) => {

    const OpenReglementModalBtn = useRef();
    const FormContainer = useRef();       
    
    
    const [FromErrors, setFromErrors] = useState({});   
    const [currentReglement, setCurrentReglement] = useState({});
    const [IdBL, setIdBL] = useState(0);
    const [CodeClt, setCodeClt] = useState(0);
    const [isEspece, setIsEspece] = useState(true);
    const [DataListReg, setDataListReg] = useState([]);
    
    
    

    const initialReglement  = {        
        "Num" : 0,               
        "DateReg" : new Date().toJSON().slice(0, 10),
        "DateEch" : new Date().toJSON().slice(0, 10),
        "Montant" : 0,
        "ModeReg" : 'Espece',
        "Banque" : '',
        "NumDoc" : '',
        "Type" : 'C',
        "Sens" : 1,        
        "Etat" : 'R',
        "TypeDoc" : 'B',      
      }   
      
    useImperativeHandle(ref,  () => ({
        
        async OpenModal (IdBL,CodeClt) {             
            OpenReglementModalBtn.current.click();           

            setCurrentReglement(initialReglement); 
            setIdBL(IdBL); setCodeClt(CodeClt);
            setIsEspece(true);

             const form = FormContainer.current.getElementsByTagName('form')[0]; 

                form.DateReg.value = initialReglement.DateReg;
                form.DateEch.value = initialReglement.DateEch;
                form.Montant.value = initialReglement.Montant;
                form.ModeReg.value = 'Espece';
                form.Banque.value = initialReglement.Banque;
                form.NumDoc.value = initialReglement.NumDoc;
            
                setDataListReg(await getDataListReg(IdBL));
            setFromErrors({})


            
        }

    }))

    const VerifData = () => {
        let verif = {};        
        if(currentReglement.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}                           

        setFromErrors(verif);        
        if(Object.keys(verif).length === 0){ SaveData()}

    }
    const SaveData = () =>  {  
        
        AddReglementBL(currentReglement,IdBL,CodeClt).then( async (data) => {
            props.getData();
            setCurrentReglement(initialReglement);
            setIsEspece(true);

            const data1 = await getDataListReg(IdBL)
            setDataListReg(data1); 
              
            const form = FormContainer.current.getElementsByTagName('form')[0]; 

                form.DateReg.value = initialReglement.DateReg;
                form.DateEch.value = initialReglement.DateEch;
                form.Montant.value = initialReglement.Montant;
                form.ModeReg.value = 'Espece';
                form.Banque.value = initialReglement.Banque;
                form.NumDoc.value = initialReglement.NumDoc;

            console.log(data.message);
       })
    }

  
    
    const delelteReg = (Reg) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Êtes vous sûr de vouloir supprimer le Reglement N°: \n ${Reg.IdRegBL} ?`)) {                   
             deleteReglement(Reg.IdRegBL).then(async (message) => {
                const data = await getDataListReg(IdBL)
                setDataListReg(data);              
                props.getData();
                console.log(message);  
            })
            .catch((e) => {alert('Erreur Suppression'); console.log(e)});
        }
        
    }
      
    return (
        <>
            <button type="button" ref={OpenReglementModalBtn} className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#myModal">
                OpenReglementModalBtn
            </button>
        
            <div ref={FormContainer} className="modal fade" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Edition Reglement</h5>
                            <button  type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form className="modal-body">
                            <div className="row my-2">
                                <div className="col-6">
                                <div className="form-group">
                                    <label>Date Réglement</label>
                                    <input className="form-control" type="date" id="DateReg"
                                    onChange={e => currentReglement.DateReg = e.target.value } />
                                </div>
                                </div>

                                <div className="col-6">
                                <div className="form-group">
                                    <label>Date Echéance</label>
                                    <input className="form-control" type="date" id="DateEch" 
                                    onChange={e => currentReglement.DateEch = e.target.value } />                                
                                </div>
                                </div>

                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Montant</label>
                                        <input className="form-control" type="number" id="Montant" 
                                        onChange={e => currentReglement.Montant = e.target.value }
                                        />
                                    </div>
                                    <span className="text-danger">{FromErrors.Montant || ''}</span>
                                </div>
                                <div className="col-6">
                                <div className="form-group">
                                    <label>Mode Rég</label>
                                    <select className="form-select" id="ModeReg" onChange={e => {currentReglement.ModeReg = e.target.value; setIsEspece(e.target.value==='Espece')} }>
                                        <option value="Espece">Espece</option>
                                        <option value="Cheque">Chéque</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                        
                            <div className={isEspece ? "row d-none" :"row"} >
                                <div className="col-4 ">
                                    <div className="form-group">
                                        <label>Banque</label>
                                        <input className="form-control" type="text" id="Banque" 
                                        onChange={e => currentReglement.Banque = e.target.value } />
                                    </div>
                                </div>
                                <div className="col-8 ">
                                    <div className="form-group">
                                        <label>Num Document</label>
                                        <input className="form-control" type="text" id="NumDoc" 
                                        onChange={e => currentReglement.NumDoc = e.target.value } />
                                    </div>
                                </div>
                            </div>
                            
                            
                        </form>
                        <div className="modal-footer">                           
                            
                                <button type="button" className="btn btn-outline-secondary mx-1" data-bs-dismiss="modal">Fermer</button>
                                <button  onClick={() => VerifData()} type="button" className="btn btn-outline-success" >Enregistrer</button>
    
                            
                        </div>
                        <div className={(DataListReg.length===0)?"d-none":"row my-1 mx-3 mb-3 border"}>
                            <div className="col-12 vh-scroled-modal bg-light"> 
                                <table className="table"  >
                                    <tbody>                                    
                                        {
                                            
                                            DataListReg.map((row,i) => 
                                            <tr key={i}>
                                                <td> { i+1 } </td>
                                                <td> { reformatDate(row.DateReg) } </td>
                                                <td> { FormattNumber(row.Montant-0,2) } </td>
                                                <td> { row.ModeReg } </td>
                                                <td>
                                                <button className="btn btn-light text-danger btn-sm my-2" 
                                                    onClick={() => delelteReg(row)}>
                                                    <i className="fa fa-trash-o"></i></button>
                                        </td>
                                            </tr>

                                            )
                                        }                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default EditReglementModal;

