import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getClientListe,
  delelteClient,
} from "../../../services/clients";
import {
  isLogin,
  padNumber,
  formatDate,
  reformatDate,
  FormattNumber,
  sortBy,
  SerachData
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import FooterTable from "../../../components/widgets/tables/FooterTable";
import EditClientModal from "./EditClientModal";

const Clients = ({ pageRoles }) => {
  const NumPage = 28;

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const ClientModal_Ref = useRef();
  const seach = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortSens, setSortSens] = useState(-1);

  const getSortedData = (data = [...DataList], search = false) => {
    if (!search) {
      setSortSens(sortSens * -1);
    }

    setDataList(sortBy("Client", "S", data, sortSens));
  };

  const getData = () => {

    setLoading(true);
    getClientListe()
      .then((data) => {
        setLoading(false);
        setDataList(data);
        getSortedData(data);
      })
      .catch(() => alert("Erreur System Liste Client Data 1"));

  };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/clients");
    if (!isLogin()) {
      navigate("/login");
    }
    const getData = () => {
      setLoading(true);
      getClientListe()
        .then((data) => {
          setLoading(false);
          setDataList(data);
        })
        .catch(() => alert("Erreur System Liste Client Data 2"));
    };
    getData();
    dispatch(SetPageTitle("Liste des Clients"));
  }, [dispatch, navigate]);



  const deleteData = (Client) => {
    // console.log(Client.TotalBL-Client.TotalReg)
    if (Client.TotalBL - Client.TotalReg === 0) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Êtes vous sûr de vouloir supprimer\n ${Client.Client} ?`)) {
        setLoading(true);
        delelteClient(Client)
          .then((message) => {
            setLoading(false);
            getData();
            console.log(message);
          })
          .catch((e) => {
            alert("Erreur System Suppression");
            console.log(e);
          });
      }
    } else {
      alert("Sode non null");
    }
  };

  return (
    isLogin() &&
    DataList && (
      <>
        <div className="row mb-3 not-print">
          <div className="col-8">
            <div className="input-group">
              <input
                ref={seach}
                className="form-control"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Clients"
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
          <div className="col-2 d-flex align-items-center">
            <button
              className="btn btn-light text-primary"
              onClick={() => {
                getSortedData();
              }}
            >
              <i className="fa fa-sort"></i>
            </button>
          </div>

          <div className="col-2 text-end">
            <button
              className="btn btn-info btn-sm text-white"
              onClick={() => pageRoles.upte && ClientModal_Ref.current.OpenModal()}
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <TableOne>
          {DataList.map((row, i) => (
            SerachData(row.Client, searchText) &&
            <tr key={i}>
              <td onDoubleClick={() => ClientModal_Ref.current.OpenModal(row)}>
                <div className="row align-items-center">
                  <div className="col-7 col-md-5">
                    <b>{row.Client}</b>
                  </div>
                  <div className="col-5 col-md-2 text-end">
                    <b>{row.Tel}</b>
                  </div>
                  <div className="col-5  col-md-3 mb-1">
                    <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-100">
                      Solde
                      <span className="badge bg-white w-50 text-end text-dark">
                        {FormattNumber(row.TotalBL - row.TotalReg - 0, 2)}
                      </span>
                    </button>
                  </div>
                  <div className="col-7 col-md-2 text-end">
                    <button
                      className="btn btn-primary btn-sm edit"
                      onClick={() => ClientModal_Ref.current.OpenModal(row)}
                      name="edit"
                      type="button"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      onClick={() => navigate("/vente/client/" + row.IdClient)}
                      className="btn btn-info btn-sm  mx-1"
                    >
                      <i className="fa fa-location-arrow"></i>
                    </button>

                    <button
                      className="btn btn-warning btn-sm delete"
                      name="delete"
                      type="button"
                      onClick={() => pageRoles.del && deleteData(row)}
                      disabled={!pageRoles.del}
                    >
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </TableOne>

        <FooterTable>
          <div className="col-6">Total Clients : {DataList.filter(row => SerachData(row.Client, searchText)).length}</div>
          <div className="col-6 text-end">
            <span className="mx-2">
              <b>Total </b> :
              {FormattNumber(
                DataList.reduce((q, row) => SerachData(row.Client, searchText) ? q + row.Solde * 1 : q, 0),
                2
              )}
            </span>
          </div>
        </FooterTable>

        <EditClientModal ref={ClientModal_Ref} getData={getData} upte={pageRoles.upte} />
        <Spinner isLoading={isLoading} />

        <table className="table w-100 table-bordered small d-none print">
          <tbody>
            {DataList.filter(row => SerachData(row.Client, searchText)).map((row, i) => (
              <>
                {(i % NumPage === 0 || i === 0) && (
                  <>
                    <tr className="text-center border-0 ">
                      <th
                        colSpan={6}
                        className="border-0"

                      >
                        <div className="d-flex justify-content-between">
                          <span>Liste Clients</span>
                          <span>
                            Page N° : {i / NumPage + 1} /
                            {Math.round((DataList.filter(row => SerachData(row.Client, searchText)).length - 1) / NumPage) + 1}
                          </span>
                          <span>le {reformatDate(formatDate(Date()))}</span>
                        </div>
                      </th>
                    </tr>
                    <tr className="text-center">
                      <th className=" bg-secondary text-white">N°</th>
                      <th className=" bg-secondary text-white">Clients</th>
                      <th className=" bg-secondary text-white">Tél</th>
                      <th className=" bg-secondary text-white">ICE</th>
                      <th className=" bg-secondary text-white">Adresse</th>
                      <th className=" bg-secondary text-white w-auto">Solde</th>
                    </tr>
                  </>
                )}

                <tr
                  key={i}
                  style={{
                    pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                  }}
                >
                  <td>
                    <b>{padNumber(i + 1, 3)}</b>
                  </td>
                  <td className="" style={{ maxWidth: "200px",width:"200px" }}>
                    {row.Client}
                  </td>
                  <td className="text-center" style={{ maxWidth: "120px",width:"120px" }}>
                    {row.Tel} <br /> {row.Mobile}
                  </td>
                  <td className="text-center" style={{ minWidth: "140px" }}>
                    {row.ICE}
                  </td>
                  <td style={{ maxWidth: "140px",width:"140px" }}>{row.Adresse}</td>
                  <td className="text-end" style={{ maxWidth: "80px",width:"80px" }}>
                    {FormattNumber(row.Solde * 1, 2)}
                  </td>
                </tr>
              </>
            ))}

            <tr>
              <th colSpan={3}>Total Clients : {DataList.filter(row => SerachData(row.Client, searchText)).length}</th>
              <th className="text-end" colSpan={3}>
                <b>Total </b> :
                {FormattNumber(
                  DataList.filter(row => SerachData(row.Client, searchText)).reduce((total, row) => total + row.Solde * 1, 0),
                  2
                )}
              </th>
            </tr>
          </tbody>
        </table>
      </>
    )
  );
};

export default Clients;
