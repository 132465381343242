import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import {
  updateFournisseur,
  AddFournisseur,
} from "../../../services/fournisseurs";

const EditFournisseurModal = forwardRef((props, ref) => {
  const OpenFournisseurModalBtn = useRef();
  const FormContainer = useRef();

  const [FromErrors, setFromErrors] = useState({});
  const [currentFournisseur, setCurrentFournisseur] = useState({});

  const initialFournisseur = {
    IdFournisseur: "",
    Fournisseur: "",
    ICE: "",
    Adresse: "",
    Tel: "",
    Mobile: "",
    Solde: "0",
    Etat: "A",
  };

  useImperativeHandle(ref, () => ({
    OpenModal(Fournisseur = initialFournisseur) {
      OpenFournisseurModalBtn.current.click();

      setCurrentFournisseur(Fournisseur);
      // console.log(Fournisseur)

      const form = FormContainer.current.getElementsByTagName("form")[0];
      form.Fournisseur.value = Fournisseur.Fournisseur;
      form.ICE.value = Fournisseur.ICE;
      form.Adresse.value = Fournisseur.Adresse;
      form.Tel.value = Fournisseur.Tel;
      form.Mobile.value = Fournisseur.Mobile;

      // setFromErrors({})
    },
  }));

  const VerifData = () => {
    let verif = {};
    if (currentFournisseur.Fournisseur === "")
      verif = { ...verif, Fournisseur: "Erreur Fournisseur" };

    setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    console.log(currentFournisseur.IdFournisseur);
    if (currentFournisseur.IdFournisseur === "") {
      AddFournisseur(currentFournisseur).then((data) => {
        props.getData();
        currentFournisseur.IdFournisseur = data.lastID;

        console.log(data.message);
      });
    } else {
        console.log(currentFournisseur)
      updateFournisseur(currentFournisseur)
        .then((message) => {
        props.getData();

          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const Newbtn = () => {
    setCurrentFournisseur(initialFournisseur);
    const form = FormContainer.current.getElementsByTagName("form")[0];
    form.Fournisseur.value = initialFournisseur.Fournisseur;
    form.ICE.value = initialFournisseur.ICE;
    form.Adresse.value = initialFournisseur.Adresse;
    form.Tel.value = initialFournisseur.Tel;
    form.Mobile.value = initialFournisseur.Mobile;
  };

  return (
    <>
      <button
        type="button"
        ref={OpenFournisseurModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModal"
      >
        OpenFournisseurModalBtn
      </button>

      <div
        ref={FormContainer}
        className="modal fade"
        id="myModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myModalLabel">
                Edition Fournisseur
              </h5>
              {props.parent === "combo" ? (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-target="#myModalList"
                  data-bs-toggle="modal"
                  aria-label="Close"
                ></button>
              ) : (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              )}
            </div>
            <form className="modal-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="form-group">
                    <label>Fournisseur</label>
                    <input
                      id="Fournisseur"
                      className="form-control form-control-sm"
                      type="text"
                      onChange={(e) =>
                        (currentFournisseur.Fournisseur = e.target.value)
                      }
                    />
                  </div>
                  <span className="text-danger">
                    {FromErrors.Fournisseur || ""}
                  </span>
                </div>
                <div className="col-12 mb-3">
                  <div className="form-group">
                    <label className="mb-1">ICE </label>
                    <input
                      id="ICE"
                      className="form-control form-control-sm"
                      type="text"
                      onChange={(e) =>
                        (currentFournisseur.ICE = e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="col-6 mb-3">
                  <div className="form-group">
                    <label>Tél</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="Tel"
                      onChange={(e) =>
                        (currentFournisseur.Tel = e.target.value)
                      }
                    />
                  </div>
                  <span className="text-danger">{FromErrors.Tel || ""}</span>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="Mobile"
                      onChange={(e) =>
                        (currentFournisseur.Mobile = e.target.value)
                      }
                    />
                  </div>
                  <span className="text-danger">{FromErrors.Tel || ""}</span>
                </div>
                <div className="col-12 mb-3">
                  <div className="form-group">
                    <label>Adresse</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="Adresse"
                      onChange={(e) =>
                        (currentFournisseur.Adresse = e.target.value)
                      }
                    />
                  </div>
                  <span className="text-danger">
                    {FromErrors.Adresse || ""}
                  </span>
                </div>
              </div>
            </form>
            <div className="modal-footer d-flex justify-content-between">
              <button
                onClick={() => {
                  props.upte && Newbtn();
                }}
                type="button"
                className="btn btn-outline-warning text-black btn-sm"
                disabled={!props.upte}
              >
                Nouveau
              </button>
              <div>
                {props.parent === "combo" ? (
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    data-bs-target="#myModalList"
                    data-bs-toggle="modal"
                  >
                    Fermer
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary mx-1 btn-sm"
                    data-bs-dismiss="modal"
                  >
                    Fermer
                  </button>
                )}
                <button
                  onClick={() => props.upte && VerifData()}
                  type="button"
                  className="btn btn-primary btn-sm"
                  disabled={!props.upte}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default EditFournisseurModal;
