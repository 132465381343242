import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  getFactureListe,
  AddFacture,
  getSingleFacture,  
  deleteFacture,
} from "../../../services/factures_ventes";
import {
  isLogin,
  GetDate,
  padNumber,
  FormattNumber,
  formatDate,
  reformatDate,
  SerachData,
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";

import EditModal from "./EditModal";
import PeriodeDateur from "../../../components/widgets/periode/periode";

const FacturesVentes = ({ pageRoles }) => {
  const NumPage = 28;

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");


  const [currentPrint, setCurrentPrint] = useState({
    IdFacture: 0,
    NumFacture: 0,
    CodeClt: "",
    DateFacture: formatDate(new Date()),
    Mod_HT: "0",
    Avoir: 1,
    MontantTTC: 0,
    Tva: 0,
    Description: "",
    UserID: 0,
    Client: "Comptant",
  });
  const [currentDetailPrint, setDetailCurrentPrint] = useState([]);

  const seach = useRef();
  const EditModal_Ref = useRef();
  const PeriodeDateurRef = useRef();

  const ListePrintRef = useRef();
  const DetailPrintRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const switchPrint = (doc) => {
    if (doc === "Detail") {
      ListePrintRef.current.classList.remove("print");
      DetailPrintRef.current.classList.add("print");
    } else {
      ListePrintRef.current.classList.add("print");
      DetailPrintRef.current.classList.remove("print");
    }
  };

  const getData = async () => {
    const Periode = PeriodeDateurRef.current.getPeriode();
    if (Periode.debut > Periode.fin) {
      alert("Période Non Valide");
    } else {
      if (seach.current.value === "") {
        setLoading(true);
        const data = await getFactureListe(Periode);
        setDataList(data);
        setLoading(false);
      } else {
        SerachData(seach.current.value);
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/factures");
    if (!isLogin()) {
      navigate("/login");
    }
    const getFistData = async () => {
      const periode = await GetDate();

      setLoading(true);
      const data = await getFactureListe(periode);
      setDataList(data);
      setLoading(false);
    };

    getFistData();
    dispatch(SetPageTitle("Liste des Facture Clients"));
  }, [dispatch, navigate]);

  

  const AddNewFacture = async (Avoir) => {
    setLoading(true);
    AddFacture(Avoir)
      .then((LastID) => {
        setLoading(false);
        return getSingleFacture(LastID);
      })
      .then((Facture) => EditModal_Ref.current.OpenModal({ ...Facture }))
      .catch((e) => {
        alert("Erreur Ajout");
        console.log(e);
      });
  };

  const delelteFacture = (Facture) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Êtes vous sûr de vouloir supprimer le Facture N°: \n ${padNumber(
          Facture.NumFacture,
          4
        )} ?`
      )
    ) {
      setLoading(true);
      deleteFacture(Facture.IdFacture)
        .then((message) => {
          setLoading(false);
          getData();
          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  return (
    isLogin() &&
    DataList && (
      <>
       <div className="row mb-lg-2 not-print">
          <div className="col-12 col-md-5 text-end d-flex gap-2 mb-1">
            <PeriodeDateur ref={PeriodeDateurRef} getData={getData} />
          </div>
          <div className="col-12 col-md-7 text-end d-flex gap-2 mb-1 ">
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => pageRoles.upte && AddNewFacture(-1)}
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-minus"></i>
            </button>
            <div className="input-group">
              <input
                ref={seach}
                className="form-control form-control-sm"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Clients"
              />
              <span className="input-group-text">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => pageRoles.upte && AddNewFacture(1)}
              disabled={!pageRoles.upte}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <TableOne vh="vh-scroled-2">
          {DataList.map((row, i) =>
            SerachData(row.Client, searchText) && (
              <tr key={i}>
                <td
                  onDoubleClick={() => {
                    switchPrint("Detail");
                    setCurrentPrint({ ...row });
                    EditModal_Ref.current.OpenModal({ ...row });
                  }}
                >
                  <div className="row align-items-center">
                    {row.Avoir * 1 === 1 ? (
                      <div className="col-6 col-md-2">
                        <b>Fac : {padNumber(row.NumFacture, 4)}</b>
                      </div>
                    ) : (
                      <div className="col-6 col-md-2 btn btn-danger">
                        Avoir : {padNumber(row.NumFacture, 4)}
                      </div>
                    )}
                    <div className="col-6 col-md-2">
                      {reformatDate(row.DateFacture)}
                    </div>
                    <div className="col-12 col-md-4">
                      <div>
                        <b>{row.Client} </b>
                      </div>
                      <div className="text-end text-secondary">
                        {row.Description}
                      </div>
                    </div>

                    <div className="col-6 col-md-2 text-end">
                      <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-100 mb-1">
                        Total
                        <span className="badge bg-white w-50 text-end text-dark">
                          {FormattNumber(row.MontantTTC * row.Avoir, 2)}
                        </span>
                      </button>
                    </div>
                    <div className="col-6 col-md-2 text-end">
                      <button
                        className="btn btn-primary btn-sm mx-1"
                        onClick={() => {
                          switchPrint("Detail");
                          setCurrentPrint({ ...row });
                          EditModal_Ref.current.OpenModal({ ...row });
                        }}
                      >
                        <i className="fa fa-edit"></i>
                      </button>

                      <button
                        className="btn btn-warning btn-sm delete"
                        onClick={() => pageRoles.del && delelteFacture(row)}
                        disabled={!pageRoles.del}
                      >
                        <i className="fa fa-trash-o"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </TableOne>
        <table className="w-100 mt-1 not-print">
          <tbody>
            <tr>
              <td>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <button className="btn btn-outline-secondary btn-sm d-flex justify-content-between align-items-center w-50">
                    Doc :
                    <span className="badge bg-white w-50 text-end text-dark">
                    {
                        DataList.filter((row) =>
                          SerachData(row.Client, searchText)
                        ).length
                      }
                    </span>
                  </button>

                  <button className="btn btn-outline-primary btn-sm d-flex justify-content-between align-items-center w-50">
                    Total :
                    <span className="badge bg-white w-50 text-end text-dark">
                      {FormattNumber(
                         DataList.filter((row) =>
                         SerachData(row.Client, searchText)
                       ).reduce(
                          (q, row) => q + row.MontantTTC * row.Avoir,
                          0
                        ),
                        2
                      )}
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <EditModal
          ref={EditModal_Ref}
          getData={getData}
          switchPrint={switchPrint}
          setCurrentPrint={setCurrentPrint}
          setDetailCurrentPrint={setDetailCurrentPrint}
        />
        <Spinner isLoading={isLoading} />

        <table
          ref={ListePrintRef}
          className="table w-100 table-bordered small d-none print"
        >
          {DataList.filter(row => SerachData(row.Client,searchText)).map((row, i) => (
            <tbody key={i}>
              {(i % NumPage === 0 || i === 0) && (
                <>
                  <tr className="w-100 text-center border-0">
                    <th colSpan={4} className="border-0 px-0">
                      <div className="d-flex justify-content-between">
                        <span>
                          Factures d'Achats :
                          {reformatDate(
                            formatDate(
                              PeriodeDateurRef.current.getPeriode().debut
                            )
                          )}
                          /
                          {reformatDate(
                            formatDate(
                              PeriodeDateurRef.current.getPeriode().fin
                            )
                          )}
                        </span>
                        <span>
                          Page N° : {i / NumPage + 1} /
                          {Math.round((DataList.filter(row => SerachData(row.Client,searchText)).length - 1) / NumPage) + 1}
                        </span>
                      </div>
                    </th>
                  </tr>
                  <tr className="w-100 text-center ">
                    <th>Num</th>
                    <th>Date</th>
                    <th>Client</th>
                    <th>Total</th>
                  </tr>
                </>
              )}
              {
                
                <tr
                  style={{
                    pageBreakAfter: (i + 1) % NumPage === 0 ? "always" : "",
                  }}
                >
                  {row.MontantTTC * row.Avoir >= 0 ? (
                    <td className="small" style={{ minWidth: "150px" }}>
                      <b>Fac : {row.NumFacture}</b>
                    </td>
                  ) : (
                    <td className="text-danger small">Avoir : {row.NumFacture}</td>
                  )}
                  <td className="text-center" style={{ minWidth: "100px" }}>
                    {reformatDate(row.DateFacture)}
                  </td>
                  <td className="w-75">{row.Client}</td>
                  <td className="text-end" style={{ minWidth: "120px" }}>
                    {FormattNumber(row.MontantTTC * row.Avoir, 2)}
                  </td>
                </tr>
              }

            </tbody>
          ))}
          <tbody>
            <tr>
              <th colSpan={3}>Factures d'achats : {
                DataList.filter((row) =>
                  SerachData(row.Client, searchText)
                ).length
              }</th>
              <th className="text-end samll">
                <b>Total </b> :
                {FormattNumber(
                  DataList.reduce(
                    (q, row) =>
                      SerachData(row.Client, searchText)
                        ? q + row.MontantTTC * row.Avoir
                        : q,
                    0
                  ),
                  2
                )}
              </th>
            </tr>
          </tbody>
        </table>

        <table ref={DetailPrintRef} className="table table-borderless d-none">
          <thead>
            <tr className="w-100 text-center ">
              <td colSpan={6}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end" colSpan={2}>
                        Date Facture : {reformatDate(currentPrint.DateFacture)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "200px", height: "100px" }}
                        className="border"
                      >
                        Logo
                      </td>
                      <td>
                        Client : <b>{currentPrint.Client}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-end">
                        N° Facture :
                        <b>
                          {currentPrint.NumFacture} /
                          {currentPrint.DateFacture.substr(-2)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="text-center  bg-secondary text-white  rounded-start">
                N°
              </td>
              <th className="w-50 text-center bg-secondary text-white border border-0 border-top border-white">
                Désignation
              </th>

              <th
                className="text-center  bg-secondary text-white  "
                style={{ minWidth: "60px" }}
              >
                Qté
              </th>
              <th
                className="text-center bg-secondary text-white "
                style={{ minWidth: "80px" }}
              >
                PU
              </th>
              <th
                className="text-center  bg-secondary text-white "
                style={{ minWidth: "40px" }}
              >
                R%
              </th>
              <th
                className="text-center  bg-secondary text-white rounded-end"
                style={{ minWidth: "120px" }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody className="small">
            {currentDetailPrint.map((row, i) => (
              <tr key={i} className="border">
                <td className="text-center border">
                  <b>{i + 1}</b>
                </td>
                <td className="w-75">
                  {row.RefArticle} | {row.designation}
                </td>

                <td className="text-center border">{row.Quantite}</td>
                <td className="text-end">{FormattNumber(row.Prix - 0, 2)}</td>
                <td className="text-center border">{row.Remise}%</td>
                <td className="text-end border">
                  {FormattNumber(row.Total - 0, 2)}
                </td>
              </tr>
            ))}
            <tr className="w-100 text-center ">
              <td colSpan={6}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-end">
                        Total Vente :
                        {FormattNumber(currentPrint.MontantTTC * 1, 2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
          <tfoot className="page-break">
            <tr>
              <th colSpan={6}>
                <div className="d-flex justify-content-between">
                  <span></span>
                  <span id="pageFooter"></span>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    )
  );
};

export default FacturesVentes;
