import {useState,useRef,useEffect,forwardRef} from 'react';


import { searchFournisseurs } from '../../../services/achats';

import EditFournisseurModal from "../Fournisseurs/EditFournisseurModal";

const Combo_Fournisseur = forwardRef((props, ref) => {

    const FournisseurText = useRef();
    const FournisseurModal_Ref = useRef();

    const [ShowFournisseurs, setShowFournisseurs] = useState(false);
    const [DataFournisseur, setDataFournisseur] = useState([]);
    const [Fournisseur,setFournisseur] = useState({CodeFour: 1,Fournisseur: 'Fournisseur Comptant'});


   
    useEffect(() => {
                
        setFournisseur(props.Fournisseur);
        FournisseurText.current.value = props.Fournisseur.Fournisseur; 
              
      },[props.Fournisseur]);

    
    const SelectFournisseur = (idFournisseur, DesignationFournisseur) => {        
        setShowFournisseurs(false);
        setFournisseur({CodeFour: idFournisseur,Fournisseur: DesignationFournisseur})
        props.setcurrentFournisseur(idFournisseur, DesignationFournisseur);
        FournisseurText.current.value = DesignationFournisseur;          
    }

    return (
        <>
           <div className='d-flex gap-1'>
           <input className="form-control m-auto" ref={FournisseurText}  
            onFocus={() => setShowFournisseurs(true)} placeholder="Selectionner Fournisseur" 
            onChange={async (e) =>   setDataFournisseur(await searchFournisseurs(e.target.value))}                                    
            />
            { 
                ShowFournisseurs &&
                <>          
                    <button className="btn btn-light btn-xs" 
                    type="button" 
                    onClick={  () => {
                        setShowFournisseurs(false);
                        SelectFournisseur(Fournisseur.CodeFour,Fournisseur.Fournisseur)
                    }} >
                        <i className="fa fa-arrow-left"></i>
                    </button>
                    <button className="btn btn-outline-primary btn-sm" 
                    type="button" 
                    onClick={() => FournisseurModal_Ref.current.OpenModal()}
                     >
                        <i className="fa fa-user-plus"></i>
                    </button>
                    </>
            }
             </div>   
            { 
                ShowFournisseurs && 
                <div className="vh-scroled-modal bg-white border" style={{position:'absolute',zIndex:'99',top:'60px', width:'90%'}}> 
                    <table className="table"  >
                        <tbody className=" "  >
                            {
                                DataFournisseur.map((row,i) => 
                                    <tr key={i}>
                                    <td className="text-center" data-style="width:50px;"> {i+1} </td>
                                    <td data-style="width:100%;"> { row.Fournisseur } </td>
                                    <td className="text-end" data-style="width:50px;">
                                    <button className="btn btn-secondary btn-sm" type="button"
                                        onClick={() => SelectFournisseur(row.IdFournisseur ,row.Fournisseur)}>
                                            <i className="fa fa-check"></i>
                                    </button>
                                </td>
                            </tr>

                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
              
            }
        </>
    );
})

export default Combo_Fournisseur;