
import axios from "axios";
import { useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector  } from 'react-redux';

import { SetPageTitle } from '../redux/slices/LayoutSlice';
import { SetCurrentUser } from "../redux/slices/userSlice";

import logo from '../assets/logo-4.png'
import { getUser} from "../services/params";

const baseApi="https://api.gstok.com"

const Login = () => {
  const dispatch = useDispatch();
  let winSize  = useSelector((state) => state.LayoutReducer.winSize) ;
  const currentUser = useSelector((state) => state.userReducer.currentUser);

    useEffect(() => {
        dispatch(SetPageTitle('Login'));
        
    },[dispatch]);


  const navigate = useNavigate();

  const inputEmail = useRef();
  const inputPassword = useRef();

  const VerifData = () => {
    let verif = {};        
          
    if(Object.keys(verif).length === 0){ PostLogin()}

    }  

    const PostLogin = async () =>  { 
      
      const data = await axios.post(`${baseApi}/login`, {        
        email: inputEmail.current.value,
        password: inputPassword.current.value,
        token:sessionStorage.getItem("token"),
    })
      sessionStorage.setItem("token", data.data);
      const url = sessionStorage.getItem("url") ? sessionStorage.getItem("url") : "/produits/liste";      
      navigate(url);

      getUser().then((data) => {
        dispatch(SetCurrentUser(data));
      })
      .catch(() => alert("Erreur System user Data"));
      
   }

   const deconnection =() => {
      sessionStorage.clear();
      navigate("/login");
   }

    return (
      
      
        <div className="container-fluid vh-100 d-flex flex-column align-items-center bg-primary  bg-opacity-25 login-section">
          
          <form className={winSize < 992 ? "d-flex flex-column align-items-center border py-4 bg-opacity-25  bg-white rounded-3 top-10 w-75" :"d-flex flex-column align-items-center border py-4 bg-opacity-25 bg-white rounded-3 top-20 w-50"}>
            
            <img src={logo} alt="Gesom logo" className="logo mb-3 m-auto"/>
            <h5 className="text-uppercase h6">Accée Contôlé</h5>
            
            
              {
                !sessionStorage.getItem("token") ? (
                    <>
                        <div className="form-outline mb-4 w-75">
                          
                          <label className="form-label" htmlFor="form2Example1">Adresse E-mail</label>
                          <input ref={inputEmail} type="email" id="form2Example1" className="form-control"/>
                        </div>

                        
                        <div className="form-outline mb-4 w-75">
                        <label className="form-label" htmlFor="form2Example2">Mot de Passe</label>
                          <input ref={inputPassword} type="password" id="form2Example2" className="form-control"/>
                          
                        </div>
                        <div className="d-flex w-75 mb-4 justify-content-end">              
                          <a className="text-primary " href="/">Mot de passe oublié ?</a>
                        </div>
                        
                        <button  onClick={() => VerifData()} type="button" className="btn bg-primary bg-opacity-75 w-50  text-white" >CONNEXION</button>
                    </>):
                        (
                         
                            <button  onClick={() => deconnection()} type="button" className="btn btn-primary" >Deconnexion </button>
                          
                        )
              } 
              
          </form>
          <p className="text-capitalize mt-2 small">Gestion Commerciale Stock & Facture 23</p>
        </div>
    )
};

export default Login;