import axios from "axios";
import { deconnect } from "./global";

const baseApi = "https://api.gstok.com";

export const AddInventaire = async () => {
  const data = await axios.post(`${baseApi}/Inventaires`, {
    action: "AddNew",
    token: sessionStorage.getItem("token"),
  });

  console.log(data.data);
  if (data.data.LastID && data.status === 200) {
    return data.data.LastID;
  } else {
    deconnect();
  }
};

export const getSingleInventaire = async (IdInventaire) => {
  const data = await axios.post(`${baseApi}/Inventaires`, {
    action: "single",
    token: sessionStorage.getItem("token"),
    IdInventaire: IdInventaire,
  });
  console.log(data.data);
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getInventaireListe = async (periode) => {
  const data = await axios.post(`${baseApi}/Inventaires`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
  });
  //    console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const searchInventaire = async () => {
  const data = await axios.post(`${baseApi}/Inventaires`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const deleteInventaire = async (IdInventaire) => {
  const data = await axios.post(`${baseApi}/Inventaires`, {
    action: "delete",
    token: sessionStorage.getItem("token"),
    IdInventaire: IdInventaire,
  });

  // console.log(data)
  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const getFamillesInventaire = async () => {              
    const data = await axios.post(`${baseApi}/Familles`, {
        action: 'getFamillesInventaire',
        token:sessionStorage.getItem("token"),        
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const SetZeroInvFamilles = (RefFamille) => { 
  axios.post(`${baseApi}/InventairesDetail`, {
      action: 'SetZeroInvFamilles',
      token:sessionStorage.getItem("token"),
      RefFamille: RefFamille,
  })
 
};
export const AddDetailInventaire = async (IdInventaire,TotalArticles) => {              
    const data = await axios.post(`${baseApi}/InventairesDetail`, {
        action: 'AddDetailInventaire',
        token:sessionStorage.getItem("token"),
        IdInventaire: IdInventaire, 
        TotalArticles:TotalArticles,  
    })

    // console.log(data.data.TotalArticles)

    if (data.status===200){return data.data.TotalArticles}
    else {deconnect();}
};

//Detail Inventaire

export const updateInventaire = async (current) => {  
  const data = await axios.post(`${baseApi}/Inventaires`, {
    action: "updateInventaire",
    token: sessionStorage.getItem("token"),
    IdInventaire: current.IdInventaire,
    DateInventaire: current.DateInventaire,
    Bon: current.Bon,
    Commentaire: current.Commentaire,
    Familles: current.Familles,
  });

  //   console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const getDetailData = async (IdInventaire) => {
  const data = await axios.post(`${baseApi}/InventairesDetail`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    IdInventaire: IdInventaire,
  });
  //  console.log(IdInventaire)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

// export const AddDetailData = async (Detail) => {
//   const data = await axios.post(`${baseApi}/InventairesDetail`, {
//     action: "insert",
//     token: sessionStorage.getItem("token"),

//     CodeInventaire: Detail.codeInventaire,
//     CodeArticle: Detail.CodeArticle,
//     qteStock: Detail.qteStock,
//     qteInv: Detail.qteInv,
//     Designation: Detail.Designation,
//   });
//   //  console.log(data.data)
//   if (data.data && data.status === 200) {
//     return data.data.message;
//   } else {
//     deconnect();
//   }
// };

// export const updateDetailData = async (Detail) => {
//   const data = await axios.post(`${baseApi}/InventairesDetail`, {
//     action: "update",
//     token: sessionStorage.getItem("token"),
//     IdDetailInventaire: Detail.IdDetailInventaire,
//     CodeArticle: Detail.CodeArticle,
//     qteStock: Detail.qteStock,
//     qteInv: Detail.qteInv,
//     Designation: Detail.Designation,
//   });
//   //  console.log(data.data.message)
//   if (data.data && data.status === 200) {
//     return data.data.message;
//   } else {
//     deconnect();
//   }
// };

// export const deleteDetailData = async (IdDetailInventaire) => {
//   const data = await axios.post(`${baseApi}/InventairesDetail`, {
//     action: "delete",
//     token: sessionStorage.getItem("token"),
//     IdDetailInventaire: IdDetailInventaire,
//   });
//   //  console.log(data.data.message)
//   if (data.data && data.status === 200) {
//     return data.data.message;
//   } else {
//     deconnect();
//   }
// };
