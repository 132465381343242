import { useState,forwardRef, useRef, useImperativeHandle } from "react";
import { AddReglement, UpdateReglement } from '../../../services/achats';
import ComboFournisseur from "../Fournisseurs/_ComboFournisseur";




const EditReglementModal = forwardRef((props, ref) => {

    const OpenReglementModalBtn = useRef();
    const FormContainer = useRef();       
     
    
    
    const [FromErrors, setFromErrors] = useState({});   
    const [currentReglement, setCurrentReglement] = useState({});
    
    
    const [isEspece, setIsEspece] = useState(true);
    
    
    const setcurrentFournisseur = (idFournisseur, DesignationFournisseur) => {
       
        currentReglement.CodeFour = idFournisseur;
        currentReglement.Fournisseur = DesignationFournisseur;
        // console.log(currentReglement)
    }
    

    const initialReglement  = {        
        "Num" : 0,
        "IdRegBA":0,             
        "DateReg" : new Date().toJSON().slice(0, 10),
        "DateEch" : new Date().toJSON().slice(0, 10),
        "Montant" : 0,
        "ModeReg" : 'Espece',
        "Banque" : '',
        "NumDoc" : '',
        "Type" : 'C',
        "Sens" : 1,        
        "Etat" : 'R',
        "TypeDoc" : 'B',
        "CodeFour" : 1,
        "Fournisseur" :'Fournisseur Comptant' ,
        "idBon":0,
        "NumBA":'',
      }   
      
    useImperativeHandle(ref,  () => ({
        
        async OpenModal (Reglement = initialReglement) {             
            OpenReglementModalBtn.current.click();           
            // console.log(Reglement)
            setCurrentReglement(Reglement); 
           
            setIsEspece(Reglement.ModeReg==='Espece');

             const form = FormContainer.current.getElementsByTagName('form')[0]; 

                form.DateReg.value = Reglement.DateReg;
                form.DateEch.value = Reglement.DateEch;
                form.Montant.value = Reglement.Montant;
                form.ModeReg.value = Reglement.ModeReg;
                form.Banque.value = Reglement.Banque;
                form.NumDoc.value = Reglement.NumDoc;
            
                
            setFromErrors({})


            
        }

    }))

    const VerifData = () => {        

        let verif = {};        
        if(currentReglement.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}                           

        setFromErrors(verif);        
        if(Object.keys(verif).length === 0){ SaveData()}

    }
    const SaveData = () =>  {
         if (currentReglement.IdRegBA===0) {
            AddReglement(currentReglement,0,currentReglement.CodeFour).then( async (data) => {
                props.getData();

                currentReglement.IdRegBA = data.lastId;
    
                console.log(data.message);
           })
         }
         else
         {
            UpdateReglement(currentReglement).then((message) => {
                props.getData();
            });
         }
         
        
    }

  
    
   
      
    return (
        <>
            <button type="button" ref={OpenReglementModalBtn} className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#myModal">
                OpenReglementModalBtn
            </button>
            
            <div ref={FormContainer} className="modal fade" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Edition Reglement</h5>
                            <button  type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form className="modal-body">
                        
                            <div className="row my-2">
                                <div className="col-12">
                                    {(currentReglement.idBon === 0 ||  currentReglement.idBon === '0')
                                    ? 
                                        <ComboFournisseur setcurrentFournisseur={setcurrentFournisseur} Fournisseur={{CodeFour:currentReglement.CodeFour,Fournisseur:currentReglement.Fournisseur}}/>
                                    :
                                        <p className="border border-primary text-center rounded ">{currentReglement.Fournisseur} - Bon : {currentReglement.NumBA}</p>
                                    
                                    }
                                </div>
                                <div className="col-6">
                                <div className="form-group">
                                    <label>Date Réglement</label>
                                    <input className="form-control" type="date" id="DateReg"
                                    onChange={e => currentReglement.DateReg = e.target.value } />
                                </div>
                                </div>

                                <div className="col-6">
                                <div className="form-group">
                                    <label>Date Echéance</label>
                                    <input className="form-control" type="date" id="DateEch" 
                                    onChange={e => currentReglement.DateEch = e.target.value } />                                
                                </div>
                                </div>

                            </div>
                            <div className="row mb-2">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Montant</label>
                                        <input className="form-control" type="number" id="Montant" 
                                        onChange={e => currentReglement.Montant = e.target.value }
                                        />
                                    </div>
                                    <span className="text-danger">{FromErrors.Montant || ''}</span>
                                </div>
                                <div className="col-6">
                                <div className="form-group">
                                    <label>Mode Rég</label>
                                    <select className="form-select" id="ModeReg" onChange={e => {currentReglement.ModeReg = e.target.value; setIsEspece(e.target.value==='Espece')} }>
                                        <option value="Espece">Espece</option>
                                        <option value="Chéque">Chéque</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                        
                            <div className={isEspece ? "row d-none" :"row"} >
                                <div className="col-4 ">
                                    <div className="form-group">
                                        <label>Banque</label>
                                        <input className="form-control" type="text" id="Banque" 
                                        onChange={e => currentReglement.Banque = e.target.value } />
                                    </div>
                                </div>
                                <div className="col-8 ">
                                    <div className="form-group">
                                        <label>Num Document</label>
                                        <input className="form-control" type="text" id="NumDoc" 
                                        onChange={e => currentReglement.NumDoc = e.target.value } />
                                    </div>
                                </div>
                            </div>
                            
                            
                        </form>
                        <div className="modal-footer">                           
                            
                                <button type="button" className="btn btn-outline-secondary mx-1 btn-sm" data-bs-dismiss="modal">Fermer</button>
                                <button  disabled={!props.upte}  onClick={() => props.upte && VerifData()} type="button" className="btn btn-outline-success btn-sm" >Enregistrer</button>
    
                            
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    );
});

export default EditReglementModal;

