import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { updateInventaire,AddDetailInventaire } from "../../../../services/inventaires";
import {
  padNumber,  
  formatDate,
} from "../../../../services/global";

import DetailInventaire from "./DetailInventaire";
import FamillesInevnatire from "./FamillesInevnatire";
import Spinner from "../../../../components/widgets/spinner/spinner";

const EditModalInventaire = forwardRef((props, ref) => {
  const OpenModalBtn = useRef();
  const FormHeader = useRef();
  const FormFooter = useRef();
  const EditModal = useRef();
  const DetailInventaireRef = useRef();
  const RefSearch = useRef();
 

  const [FromErrors, setFromErrors] = useState({});
  const [currentInventaire, setcurrentInventaire] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [OldFamilles, setOldFamilles] = useState(0);
 
 

  const setDetailPrint = (data) => {
    props.setDetailCurrentPrint(data);
  };

  const initialInventaire = {
    IdInventaire: 0,    
    DateInventaire: formatDate(new Date()),
    Bon:0,  
    Commentaire: "",
    Familles: "",
  };

  useImperativeHandle(ref, () => ({
    async OpenModal(Inventaire = initialInventaire) {
      OpenModalBtn.current.click();     

      setcurrentInventaire(Inventaire);

      const FormInventaire = FormHeader.current;
      const FormInventaire2 = FormFooter.current;

      FormInventaire.IdInventaire.value = padNumber(Inventaire.IdInventaire, 4);
      FormInventaire.DateInventaire.value = Inventaire.DateInventaire;
    

      FormInventaire2.Commentaire.value = Inventaire.Commentaire;

      setFromErrors({});
      setOldFamilles(Inventaire.Familles)    

      window.onafterprint = function () {
        try {
          const div = document.querySelectorAll(".bg-transparent");
          div[0].classList.remove("bg-transparent");
          div[0].classList.add("modal-backdrop");
        } catch {}
      };
    },
  }));

  

  const imprimer = () => {
    const div = document.querySelectorAll(".modal-backdrop");
    div[0].classList.add("bg-transparent");
    div[0].classList.remove("modal-backdrop");
    window.print();
  };

  const VerifData = () => {
    let verif = {};
    // if(currentInventaire.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}

    // setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    if (currentInventaire.IdInventaire === "") {
      //     AddInventaire(currentInventaire).then((data) => {
      //         props.getData();
      //         currentInventaire.IdInventaire=data.lastID;
      //         console.log(data.message);
      //    })
    } else {
      updateInventaire(currentInventaire)
        .then((message) => {
          //  props.getData();
          props.setCurrentPrint({ ...currentInventaire });
          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const TriggerModal = (Modal = EditModal.current) => {    

    if (Modal.classList.contains("d-none")) {   

      Modal.classList.remove("d-none");
    } else {
      Modal.classList.add("d-none");
      setFromErrors({});
    }
  };

  const LoadInventaire = async (NewCommentaire,NewFamilles,TotalArticles) => {
    setLoading(true);
    await updateInventaire({...currentInventaire,Commentaire:NewCommentaire,Familles:NewFamilles.toString()}) ;
    setcurrentInventaire({...currentInventaire,Commentaire:NewCommentaire,Familles:NewFamilles.toString()});
    const Message = await AddDetailInventaire(currentInventaire.IdInventaire,TotalArticles);
    DetailInventaireRef.current.getData();    
    setLoading(false);

    if(!Message){
      alert('Erreur Création de la Lite Inventaire !')
    }
    
  }

  return (
    <>
      {/* button to open this modal from parent page */}
      <button      
        type="button"
        ref={OpenModalBtn}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#myModalList"
      >
        OpenModalBtn
      </button>

      {/* bootstrap modal container */}
      <div
        className="modal fade not-print"
        id="myModalList"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-"
        aria-labelledby="myModalListLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          {/* Inventaire Détail */}

          <div className="modal-content py-2">
            <button
              type="button"
              onClick={() => {
                props.switchPrint("List");
                props.getData();
                RefSearch.current.value="";
                DetailInventaireRef.current.search("")             
              }}
              className="btn btn-light position-absolute top-0 start-100 translate-middle rounded-circle"
              data-bs-dismiss="modal"
              aria-label="Close"              
            >
              X
            </button>

            <form ref={FormHeader} className="modal-header">
              <div className="row w-100 m-auto">
                <div className="col-12 col-md-6 d-flex gap-2 mb-1 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control text-center"
                    disabled={true}
                    type="text"
                    id="IdInventaire"
                    readOnly
                  />
                   <input
                    ref={RefSearch}              
                    className="form-control"
                    onChange={(e) => DetailInventaireRef.current.search(e.target.value)}
                    placeholder="Recherche"
                  />
                </div>

                <div className="col-6 col-md-4 input-group-sm">
                  <input
                    style={{ maxWidth: "120px" }}
                    className="form-control"
                    type="date"
                    id="DateInventaire"
                    onChange={(e) =>
                      (currentInventaire.DateInventaire = e.target.value)
                    }
                  />
                </div>

                <div className="col-6 col-md-2 justify-content-end d-flex gap-2 py-1 ">
                  <input
                    onClick={() => props.upte && VerifData()}
                    value="Enregistrer"
                    className="btn btn-primary btn-sm"
                    type="button"
                    disabled={!props.upte}
                  />
                   <input
                    onClick={() => props.upte && TriggerModal()}
                    value="Créer"
                    className="btn btn-success btn-sm"
                    type="button"
                    disabled={!props.upte}
                  />
                </div>
              </div>
            </form>
            
            <div className="modal-body border vh-scroled-detail">
              <DetailInventaire
                ref = {DetailInventaireRef}
                IdInventaire={currentInventaire.IdInventaire}
                getData={props.getData}                
                setDetailPrint={setDetailPrint}
                
              />
            </div>

            <form
              ref={FormFooter}
              className="modal-footer d-flex flex-column"
            >
              <div className="row w-100">
                <div className="col-8 col-md-6 input-group-sm">
                  <input
                    className="form-control"
                    type="text"
                    id="Commentaire"
                    placeholder="Edtion Commentaire"
                    value={currentInventaire.Commentaire || ""}
                    onChange={(e) => console.log(e.current.target.value) }
                                       
                  />
                </div>
                <div className="col-4 col-md-2 py-1">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => imprimer()}
                    type="button"
                  >
                    <i className="fa fa-print"></i>
                  </button>                  
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Famille Modal for inventaire*/}
      <div
        ref={EditModal}     
        // onClick={(e) => {if (e.target === e.currentTarget) {ComboProduitRef.current.callBackBtn()}}}
        className=" bg-dark bg-opacity-25 modal-fullscreen w-100 d-none fixed-top d-flex align-items-center justify-content-center"
        style={{zIndex:"2030"}}
      >
        <FamillesInevnatire  TriggerModal={TriggerModal} LoadInventaire={LoadInventaire} OldFamilles={OldFamilles} />
      </div>

      <Spinner isLoading={isLoading} />

    </>
  );
});

export default EditModalInventaire;
