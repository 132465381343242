import { useState, useRef, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  getDetailData,
  AddDetailData,
  updateDetailData,
  deleteDetailData,
} from "../../../../services/factures_ventes";

import { FormattNumber, isLogin } from "../../../../services/global";
import Spinner from "../../../../components/widgets/spinner/spinner";

import ComboProduit from "../../../Produits/Articles/ComboProduit";

const DetailFacture = forwardRef((props, ref) => {
  const EditModal = useRef();
  const Qte = useRef();
  const Prix = useRef();
  const Remise = useRef();

  const ComboProduitRef = useRef();

  const navigate = useNavigate();

  const [DataList, setDataList] = useState([]);
  const [FromErrors, setFromErrors] = useState({});

  const initialDetail = {
    IdDetailFacture: 0,
    CodeArticle: 0,
    codeFacture: props.IdFacture,
    designation: "",
    Quantite: 0,
    Description: "",
    Prix: 0,
    TTva: 20,
    Remise: 0,
    Total: 0,
    Sens: props.Sens,
    RefArticle: "",
  };

  let currentDetail = initialDetail;

  const [isLoading, setLoading] = useState(false);

  const getDetail = async () => {
    setLoading(true);
    const data = await getDetailData(props.IdFacture);
    setDataList(data);
    setLoading(false);
    props.setDetailPrint([...data]);
    const TotalFacture = data.reduce(
      (Total, row) => Total + row.Quantite * row.Prix * (1 - row.Remise / 100),
      0
    );
    props.setTotalFacture(TotalFacture);
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/factures");
    if (!isLogin()) {
      navigate("/login");
    }

    const getFistData = async () => {
      setLoading(true);
      const data = await getDetailData(props.IdFacture);
      setDataList(data);
      setLoading(false);
      props.setDetailPrint([...data]);
    };

    getFistData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.IdFacture, navigate]);

  const TriggerModal = (Detail = initialDetail) => {
    currentDetail = { ...Detail };
    //

    const Modal = EditModal.current;

    if (Modal.classList.contains("d-none")) {
      Qte.current.value = currentDetail.Quantite;
      Prix.current.value = currentDetail.Prix;
      Remise.current.value = currentDetail.Remise;

      ComboProduitRef.current.SetProdText(
        currentDetail.CodeArticle,
        currentDetail.designation,
        currentDetail.RefArticle,
        currentDetail.Prix
      );

      Modal.classList.remove("d-none");
    } else {
      Modal.classList.add("d-none");
      setFromErrors({});
    }
  };

  const VerifData = () => {
    //  console.log(currentDetail)
    let verif = {};
    if (currentDetail.CodeArticle === 0)
      verif = { ...verif, Article: "Erreur Article" };
    if (currentDetail.Quantite === "0" || currentDetail.Quantite === 0)
      verif = { ...verif, Quantite: "Erreur Quantite" };

    setFromErrors(verif);
    //   console.log(Object.keys(verif).length)
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    // console.log(currentDetail)
    if (currentDetail.IdDetailFacture === 0) {
      // console.log(currentDetail)
      AddDetailData(currentDetail)
        .then((message) => {
          TriggerModal();
          getDetail();
        })
        .catch((e) => alert("Erreur Modification"));
    } else {
      updateDetailData(currentDetail)
        .then((message) => {
          TriggerModal();
          getDetail();

          //  console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const deleteDetail = (IdDetailFacture) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Êtes vous sûr de vouloir supprimer ce produit ?`)) {
      deleteDetailData(IdDetailFacture)
        .then(async (message) => {
          getDetail();

          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  const updateProduct = (idArticle, DesignationProduct, PU) => {
    currentDetail.CodeArticle = idArticle;
    currentDetail.designation = DesignationProduct;
    currentDetail.Prix = PU;

    Prix.current.value = PU;
  };

  return (
    <>
      <button
        onClick={() => TriggerModal()}
        className="btn btn-success btn-sm position-absolute top-0 end-0 mx-2 "
        type="button"
      >
        +
      </button>

      <table className="table table-hover w-100 border">
        <tbody>
          {DataList.map((row, i) => (
            <tr key={i}>
              <td className="text-center border-end">{i + 1}</td>
              <td onDoubleClick={() => TriggerModal(row)}>
                <div className="row small">
                  <div className="col-12 col-md-5" styles="margin-bottom:10px;">
                    <b>{row.designation}</b> ( {row.RefArticle} )
                  </div>
                  <div className="col-5  col-md-2">
                    <b> Qté : </b>
                    {row.Quantite} <br />
                  </div>

                  <div
                    className="col-7  col-md-2 text-end"
                    styles="text-align:right"
                  >
                    <b> Prix : </b>
                    {FormattNumber(row.Prix - 0, 2)}
                  </div>
                  <div className="col-6  col-md-1">
                    <b> R% : </b>
                    {FormattNumber(row.Remise - 0, 0)}
                  </div>
                  <div className="col-6  col-md-2">
                    <b> TVA : </b>
                    {FormattNumber(row.TTva - 0, 0)}%
                  </div>
                </div>
              </td>
              <td className="border-start">
                <div className="row">
                  <div className="col-12  col-md-6" styles="text-align:right">
                    {FormattNumber(row.Total - 0, 2)}
                  </div>
                  <div className="col-12  col-md-6 t d-flex">
                    <button
                      className="btn bg-primary-1 btn-sm"
                      onClick={() => TriggerModal(row)}
                      name="edit"
                      type="button"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-warning btn-sm delete mx-2"
                      name="delete"
                      type="button"
                      onClick={() => deleteDetail(row.IdDetailFacture)}
                    >
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Spinner isLoading={isLoading} StyleClass="spinner-positionDetail" />

      {/* <!-- Button trigger modal --> */}

      {/* <!-- Modal --> */}
      <div
        ref={EditModal}
        onClick={(e) => {if (e.target === e.currentTarget) {ComboProduitRef.current.callBackBtn()}}}
        className=" bg-dark bg-opacity-25 modal-fullscreen w-100 d-none fixed-top d-flex align-items-center justify-content-center"
      >
        <div
          className="bg-white border rounded-3 position-relative shadow-lg"
          style={{ width: "500px", minHeight: "230px" }}
        >
          <div className="row border-bottom m-auto w-100 py-2">
            <div className="col-12">Edition</div>
          </div>
          <div className="row m-3 small">
            <ComboProduit updateProduct={updateProduct} ref={ComboProduitRef} />
            <span className="text-danger">{FromErrors.Article || ""}</span>
            <div className="col-4">
              <div className="form-group">
                <label>Quantité</label>
                <input
                  className="form-control"
                  type="number"
                  ref={Qte}
                  onChange={(e) => (currentDetail.Quantite = e.target.value)}
                />
              </div>
              <span className="text-danger">{FromErrors.Quantite || ""}</span>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Prix</label>
                <input
                  className="form-control"
                  type="number"
                  ref={Prix}
                  onChange={(e) => (currentDetail.Prix = e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Remise(%)</label>
                <input
                  className="form-control"
                  type="number"
                  ref={Remise}
                  onChange={(e) => (currentDetail.Remise = e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row position-absolute bottom-0 m-auto border-top w-100 py-2">
            <div className="col-12">
              <div className="form-group text-end">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={() => TriggerModal()}
                >
                  Fermer
                </button>
                <input
                  className="btn btn-success btn-sm mx-2"
                  type="button"
                  value="Enregistrer"
                  onClick={() => VerifData()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default DetailFacture;
