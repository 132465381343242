import { useEffect, useState,useRef, forwardRef,useImperativeHandle} from "react";

import {
  getClientListe,
  searchClient,  
} from "../../../services/clients";


import TableOne from "../../../components/widgets/tables/TableOne";
import EditClient from "./EditClient"; 

  const ComboClient = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
      SerachDataClt(v) {
        SerachData(v);
        setsearch(v);
      },

      NewClt() {
        EditClient_Ref.current.OpenEditData()
      }
      
    }));

  const [DataList, setDataList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [search, setsearch] = useState("");

  const EditClient_Ref = useRef();  

  const getData = () => {
    if (search === "") {
      setLoading(true);
      getClientListe()
        .then((data) => {
          setLoading(false);
          setDataList(data);
         
        })
        .catch(() => alert("Erreur Liste Client Data 1"));
    } else {
      SerachData(search);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/Clients");
   
    const getData = () => {
      setLoading(true);
      getClientListe()
        .then((data) => {
          setLoading(false);
          setDataList(data);
        })
        .catch(() => alert("Erreur Liste Client Data 2"));
    };
    getData();
    
  },[]);

  const SerachData = (searchValue) => {
    setLoading(true);

    searchClient(searchValue)
      .then((data) => {
        setLoading(false);
        setDataList(data);
        
      })
      .catch(() => alert("Erreur Liste Client Data 3"));
  };

 

  return (
    DataList && (
      <div
      className="vh-scroled-modal bg-light not-print border"
      // className="bg-dark bg-opacity-25 modal-fullscreen w-100 d-none fixed-top d-flex align-items-center justify-content-center"
      style={{
        position: "absolute",
        zIndex: "9999",
        top: "61px",
        left : (window.innerWidth<400 ? "0px":"156px"),
        maxWidth: "500px",
        width: "97%",
      }}
    >  

        <TableOne vh="">
         
          {DataList.map((row, i) => (
            <tr key={i} className="small">
              <td
                onDoubleClick={() =>
                  props.SelectClient(row.IdClient,row.Client)
                }
              >
                <div className="row align-items-center">
                  <div className="col-9">
                    {row.Client}
                  </div>

                  <div className="col-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-sm edit mx-1"
                      onClick={() =>
                        EditClient_Ref.current.OpenEditData(row)
                      }
                      name="edit"
                      type="button"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-info btn-sm edit text-white"
                      onClick={() =>
                        props.SelectClient(row.IdClient,row.Client)
                      }
                      name="edit"
                      type="button"
                    >
                      <i className="fa fa-check"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </TableOne>

        
        <EditClient ref={EditClient_Ref} getData={getData} />

        
      </div>
    )
  );
});

export default ComboClient;
