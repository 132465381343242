
import axios from "axios";
import { deconnect } from './global';

const baseApi="https://api.gstok.com"

export const AddDevis = async () => { 
    const data = await axios.post(`${baseApi}/VentesDevis`, {
        action: 'AddNew',
        token:sessionStorage.getItem("token"),        
    })

      console.log(data.data)
    if (data.data.LastID && data.status===200){return data.data.LastID}
    else {deconnect();}
    
};

export const getSingleDevis = async (IdDevis) => {    
    const data = await axios.post(`${baseApi}/VentesDevis`, {
        action: 'single',
        token:sessionStorage.getItem("token"),
        IdDevis:IdDevis,        
    });
     console.log(data.data)
     if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const getDevisListe = async (periode) => {
    sessionStorage.setItem('Debut',periode.debut);
    sessionStorage.setItem('Fin',periode.fin);
    const data = await axios.post(`${baseApi}/VentesDevis`, {
        action: 'fetchall',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,        
    });
    //   console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const searchDevis = async (seachValue,periode) => {              
    const data = await axios.post(`${baseApi}/VentesDevis`, {
        action: 'searchItem',
        token:sessionStorage.getItem("token"),
        Debut: periode.debut,
        Fin: periode.fin,
        search: seachValue
    })

    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};


export const deleteDevis = async (IdDevis) => { 
    const data = await axios.post(`${baseApi}/VentesDevis`, {
        action: 'delete',
        token:sessionStorage.getItem("token"),
        IdDevis: IdDevis,
    })

    // console.log(data)
    if (data.data.message && data.status===200){return data.data.message}
    else {deconnect();}
    
};

//Detail Devis

export const updateDevis = async (current) => { 
     console.log(current)
        const data = await axios.post(`${baseApi}/VentesDevis`, {
            action: 'updateDevis',
            token:sessionStorage.getItem("token"),        
            IdDevis: current.IdDevis,
            NumDevis: current.NumDevis,
            DateDevis: current.DateDevis,
            CodeClt: current.CodeClt,
            MontantTTC: current.MontantTTC,
            Mod_HT: current.Mod_HT,            
            Tva: current.Tva,
            BL: current.BL,
            Type: current.Type,            
            Description: current.Description,
            
            
        })
    
    //   console.log(data.data)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    }
    
    export const searchClients = async (seachValue) => {              
        const data = await axios.post(`${baseApi}/VentesClients`, {
            action: 'searchItem',
            token:sessionStorage.getItem("token"),
            search: seachValue
        })
    
        if (data.data && data.status===200){return data.data}
        else {deconnect();}
    };
    
    export const getDetailData = async (IdDevis) => {
        const data = await axios.post(`${baseApi}/VentesDevisDetail`, {
            action: 'fetchall',
            token:sessionStorage.getItem("token"),
            IdDevis: IdDevis,       
        });
        //  console.log(IdDevis)
        if (data.data && data.status===200){return data.data}
        else {deconnect();}
    };
    
    export const AddDetailData = async (Detail) => {          
        const data = await axios.post(`${baseApi}/VentesDevisDetail`, {
            action: 'insert',
            token:sessionStorage.getItem("token"),

            CodeDevis:Detail.codeDevis, 
            CodeArticle:Detail.CodeArticle,
            Quantite:Detail.Quantite,        
            Prix:Detail.Prix,
            TTva:Detail.TTva,
            Remise:Detail.Remise,
                  
        });
        //  console.log(data.data)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    };
    
    export const updateDetailData = async (Detail) => {
        const data = await axios.post(`${baseApi}/VentesDevisDetail`, {
            action: 'update',
            token:sessionStorage.getItem("token"),
            IdDetailDevis:Detail.IdDetailDevis,
            CodeDevis:Detail.CodeDevis, 
            CodeArticle:Detail.CodeArticle,
            Quantite:Detail.Quantite,        
            Prix:Detail.Prix,
            TTva:Detail.TTva,
            Remise:Detail.Remise,        
        });
        //  console.log(data.data.message)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    };
    
    export const deleteDetailData = async (IdDetailDevis) => {
        const data = await axios.post(`${baseApi}/VentesDevisDetail`, {
            action: 'delete',
            token:sessionStorage.getItem("token"),
            IdDetailDevis:IdDetailDevis,
                 
        });
        //  console.log(data.data.message)
        if (data.data && data.status===200){return data.data.message}
        else {deconnect();}
    };

    //devis to bon


export const devisToBon = async (numDevis,dateBon,IdDevis,CodeClt) => {
    const data = await axios.post(`${baseApi}/VentesBonsDetail`, {
      action: "devisToBon",
      token: sessionStorage.getItem("token"),      
      NumDevis:numDevis,
      DateBL :dateBon,
      IdDevis :IdDevis,
      CodeClt :CodeClt,
          
  
    });
    //  console.log(data.data.message)
    if (data.data && data.status === 200) {
      return data.data;
    } else {
     deconnect();
    }
  };

  export const getNumBon = async (IdBL) => {    
    const data = await axios.post(`${baseApi}/VentesBonsDetail`, {
        action: 'getNumBon',
        token:sessionStorage.getItem("token"),
        IdBL :IdBL ,        
    });
    //  console.log(data.data)
     if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

