import "./TopBar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToggelLeftSide, setWinSize } from "../../redux/slices/LayoutSlice";
import { SetCurrentUser } from "../../redux/slices/userSlice";

import { getUser } from "../../services/params";

import { useEffect } from "react";
import logo from "../../assets/LOGO-IG.png";

const TopBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation()

  let pageTitle = useSelector((state) => state.LayoutReducer.pageTitle);
  let winSize = useSelector((state) => state.LayoutReducer.winSize);
  const currentUser = useSelector((state) => state.userReducer.currentUser);

  const trogglLeft = () => {
    dispatch(ToggelLeftSide());
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      dispatch(setWinSize(window.innerWidth));
    };

    const getData = () => {
      getUser()
        .then((data) => {
          dispatch(SetCurrentUser(data));
        })
        .catch(() => alert("Erreur System user Data"));
    };

    if (location.pathname !== '/login') {
      getData();
    }

    window.addEventListener("resize", handleResize);


  }, [dispatch, location.pathname]);

  return (
    pageTitle !== "Login" && (
      <div
        id="TopBar"
        className="d-flex justify-content-between w-100 border-bottom px-2 align-items-center gap-1 bg-opacity-25 not-print"
        style={{ background: "var(--dark-2)" }}
      >
        <div className={winSize < 992 ? "d-flex justify-content-between  w-100" : ""}>
          {winSize < 992 ? (
            <>
              <button
                className="btn btn-white"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas-LettSide"
                aria-controls="offcanvas-LettSide"
              >
                <i className="fa fa-bars fa-lg h5 mx-1"></i>
              </button>
              <button className="btn btn-white">{pageTitle} </button>

              <button className="btn btn-white" onClick={() => navigate(-1)}>
                <i className="fa fa-arrow-left h5 mx-1"></i>
              </button>
            </>
          ) : (
            <div className="btn-group">
              <img
                src={logo}
                alt="Gesom logo"
                className="logo"
                style={{ width: "40px" }}
              />
              <button className="btn btn-white" onClick={trogglLeft}>
                <i className="fa fa-desktop fa-2x h5 m-1 mx-3 text-white"></i>
                <span className="infobulle btn bg-light border border-2 btn-sm">
                  Tableau de Borad
                </span>
              </button>

              <button className="btn btn-white" onClick={()=> navigate("/ventes/comptoir")}>
                <i className="fa fa-dashcube fa-2x h5 mx-2 text-white"></i>
                <span className="infobulle btn bg-light border border-2 btn-sm">
                  Comptoir
                </span>
              </button>
              <button className="btn btn-white" onClick={trogglLeft}>
                <i className="fa fa-bars fa-2x h5 mx-1 text-warning"></i>
              </button>


            </div>
          )}
        </div>

        {winSize >= 992 &&
          <>
            <button className="btn btn-white">{pageTitle} </button>
            <div className="btn-group">
              <button
                className="btn bg-dark-1 text-white border btn-sm"
                onClick={() => navigate(-1)}
              >
                <i className="fa fa-arrow-left fa-lg mx-1"></i>
              </button>
              <button
                className="btn btn-light  text-dark-2"
                onClick={() => window.print()}
              >
                <i className="fa fa-print"></i>
                <div className="infobulle btn bg-light border border-2 btn-sm">
                  Imprimer
                </div>
              </button>

              <button
                type="button"
                className="btn bg-dark-1 text-white border btn-sm"
                onClick={() => console.log(currentUser)}
              >
                <i className="fa fa-user me-2 "></i>
                <span className="small">{currentUser.userName}</span>
                <div className="infobulle btn bg-warning border border-2 btn-sm" style={{ left: '-20px' }}>{currentUser.email}
                </div>
              </button>
              <button className="btn btn-light btn-sm text-dark-2">
                <i
                  className="fa fa-arrows-alt fa-lg   mx-1"
                  onClick={() => toggleFullScreen()}
                ></i>
                <div className="infobulle btn bg-warning text-dark btn-sm">
                  Plein Ecran
                </div>
              </button>
              <button className="btn btn-light btn-sm text-dark">
                <i
                  className="fa fa-sign-out fa-lg  mx-1 "
                  onClick={() => navigate("/login")}
                ></i>
                <div className="infobulle btn bg-warning text-dark btn-sm">
                  Quitter
                </div>
              </button>
            </div>
          </>
        }
      </div>

    )
  );
};

export default TopBar;
