
import { useNavigate } from "react-router-dom";

const AccordionItem = ({title, isShow='', Id,content='',links='',isMobile=false,icon ="fa-arrow-right"}) => {
  
const collapsed = isShow==='show' ? '' : ' collapsed '
const darkText = isMobile ? ' text-dark ' : ''

 const linksArray = links.split('-');
 const navigate = useNavigate(); 

    return (
        <div className="accordion-item border-0 border-bottom" style={{ background: 'transparent' }}>
            <h2 className="accordion-header" id={'heading' + Id }>
            <button className={"accordion-button " + darkText + collapsed} type="button" data-bs-toggle="collapse" data-bs-target={'#collapse' + Id } aria-expanded="false" aria-controls={'collapse' + Id }>
            <i className={"fa " + icon +" me-2"} aria-hidden="true"></i> {title}
            </button>
            </h2>
            <div id={'collapse' + Id } className={"accordion-collapse collapse " + isShow} aria-labelledby={'heading' + Id } data-bs-parent="#accordion-LSide">
            <div className="accordion-body d-flex flex-column">
                
                {
                    isMobile ? (
                        content.split('-').map((lien,i) =>                        
                        <button className="btn btn-outline-dark btn-sm my-1 text-start w-75" 
                            key={i}
                            onClick={() => navigate('/' + linksArray[i])}  
                            data-bs-dismiss="offcanvas"                      
                            >{lien}                        
                        </button>
                    ))
                    :
                    (
                        content.split('-').map((lien,i) =>                        
                        <button className="btn btn-outline-light btn-sm my-1 text-start " 
                            key={i}
                            onClick={() => navigate('/' + linksArray[i])}                      
                            >{lien}                        
                        </button>
                    ))
                }
                </div>
            </div>
        </div>
    );
};

export default AccordionItem;