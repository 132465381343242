import AccordionItem from "../widgets/accordionItem";
import logo from "../../assets/responsive-w.png";
import { useSelector } from "react-redux";

const Menu = ({ isMobile = false }) => {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  return (
    <>
      <div className="accordion">
        <AccordionItem
          title="Produits"
          Id="1"
          isShow="show"
          links="produits/liste-produits/etatperiodique-produits/inventaires-produits/graphe"
          content="Produits & Services-Etat Periodique-Inventaires-Graphiques"
          icon="fa-product-hunt"
          isMobile={isMobile}
        />
        <AccordionItem
          title="Achats"
          Id="2"
          links="achats/fournisseurs-achats/commandes-achats/liste-achats/factures-achats/reglements"
          content="Fournisseurs-Commandes-Bon d'Achats-Factures Fournissurs-Réglements"
          icon="fa-truck"
          isMobile={isMobile}
        />
        <AccordionItem
          title="Ventes"
          Id="3"
          links="ventes/clients-ventes/devis-ventes/liste-ventes/factures-ventes/reglements"
          content="Clients-Devis-Bons de Livraisons-Factures Clients-Réglements"
          icon="fa-shopping-cart"
          isMobile={isMobile}
        />
        <AccordionItem
          title="Trésorie"
          Id="4"
          links="Page1-Page2-tresorie/caisse/journal-tresorie/caisse/suivi-tresorie/banques/operations-Page1"
          content="Comptoir-Tableau de Bord-Journal de Caisse-Suivi de Caisse-Banque Opérations-Porte Feuille"
          icon="fa-credit-card"
          isMobile={isMobile}
        />
        
        {currentUser.role && currentUser.role*1 < 2 && (
          <AccordionItem
            title="Paramètres"
            Id="5"
            links="params/users-params/users-params/users"
            content="Utilisateurs-Params 1-Params 2"
            icon="fa-cogs"
            isMobile={isMobile}
          />
        )}
      </div>
      {/* <div id="logo" className="bg-white my-5 rounded-pill d-flex justify-content-center align-items-center w-75 mx-auto  "> */}
      <div id="logo" className="my-5 text-center py-5 ">
        <img src={logo} alt="Gesom logo" className="logo w-50 opacity-75" />

        <button
          type="button"
          className="btn btn-outline-light position-relative btn-sm border-0 w-75"
        >
          <span className="opacity-75 small">Cloud APP</span>
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark bg-opacity-50 text-warning">
            24
          </span>
        </button>
      </div>
    </>
  );
};

export default Menu;
