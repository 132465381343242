import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  getBonLivraionListe,
  AddBonLivraion,
  getSingleBL,
  deleteBonLivraion,
} from "../../../services/ventes";

import { getProductListe } from "../../../services/produits";

import {
  isLogin,
  GetDate,
  FormattNumber,
  formatDate,
  reformatDate,
  SerachData,
} from "../../../services/global";

import Spinner from "../../../components/widgets/spinner/spinner";
import TableOne from "../../../components/widgets/tables/TableOne";
import EditReglementModal from "./EditReglementBL";
import EditBLModal from "./EditBLModal";
import PeriodeDateur from "../../../components/widgets/periode/periode";
import { getRoles } from "@testing-library/react";

const Comptoir = ({ pageRoles }) => {
  const ModalUpdate = useRef();
  const Qte = useRef();
  const Prix = useRef();
  const Remise = useRef();

  const [DataListProducts, setDataListProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");  
  const [currentDetail, setCurrentDetail] = useState([]);
  const [currentLigne, setcurrentLigne] = useState(-1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const getListProducts = () => {
  //   setLoading(true);
  //   getProductListe()
  //     .then((data) => {
  //       setLoading(false);
  //       setDataListProducts(data);

  //     })
  //     .catch(() => alert("test"));

  // };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/comptoir");
    if (!isLogin()) {
      navigate("/login");
    } else {
      const getListProducts = () => {
        setLoading(true);
        getProductListe()
          .then((data) => {
            setLoading(false);
            setDataListProducts(data);
          })
          .catch(() => alert("test"));
      };
      getListProducts();
      dispatch(SetPageTitle("Vente Comptoir"));
    }
  }, [dispatch, navigate]);

  const AddDetailBL = (Detail) => {
    if (
      currentDetail.find(
        ({ idArticle, PU }) =>
          // idArticle === Detail.idArticle && PU === Detail.PrixVTTC
          idArticle === Detail.idArticle
      )
    ) {
      let j = 0;
      const arr = [...currentDetail];
      arr.forEach((row) => {
        if (
          row.idArticle === Detail.idArticle &&
          j === 0
          // && // row.PU === Detail.PrixVTTC
        ) {
          row.Qte++;
          j++;
          setCurrentDetail(arr);
        }
      });
    } else {
      setCurrentDetail([
        ...currentDetail,
        {
          idArticle: Detail.idArticle,
          Designation: Detail.Designation,
          RefArticle: Detail.RefArticle,
          DesignationFamille: Detail.DesignationFamille,
          Qte: 1,
          PU: Detail.PrixVTTC,
          Remise: 0,
        },
      ]);
    }
  };

  const TriggerModal = (Detail = null, i = -1) => {
    const Modal = ModalUpdate.current;
    setcurrentLigne(i);
    if (Modal.classList.contains("d-none") && i !== -1) {
      Qte.current.value = Detail.Qte;
      Prix.current.value = Detail.PU;
      Remise.current.value = Detail.Remise;
      Modal.classList.remove("d-none");
    } else {
      Modal.classList.add("d-none");
    }
  };

  const updateDetail = () => {
    try {
      const arr = [...currentDetail];
      arr[currentLigne].Qte = Qte.current.value;
      arr[currentLigne].PU = Prix.current.value;
      arr[currentLigne].Remise = Remise.current.value;
      setCurrentDetail(arr);
      TriggerModal();
    } catch {
      console.log("Erreur updateDetail");
    }
  };

  const RemoveDetailBL = (i) => {
    const arr = [...currentDetail];
    arr.splice(i, 1);

    setCurrentDetail(arr);
  };

  const CalculTotal = () => {
    const t = currentDetail.reduce(
      (accumulator, row) => accumulator + row.Qte,
      0
    );

    console.log(t);
  };

  return (
    <>
      <div className="d-flex justify-content-between gap-2">
        <section className="w-50">
          {isLogin() && DataListProducts && (
            <>
              <div className="row mb-1 not-print">
                <div className="col-12 mb-2 d-flex gap-1">
                  <div className="input-group">
                    <input
                      className="form-control"
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Réference | Famille | Désignation"
                    />
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <button
                    className="btn btn-light  text-success text-nowrap"
                    // onClick={() => onDownload()}
                  >
                    Service <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>

              <TableOne>
                {DataListProducts.map(
                  (row, i) =>
                    "A" === row.Etat &&
                    "A" === row.Type &&
                    SerachData(
                      row.Designation + row.DesignationFamille + row.RefArticle,
                      searchText
                    ) && (
                      <tr key={i}>
                        <td>
                          <div className="d-flex gap-4 justify-content-between">
                            <div className="row w-100">
                              <div className="col-12">
                                <span>
                                  {row.Designation} | <b>{row.RefArticle}</b> |{" "}
                                  {row.DesignationFamille}
                                </span>
                              </div>

                              <div className="col-12 d-flex justify-content-between">
                                <span>
                                  PU : <b>{row.PrixVTTC}</b>
                                </span>
                                <span>
                                  <b
                                    className={
                                      row.Stock * 1 <= 0
                                        ? "text-danger"
                                        : "text-primary"
                                    }
                                  >
                                    {row.Stock}
                                  </b>{" "}
                                  unit
                                </span>
                              </div>
                            </div>
                            <button
                              className="btn btn-light  text-success text-nowrap"
                              onClick={() => AddDetailBL(row)}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                )}
              </TableOne>
            </>
          )}
        </section>
        <section className="w-50 mt-1">
          <div className="row my-1 not-print p-0 ">
            <div className="col-12 mb-2 d-flex gap-1 justify-content-between align-items-center">
              <button
                className="btn btn-danger btn-sm"
                // onClick={() => onDownload()}
              >
                <i className="fa fa-minus"></i>
              </button>
              <button
                className="btn btn-success btn-sm"
                // onClick={() => onDownload()}
              >
                Réglement
              </button>
              <span
                className="flex-fill btn btn-light btn-sm"
                // onClick={() => onDownload()}
              >
                <b>Total : {FormattNumber(
                  currentDetail.reduce(
                    (accumulator, row) =>
                      accumulator +
                      row.PU * row.Qte * (1 - (row.Remise * 1) / 100),
                    0
                  ) * 1,
                  2
                )}</b>
              </span>
              <button
                className="btn btn-warning btn-sm"
                onClick={() => setCurrentDetail([])}
              >
                Annuler
              </button>

              <button
                className="btn btn-primary btn-sm"
                // onClick={() => onDownload()}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <div className="row  not-print m-0 bg-dark p-0 pe-4 text-white text-center">
            <div className="col-6 ">Désignation</div>
            <div className="col-2 ">Qté</div>
            <div className="col-2">PU</div>
            <div className="col-2">R%</div>
          </div>

          <TableOne vh="vh-scroled-detail">
            {currentDetail.map((row, i) => (
              <tr key={i}>
                <td onDoubleClick={() => TriggerModal(row, i)}>
                  <div className="row w-100">
                    <div className="col-6">
                      {row.Designation}
                    </div>
                    <div className="col-2 text-center" >
                      <b>{row.Qte}</b>
                    </div>
                    <div className="col-2 text-center">
                      <b>{row.PU}</b>
                    </div>
                    <div className="col-2 text-center">{row.Remise}%</div>
                  </div>
                  <div className="row w-100 align-items-center mb-1">
                    <div className="col-6">
                      <span>
                        <b>{row.RefArticle}</b> | {row.DesignationFamille}
                      </span>
                    </div>
                    <div className="col-4 text-end text-primary">
                      <b>
                        Total ={" "}
                        {FormattNumber(
                          row.PU * row.Qte * (1 - (row.Remise * 1) / 100),
                          2
                        )}
                      </b>
                    </div>

                    <div className="col-2 text-end">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => RemoveDetailBL(i)}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </TableOne>
        </section>
      </div>
      <Spinner isLoading={isLoading} />

      {/* <!-- Modal --> */}
      <div
        ref={ModalUpdate}
        className=" bg-dark bg-opacity-25 modal-fullscreen w-100 d-none fixed-top d-flex align-items-center justify-content-center"
      >
        <div
          className="bg-white border rounded-3 position-relative shadow-lg"
          style={{ width: "500px", minHeight: "230px" }}
        >
          <div className="row border-bottom m-auto w-100 py-2">
            <div className="col-12">Edition</div>
          </div>
          <div className="row m-3 small">
            <div className="col-4">
              <div className="form-group">
                <label>Quantité</label>
                <input className="form-control" type="number" ref={Qte} />
              </div>
              {/* <span className="text-danger">{FromErrors.Quantite || ""}</span> */}
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Prix</label>
                <input className="form-control" type="number" ref={Prix} />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Remise(%)</label>
                <input className="form-control" type="number" ref={Remise} />
              </div>
            </div>
          </div>

          <div className="row position-absolute bottom-0 m-auto border-top w-100 py-2">
            <div className="col-12">
              <div className="form-group text-end">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={() => TriggerModal()}
                >
                  Fermer
                </button>
                <input
                  className="btn btn-success btn-sm mx-2"
                  type="button"
                  value="Enregistrer"
                  onClick={() => updateDetail()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Comptoir;
