const TableOne = ({vh="vh-scroled",children}) => { 
    return (
        <div className={"border  not-print " + vh }>       
            <table className="table table-hover w-100 ">
                <tbody>
                    {children}
                </tbody>
            </table>
        </div> 
    );
};

export default TableOne;