import { createSlice } from "@reduxjs/toolkit"

const initialState={
  currentUser :{
    IdUser:0,
    userName:'',
    role:2
  }
}

const userSlice= createSlice({
    name:'userSlice',
    initialState,
    reducers:{      
      
      SetCurrentUser(state, action) {
        state.currentUser = action.payload;       
      },
      
    },

    extraReducers: (builder) => {      
      
        
      
    },
      
    
})

export const {  SetCurrentUser } = userSlice.actions;
export default userSlice.reducer