import {
  useState,
  useEffect,  
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import {
  getCaisseCompte,
  UpdateCaisseOperation,
  AddCaisseOperation,
} from "../../../services/tresorie";


const EditOperation = forwardRef((props, ref) => {

  const initialDetail = {
    idOp: 0,
    idCaisse: "0",
    DateOp: new Date().toJSON().slice(0, 10),
    Montant: 0,
    sens: 1,
    Commentaire: "",
    Compte: 0,
  };

  
  const idCaisseRef = useRef();
  const DateOpRef = useRef();
  const MontantRef = useRef();
  const sensRef = useRef();
  const CommentaireRef = useRef();

  const [CompteList, setCompteList] = useState([]);

  const [currentDetail, setcurrentDetail] = useState({});
  const [switchSens, setswitchSens] = useState(1);

  const getDataCompteListe = async () => {
    const data = await getCaisseCompte();
    setCompteList(data);
  };

  useEffect(() => {
    getDataCompteListe()
}, []);

  useImperativeHandle(ref, () => ({
    async OpenModal(Detail = initialDetail) {
      setcurrentDetail(Detail);
      //   idOpRef.current.value = currentDetail.idOp;
      idCaisseRef.current.value = Detail.idCaisse;
      DateOpRef.current.value = Detail.DateOp;
      MontantRef.current.value = Detail.Montant;
      // console.log(Detail.sens)
      sensRef.current.value = Detail.sens*1;
      setswitchSens(Detail.sens*1)
      CommentaireRef.current.value = Detail.Commentaire;
      //CompteCaisseRef.current.value = currentDetail.Compte;
    },
  }));

  const VerifData = () => {
    // let verif = {};
    // if(currentDetail.Montant ===0) verif = {...verif,Montant:'Erreur Montant'}

    // setFromErrors(verif);
    // if(Object.keys(verif).length === 0){ SaveData()}

    SaveData();
  };
  const SaveData = () => {
    if (currentDetail.idOp === 0) {
      AddCaisseOperation(currentDetail).then(
        async (data) => {
            props.getData(currentDetail.DateOp);
            currentDetail.idOp = data.lastId;
            console.log(data.message);
        }
      );
    } else {
      UpdateCaisseOperation(currentDetail).then((message) => {props.getData(currentDetail.DateOp)});
    }
    //
  };

  return (
    <div
      className="bg-white border rounded-3 position-relative shadow-lg"
      style={{ width: "500px", minHeight: "330px" }}
    >
      <div className="row border-bottom m-auto w-100 py-2">
        <div className="col-12">Edition</div>
      </div>
      <div className="row m-3 small">
        <div className="col-6">
          <div className="form-group">
            <label>Date Caisse</label>
            <input
              className="form-control"
              type="date"
              ref={DateOpRef}
              onChange={(e) => (currentDetail.DateOp = e.target.value)}
            />
          </div>
        </div>        
        <div className="col-6 d-flex align-items-end justify-content-end">
          <div className="form-check form-switch ">
            <input
              className="form-check-input "
              id="switchSens"
              type="checkbox"
              checked={switchSens===1}
              ref={sensRef}
              onChange={() => {
                setswitchSens(switchSens===1? -1:1);                
                currentDetail.sens = switchSens===1? -1:1 ;
              }}
            />
            <label
              className="form-check-label fw-bold d-none d-md-inline"
              htmlFor="switchSens"
            >
              {switchSens === 1 ? (
                        <span>+ Enc</span>
                      ) : (
                        <span className="text-danger">- Déc  </span>
                      )}
            </label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>Montant</label>
            <input
              className="form-control"
              type="number"
              ref={MontantRef}
              onChange={(e) => (currentDetail.Montant = e.target.value)}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>Compte</label>
            <select
              ref={idCaisseRef}
              className="form-select"
              onChange={(e) => (currentDetail.idCaisse = e.target.value)}
            >
              {/* <option defaultValue="0">Compte Caisses</option> */}
              {CompteList.map((row, i) => (
                row.Type==='A' &&
                <option key={i} value={row.IdComptCaisse}>
                  {row.Compte}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>Commentaire</label>
            <input
              className="form-control"
              type="text"
              ref={CommentaireRef}
              onChange={(e) => (currentDetail.Commentaire = e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="row position-absolute bottom-0 m-auto border-top w-100 py-2">
        <div className="col-12">
          <div className="form-group text-end">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => props.TriggerModal()}
            >
              Fermer
            </button>
            <input
              className="btn btn-primary btn-sm mx-2"
              type="button"
              value="Enregistrer"
              onClick={() => VerifData()}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditOperation;
