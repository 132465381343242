
import axios from "axios";
import { deconnect } from './global';

const baseApi="https://api.gstok.com";


export const getUsersList = async () => {   

    const data = await axios.post(`${baseApi}/Users`, {
        action: 'getUsersList',
        token:sessionStorage.getItem("token"),        
        
    })
    // console.log(data.data)
    if (data.data && data.status===200){return data.data}
    else {deconnect();}
};

export const AddUser = async (current) => {
    const data = await axios.post(`${baseApi}/Users`, {
      action: "AddUser",
      token: sessionStorage.getItem("token"),
      userName: current.userName,  
      email : current.email ,
      password: current.password,
          
    });
    if (data.data && data.status === 200) {
      return data.data;
    } else {
      deconnect();
    }
  };

  export const UpdateUser = async (current) => {
    // console.log(current)
    const data = await axios.post(`${baseApi}/Users`, {
      action: "UpdateUser",
      token: sessionStorage.getItem("token"),
      IdUser:current.IdUser,
      userName: current.userName,  
      email : current.email ,
      password: current.password,
          
    });
    if (data.data && data.status === 200) {
      return data.data;
    } else {
      deconnect();
    }
  };

  export const UpdateProduitsRoles = async (current) => {
    // console.log(current)
    const data = await axios.post(`${baseApi}/Users`, {
      action: "UpdateProduitsRoles",
      token: sessionStorage.getItem("token"),
      IdUser:current.IdUser,

      prodList:current.prodList,
      prodPeriodique:current.prodPeriodique,
      prodInvenatire:current.prodInvenatire,
      prodGraphique:current.prodGraphique,
          
    });
    if (data.data && data.status === 200) {
      return data.data;
    } else {
      deconnect();
    }
  };

  export const UpdateAchatsRoles = async (current) => {
    // console.log(current)
    const data = await axios.post(`${baseApi}/Users`, {
      action: "UpdateAchatsRoles",
      token: sessionStorage.getItem("token"),
      IdUser:current.IdUser,

      AchatFournisseur:current.AchatFournisseur,
      AchatCommande:current.AchatCommande,
      AchatsBon:current.AchatsBon,
      AchatFacture:current.AchatFacture,
      AchatReglement:current.AchatReglement,
          
    });
    if (data.data && data.status === 200) {
      return data.data;
    } else {
      deconnect();
    }
  };

  export const UpdateVentesRoles = async (current) => {
    // console.log(current)
    const data = await axios.post(`${baseApi}/Users`, {
      action: "UpdateVentesRoles",
      token: sessionStorage.getItem("token"),
      IdUser:current.IdUser,

      VenteClient:current.VenteClient,
      VenteDevis:current.VenteDevis,
      VenteBon:current.VenteBon,
      VenteFacture:current.VenteFacture,
      VenteReglement:current.VenteReglement,
      VenteComptoir:current.VenteComptoir,

          
    });
    if (data.data && data.status === 200) {
      return data.data;
    } else {
      deconnect();
    }
  };

  export const UpdateTresorieRoles = async (current) => {
    // console.log(current)
    const data = await axios.post(`${baseApi}/Users`, {
      action: "UpdateTresorieRoles",
      token: sessionStorage.getItem("token"),
      IdUser:current.IdUser,

      prodList:current.prodList,
      prodPeriodique:current.prodPeriodique,
      prodInvenatire:current.prodInvenatire,
      prodGraphique:current.prodGraphique,
          
    });
    if (data.data && data.status === 200) {
      return data.data;
    } else {
      deconnect();
    }
  };

  export const getUser = async () => {   

    const data = await axios.post(`${baseApi}/Users`, {
        action: 'getUser',
        token:sessionStorage.getItem("token"),        
        
    })
    // console.log(data.data)
    if (data.data && data.status===200){return data.data[0]}
    else {deconnect();}
  };

  export const getSingleUser = async (currentUser) => {   

    const data = await axios.post(`${baseApi}/Users`, {
        action: 'getSingleUser',
        currentUser:currentUser,
        token:sessionStorage.getItem("token"),        
        
    })
    // console.log(data.data)
    if (data.data && data.status===200){return data.data[0]}
    else {deconnect();}
  };
