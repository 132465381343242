import { React,useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import LSide from './components/layouts/LSide';
import RSide from './components/layouts/RSide';
import LeftSideOffcanvas from './components/layouts/leftSideOffcanvas';
import TopBar from './components/layouts/TopBar';
import Login from './pages/Login';

import { getUser} from "./services/params";

import { useSelector,useDispatch } from "react-redux";
import { SetCurrentUser } from "./redux/slices/userSlice";

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);

  useEffect(() => {
    if(window.location.pathname !== '/login'){
      getUser().then((data) => {
        dispatch(SetCurrentUser(data));
      })
      .catch(() => alert("Erreur System user Data"));
    }
  
    
},[dispatch]);


  return (
     window.location.pathname !== '/login' ?
      <BrowserRouter>
        {
          currentUser && currentUser.userName ?
            <>
              <TopBar />
              <LeftSideOffcanvas />
              <main className='d-flex'>
                <LSide />
                <RSide />
              </main>
            </>
            :
            <Login />
        }
      </BrowserRouter>
      :
      <BrowserRouter>

        <Login />

      </BrowserRouter>
  );
};
export default App;
