import './style.css'

const Spinner = ({ isLoading = false, StyleClass = 'spinner-position' }) => {
    return (
        <>
            {
                isLoading && (
                    <div className={StyleClass} style={{ zIndex: '9999' }}>
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                )
            }
        </>
    );
};

export default Spinner;