import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import {
  searchFamilles,
  insertFamilles,
  updateArticle,
  AddArticle,
} from "../../../services/produits";

import ExtraForm from "../../../components/ExtraChamp/ExtraForm";

const EditProduct = forwardRef((props, ref) => {
  
  const FormContainer = useRef();
  const FamilleText = useRef();

  const [DataFamille, setDataFamille] = useState([]);
  const [FromErrors, setFromErrors] = useState({});
  const [ShowFamille, setShowFamille] = useState(false);
  const [currentArticle, setCurrentArticle] = useState({});

  const [ShowDetail, setShowDetail] = useState(false);
  const [ShowAddFamille, setShowAddFamille] = useState(false);
  const [NewFamille, setNewFamille] = useState("");
  const [SwitchEtat, SetSwitchEtat] = useState("A");

  const initialArticle = {
    idArticle: "",
    FamilleRef: "",
    RefArticle: "",
    Designation: "",
    Type: "A",
    PrixVTTC: "0.00",
    PrixATTC: "0.00",
    TTva: "20.00",
    Stock: "0",
    StFact: "0",
    StMin: "0",
    Etat: "A",
    DesignationFamille: "",
  };

  useImperativeHandle(ref, () => ({
    getShowDetail(){
      return ShowDetail;
    },

    setShowDetail(v){
      setShowDetail(v);
    },

    async OpenEditData(Article = initialArticle) {
      setShowDetail(true);
      setShowAddFamille(false);
      setShowFamille(false);

      setCurrentArticle(Article);

      // RefArticle.current.value = Article.RefArticle;
      const form = FormContainer.current;
      form.RefArticle.value = Article.RefArticle;
      form.Designation.value = Article.Designation;
      form.PrixATTC.value = Article.PrixATTC;
      form.PrixVTTC.value = Article.PrixVTTC;
      FamilleText.current.value = Article.DesignationFamille;

      setDataFamille(await searchFamilles());
      SetSwitchEtat(Article.Etat);
      setFromErrors({});
      //  console.log(currentArticle.RefArticle)
    },
  }));

  const VerifData = () => {
    let verif = {};

    if (currentArticle.RefArticle === "")
      verif = { ...verif, RefArticle: "Erreur Réference" };
    if (currentArticle.Designation === "")
      verif = { ...verif, Designation: "Erreur Désignation" };
    if (currentArticle.FamilleRef === "")
      verif = { ...verif, FamilleRef: "Erreur Famille" };
    if (currentArticle.PrixATTC === "")
      verif = { ...verif, PrixATTC: "Erreur Prix Achat" };
    if (currentArticle.PrixVTTC === "")
      verif = { ...verif, PrixVTTC: "Erreur Prix de Vente" };

    setFromErrors(verif);
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    if (currentArticle.idArticle === "") {
      AddArticle(currentArticle).then((data) => {
        props.getData();
        currentArticle.idArticle = data.lastID;

        console.log(data.message);
      });
    } else {
      updateArticle(currentArticle)
        .then((message) => {
          props.getData();

          console.log(message);
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const SelectFamille = (idFamille, DesignationFamille) => {
    setShowFamille(false);
    currentArticle.FamilleRef = idFamille;
    currentArticle.DesignationFamille = DesignationFamille;
    FamilleText.current.value = DesignationFamille;
  };

  const SetCurrentEtat = () => {
    currentArticle.Etat = currentArticle.Etat === "A" ? "S" : "A";
    SetSwitchEtat(currentArticle.Etat);
  };

  const GetCodeBarre = () => {
    const tb_controle = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
    const codebase =
      "00" +
      Math.floor(Date.now() / 1000)
        .toString()
        .substring(0, 10);
    let controle = 0;

    for (let i = 0; i < 12; i++) {
      let chiffre_c = parseInt(codebase.substring(i, i + 1));
      controle += chiffre_c * tb_controle[i];
    }

    controle = controle % 10;
    controle = controle === 0 ? 0 : 10 - controle;

    // eslint-disable-next-line no-restricted-globals
    if (confirm("Attention Nouveau Code Barre : " + codebase + controle)) {
      FormContainer.current.getElementsByTagName("form")[0].RefArticle.value =
        codebase + controle;
      currentArticle.RefArticle = codebase + controle;
    }
  };

  const Newbtn = () => {
    setCurrentArticle(initialArticle);
    const form = FormContainer.current;
    form.RefArticle.value = "";
    form.Designation.value = initialArticle.Designation;
    form.PrixATTC.value = initialArticle.PrixATTC;
    form.PrixVTTC.value = initialArticle.PrixVTTC;
    FamilleText.current.value = initialArticle.DesignationFamille;
    SetSwitchEtat("A");
  };

  return (
    
    <div className={ShowDetail ? " rounded-2 border bg-white p-3":"d-none"} style={{position:'absolute',zIndex:'999',left:'0px',top:'-91px'}}>          
        
          
            <div className="d-flex py-2 border-bottom">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  id="SwitchEtat"
                  type="checkbox"
                  checked={SwitchEtat === "A"}
                  onChange={() => SetCurrentEtat()}
                />
                <label
                  className="form-check-label fw-bold "
                  htmlFor="SwitchEtat"
                >
                  {SwitchEtat === "A" ? (
                    <span>Active</span>
                  ) : (
                    <span className="text-secondary">Archive</span>
                  )}
                </label>
              </div>
              <h5 className="EditData-title mx-5 h6 d-none d-md-inline w-100 ">
                Edition Produit
              </h5>
              {currentArticle.idArticle && (
                <div className="w-100 mx-2">
                  <ExtraForm
                    Tble={"Articles"}
                    idOrigine={currentArticle.idArticle}
                  />
                </div>
              )}

              <button
                type="button"
                className="btn-close"               
                aria-label="Close"
                onClick={() => setShowDetail(false)}
              ></button>
            </div>
            <div >
              
                <form ref={FormContainer} className="my-2">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="mb-1">Réference </label>
                        <div className="d-flex gap-2">
                          <input
                            id="RefArticle"
                            className="form-control"
                            type="text"
                            onChange={(e) =>
                              (currentArticle.RefArticle = e.target.value)
                            }
                          />
                          <input
                            className="btn btn-light btn-sm form-control"
                            style={{ maxWidth: 40 }}
                            type="button"
                            value="C"
                            click="GetCodeBarre"
                            readOnly
                            onClick={() => GetCodeBarre()}
                          />
                        </div>
                        <span className="text-danger">
                          {FromErrors.RefArticle}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Désignation</label>
                        <input
                          id="Designation"
                          className="form-control"
                          type="text"
                          onChange={(e) =>
                            (currentArticle.Designation = e.target.value)
                          }
                        />
                      </div>
                      <span className="text-danger">
                        {FromErrors.Designation || ""}
                      </span>
                    </div>
                    <div className="col-12 my-1 d-flex justify-content-end gap-1">
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setShowAddFamille(!ShowAddFamille)}
                        type="button"
                      >
                        Familles <i className="fa fa-plus mx-1"></i>
                      </button>

                      {ShowAddFamille && (
                        <>
                          <div className="form-group flex-grow-1">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => setNewFamille(e.target.value)}
                            />
                          </div>
                          <button
                            className="btn btn-success btn-xs"
                            type="button"
                            onClick={async () => {
                              const data = await insertFamilles(NewFamille);
                              setShowAddFamille(false);
                              setNewFamille("");
                              SelectFamille(data.Ref, NewFamille);
                            }}
                          >
                            +
                          </button>
                        </>
                      )}
                    </div>

                    <div className="col-12 my-1">
                      <div className="form-group">
                        <span className="text-danger">
                          {FromErrors.FamilleRef || ""}
                        </span>
                        <div className="d-flex gap-1">
                          <input
                            className="form-control"
                            ref={FamilleText}
                            // onBlur={() => setShowFamille(false)}
                            onFocus={() => setShowFamille(true)}
                            placeholder="Selectionner Famille"
                            onChange={async (e) =>
                              setDataFamille(
                                await searchFamilles(e.target.value)
                              )
                            }
                          />

                          {ShowFamille && (
                            <button
                              className="btn btn-light btn-xs"
                              type="button"
                              onClick={() => {
                                setShowFamille(false);
                                SelectFamille(
                                  currentArticle.FamilleRef,
                                  currentArticle.DesignationFamille
                                );
                              }}
                            >
                              <i className="fa fa-arrow-left"></i>
                            </button>
                          )}
                        </div>
                        {ShowFamille && (
                          <div className="vh-scroled-modal">
                            <table className="table bg-light">
                              <tbody className="border ">
                                {DataFamille.map((row, i) => (
                                  <tr key={i}>
                                    <td
                                      calss="text-center"
                                      data-style="width:50px;"
                                    >
                                      {i + 1}
                                    </td>
                                    <td data-style="width:100%;">
                                      {row.Designation}
                                    </td>
                                    <td
                                      className="text-end"
                                      data-style="width:50px;"
                                    >
                                      <button
                                        className="btn btn-secondary btn-sm"
                                        type="button"
                                        onClick={() =>
                                          SelectFamille(
                                            row.Ref,
                                            row.Designation
                                          )
                                        }
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Prix d'Achat</label>
                        <input
                          className="form-control"
                          type="number"
                          id="PrixATTC"
                          onChange={(e) =>
                            (currentArticle.PrixATTC = e.target.value)
                          }
                        />
                      </div>
                      <span className="text-danger">
                        {FromErrors.PrixATTC || ""}
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Prix de Vente</label>
                        <input
                          className="form-control"
                          type="number"
                          id="PrixVTTC"
                          onChange={(e) =>
                            (currentArticle.PrixVTTC = e.target.value)
                          }
                        />
                      </div>
                      <span className="text-danger">
                        {FromErrors.PrixVTTC || ""}
                      </span>
                    </div>
                  </div>
                </form>
              
            </div>
            <div className="d-flex justify-content-between" style={{maxHeight:'30px'}}>
              <button
                onClick={() => {
                  Newbtn();
                }}
                type="button"
                className="btn btn-outline-success btn-sm p-1"
              >
                Nouveau
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary mx-1 btn-sm"
                  onClick={() => setShowDetail(false)}                 
                >
                  Fermer
                </button>
                <button
                  onClick={() => VerifData()}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          
        
      </div>
    
  );
});

export default EditProduct;
