import axios from "axios";
import { deconnect } from "./global";
const baseApi = "https://api.gstok.com";

export const getFournisseurListe = async () => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getSingleFournisseur = async (IdFournisseur) => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "fetchSingle",
    token: sessionStorage.getItem("token"),
    IdFournisseur: IdFournisseur,
  });
   //console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const searchFournisseur = async (seachValue) => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
    search: seachValue,
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const updateFournisseur = async (current) => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "update",
    token: sessionStorage.getItem("token"),
    ICE: current.ICE,
    Fournisseur: current.Fournisseur,
    IdFournisseur: current.IdFournisseur,
    Adresse: current.Adresse,
    Tel: current.Tel,
    Solde: current.Solde,
    Mobile: current.Mobile,
  });

  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const AddFournisseur = async (current) => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "insert",
    token: sessionStorage.getItem("token"),
    ICE: current.ICE,
    Fournisseur: current.Fournisseur,
    Mobile: current.Mobile,
    Adresse: current.Adresse,
    Tel: current.Tel,
    Solde: current.Solde,
  });
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const delelteFournisseur = async (current) => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "delete",
    token: sessionStorage.getItem("token"),
    IdFournisseur: current.IdFournisseur,
    user: 0,
  });

  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

// Mouvement
export const getDataMouvement = async (periode, IdFournisseur) => {
  sessionStorage.setItem("Debut", periode.debut);
  sessionStorage.setItem("Fin", periode.fin);
  const Solde = await axios.post(`${baseApi}/FournisseurMouvement`, {
    action: "getSolde",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
    CurrentId: IdFournisseur,
  });

  // console.log(Solde.data)
  let reportDebut = 0;
  if (Solde.data && Solde.status === 200) {
    reportDebut = Solde.data[0].reportDebut;
  } else {
    deconnect();
    return;
  }

  const data = await axios.post(`${baseApi}/FournisseurMouvement`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
    CurrentId: IdFournisseur,
  });

  let dataMouvement = data.data;

  if (dataMouvement.length > 0) {
    dataMouvement[0].report = reportDebut * 1;
    for (let i = 1; i < dataMouvement.length; i++) {
      dataMouvement[i].report =
        dataMouvement[i - 1].report +
        dataMouvement[i - 1].Montant * dataMouvement[i - 1].sens;
    }
  }

  return dataMouvement;
};
