import axios from "axios";
import { deconnect } from "./global";

const baseApi = "https://api.gstok.com";

export const AddFacture = async (Avoir) => {
  const data = await axios.post(`${baseApi}/AchatsFactures`, {
    action: "AddNew",
    token: sessionStorage.getItem("token"),
    Avoir: Avoir,
  });

  //   console.log(data.data)
  if (data.data.LastID && data.status === 200) {
    return data.data.LastID;
  } else {
    deconnect();
  }
};

export const getSingleFacture = async (IdFacture) => {
  const data = await axios.post(`${baseApi}/AchatsFactures`, {
    action: "single",
    token: sessionStorage.getItem("token"),
    IdFacture: IdFacture,
  });
  //  console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getFactureListe = async (periode) => {
  sessionStorage.setItem("Debut", periode.debut);
  sessionStorage.setItem("Fin", periode.fin);
  const data = await axios.post(`${baseApi}/AchatsFactures`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
  });
  //  console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const searchFacture = async (seachValue, periode) => {
  const data = await axios.post(`${baseApi}/AchatsFactures`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
    Debut: periode.debut,
    Fin: periode.fin,
    search: seachValue,
  });

  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const deleteFacture = async (IdFacture) => {
  const data = await axios.post(`${baseApi}/AchatsFactures`, {
    action: "delete",
    token: sessionStorage.getItem("token"),
    IdFacture: IdFacture,
  });

  // console.log(data)
  if (data.data.message && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

//Detail Facture

export const updateFacture = async (current) => {
  console.log(current);
  const data = await axios.post(`${baseApi}/AchatsFactures`, {
    action: "updateFacture",
    token: sessionStorage.getItem("token"),
    IdFacture: current.IdFacture,
    NumFacture: current.NumFacture,
    DateFacture: current.DateFacture,
    CodeFour: current.CodeFour,
    MontantTTC: current.MontantTTC,
    Mod_HT: current.Mod_HT,
    Tva: current.Tva,
    Avoir: current.Avoir,
    Description: current.Description,
  });

  //    console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const searchFournisseurs = async (seachValue) => {
  const data = await axios.post(`${baseApi}/Fournisseurs`, {
    action: "searchItem",
    token: sessionStorage.getItem("token"),
    search: seachValue,
  });

  // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const getDetailData = async (IdFacture) => {
  // console.log(IdFacture)
  const data = await axios.post(`${baseApi}/AchatsFacturesDetail`, {
    action: "fetchall",
    token: sessionStorage.getItem("token"),
    IdFacture: IdFacture,
  });
  //  console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data;
  } else {
    deconnect();
  }
};

export const AddDetailData = async (Detail) => {
    // console.log(Detail)
  const data = await axios.post(`${baseApi}/AchatsFacturesDetail`, {
    action: "insert",
    token: sessionStorage.getItem("token"),

    CodeFacture: Detail.codeFacture,
    CodeArticle: Detail.CodeArticle,
    Quantite: Detail.Quantite,
    Prix: Detail.Prix,
    TTva: Detail.TTva,
    Remise: Detail.Remise,
    Sens: Detail.Sens,
  });
    // console.log(data.data)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const updateDetailData = async (Detail) => {
  const data = await axios.post(`${baseApi}/AchatsFacturesDetail`, {
    action: "update",
    token: sessionStorage.getItem("token"),
    IdDetailFacture: Detail.IdDetailFacture,
    CodeFacture: Detail.CodeFacture,
    CodeArticle: Detail.CodeArticle,
    Quantite: Detail.Quantite,
    Prix: Detail.Prix,
    TTva: Detail.TTva,
    Remise: Detail.Remise,
  });
  //  console.log(Detail)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};

export const deleteDetailData = async (IdDetailFacture) => {
  const data = await axios.post(`${baseApi}/AchatsFacturesDetail`, {
    action: "delete",
    token: sessionStorage.getItem("token"),
    IdDetailFacture: IdDetailFacture,
  });
  //  console.log(data.data.message)
  if (data.data && data.status === 200) {
    return data.data.message;
  } else {
    deconnect();
  }
};
