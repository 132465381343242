import { useState, useRef, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getDetailData,
  AddDetailData,
  updateDetailData,
  deleteDetailData,
} from "../../../../services/ventes";

import { FormattNumber, isLogin, SerachData } from "../../../../services/global";
import Spinner from "../../../../components/widgets/spinner/spinner";

import ComboProduit from "../../../Produits/Articles/ComboProduit";

const DetailBL = forwardRef((props, ref) => {
  const EditModal = useRef();
  const searchInput = useRef();

  const Qte = useRef();
  const Prix = useRef();
  const Remise = useRef();
  const ComboProduitRef = useRef();

  const QteAdd = useRef();
  const PrixAdd = useRef();
  const RemiseAdd = useRef();
  const ComboProduitRefAdd = useRef();

  const DetailTable = useRef();

  const navigate = useNavigate();

  let winSize = useSelector((state) => state.LayoutReducer.winSize);
  const [DataList, setDataList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchMode, setSearchMode] = useState(false);
  const [FromErrors, setFromErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentLigne, setCurrentLigne] = useState(0);



  const initialDetail = {
    IdDetailBL: 0,
    CodeArticle: 0,
    codeBL: props.IdBL,
    designation: "",
    Quantite: 0,
    sens: props.Sens,
    Description: "",
    Prix: 0,
    TTva: 20,
    Remise: 0,
    Total: 0,
    RefArticle: "",
  };

  let currentDetail = initialDetail;


  const getDetail = async () => {
    setLoading(true);
    const data = await getDetailData(props.IdBL);
    setDataList(data);
    setLoading(false);
    props.setDetailPrint([...data]);
    const TotalBL = data.reduce(
      (Total, row) => Total + row.Quantite * row.Prix * (1 - row.Remise / 100),
      0
    );
    props.setTotalBL(TotalBL);
  };

  useEffect(() => {
    sessionStorage.setItem("url", "/ventes/liste");
    if (!isLogin()) {
      navigate("/login");
    }

    const getFistData = async () => {
      setLoading(true);
      const data = await getDetailData(props.IdBL);
      setDataList(data);
      setLoading(false);
      props.setDetailPrint([...data]);
    };

    getFistData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.IdBL, navigate]);

  const TriggerModal = (Detail = initialDetail) => {
    if (!props.upte) {
      alert('Accès Non Autorisé')
    } else {
      currentDetail = { ...Detail };
      //

      const Modal = EditModal.current;

      if (Modal.classList.contains("d-none")) {
        if (currentDetail.IdDetailBL === 0 && winSize >= 992) {
          QteAdd.current.value = 0;
          PrixAdd.current.value = 0;
          RemiseAdd.current.value = 0;

          ComboProduitRefAdd.current.SetProdText(0, "", "", 0);
        }
        else {
          Qte.current.value = currentDetail.Quantite;
          Prix.current.value = currentDetail.Prix;
          Remise.current.value = currentDetail.Remise;

          ComboProduitRef.current.SetProdText(
            currentDetail.CodeArticle,
            currentDetail.designation,
            currentDetail.RefArticle,
            currentDetail.Prix
          );

          Modal.classList.remove("d-none");
        }
      } else {
        Modal.classList.add("d-none");
        setFromErrors({});
      }
    }
  };

  const VerifData = () => {
    //  console.log(currentDetail)
    let verif = {};
    if (currentDetail.CodeArticle === 0)
      verif = { ...verif, Article: "Erreur Article" };
    if (currentDetail.Quantite === "0" || currentDetail.Quantite === 0)
      verif = { ...verif, Quantite: "Erreur Quantite" };

    setFromErrors(verif);
    //   console.log(Object.keys(verif).length)
    if (Object.keys(verif).length === 0) {
      SaveData();
    }
  };
  const SaveData = () => {
    if (currentDetail.IdDetailBL === 0) {
      AddDetailData(currentDetail)
        .then((message) => {
          getDetail();
          DetailTable.current.scrollTop = DetailTable.current.scrollHeight + 50;
          setCurrentLigne(DataList.length);
          TriggerModal();
        })
        .catch((e) => alert("Erreur Modification"));
    } else {
      updateDetailData(currentDetail)
        .then((message) => {
          getDetail();
          setCurrentLigne(DataList.findIndex(object => {
            return object.IdDetailBL === currentDetail.IdDetailBL;
          }))
          TriggerModal();
        })
        .catch((e) => alert("Erreur Modification"));
    }
  };

  const deleteDetail = (IdDetailBL) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Êtes vous sûr de vouloir supprimer ce produit ?`)) {
      deleteDetailData(IdDetailBL)
        .then(async (message) => {
          getDetail();

          console.log(message);
        })
        .catch((e) => {
          alert("Erreur Suppression");
          console.log(e);
        });
    }
  };

  const updateProduct = (idArticle, DesignationProduct, PU) => {
    currentDetail.CodeArticle = idArticle;
    currentDetail.designation = DesignationProduct;
    currentDetail.Prix = PU;

    if (currentDetail.IdDetailBL === 0 && winSize >= 992) {
      PrixAdd.current.value = PU;
    }
    else { Prix.current.value = PU; }

  };

  const ItemsCount = (Ref) => {
    return DataList.filter((currentElement) => currentElement.RefArticle === Ref).length;
  };

  return (
    <>
      <div className="d-flex justify-content-between my-1" >
        {
          (!searchMode && winSize > 992) &&
          <div className="d-flex small gap-2 mt-1" style={{ width: '88%' }} >
            <div className="" style={{ width: '60%' }}>
              <ComboProduit updateProduct={updateProduct} ref={ComboProduitRefAdd} top={'42px'} />
            </div>

            <div className="d-flex gap-1 align-items-center">
              <label>Qté</label>
              <input
                className="form-control form-control-sm"
                type="number"
                ref={QteAdd}
                onChange={(e) => (currentDetail.Quantite = e.target.value)}
              />
            </div>
            <span className="text-danger">{FromErrors.Quantite || ""}</span>


            <div className="d-flex gap-1 align-items-center">
              <label>Prix</label>
              <input
                className="form-control form-control-sm"
                type="number"
                ref={PrixAdd}
                onChange={(e) => (currentDetail.Prix = e.target.value)}
              />
            </div>


            <div className="d-flex gap-1 align-items-center">
              <label>R%</label>
              <input
                className="form-control form-control-sm"
                type="number"
                ref={RemiseAdd}
                onChange={(e) => (currentDetail.Remise = e.target.value)}
              />
            </div>

            <input
              className="btn btn-success btn-sm mb-1"
              type="button"
              value="Ajouter"
              onClick={() => VerifData()}
            />

          </div>
        }
        <div className={searchMode ? "d-flex justify-content-between w-100" : 'd-flex'}>

          <div className="input-group ">
            <input
              ref={searchInput}
              className="form-control me-2"
              style={{ zIndex: searchMode || winSize <= 992 ? '10' : '-10' }}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Designation / Réf"
            />
          </div>
          <div className="d-flex">
            <span className="input-group-text d-none d-lg-inline"
              onClick={() => { setSearchMode(!searchMode); setSearchText(""); searchInput.current.focus(); }}>
              <i className={searchMode ? "fa fa-plus" : 'fa fa-search'} ></i>
            </span>
            <button
              className="btn btn-primary btn-sm ms-2"
              onClick={() => props.imprimer()}
              type="button"
            >
              <i className="fa fa-print"></i>
            </button>
          </div>


        </div>
        {
          winSize <= 992 &&
          <button
            onClick={() => props.upte && TriggerModal()}
            className="btn btn-success btn-sm"
            type="button"
            disabled={!props.upte}
          >
            +
          </button>
        }


      </div>

      <div ref={DetailTable} className="vh-scroled-detail mb-1 border">
        <table className="table table-hover w-100 mb-5">
          <tbody>
            {DataList.map((row, i) => (
              (SerachData(row.designation + ' ' + row.RefArticle, searchText)) && (
                <tr key={i} className={currentLigne === i ? "bg-primary bg-opacity-25" : ''} >
                  <td className="text-center border-end align-middle  d-none d-lg-table-cell" >{i + 1}</td>
                  <td onDoubleClick={() => TriggerModal(row)} className="align-middle">
                    <div className="row small gy-2 mb-1">
                      <div className="col-12 col-lg-5 px-2">
                        <b>{row.designation}</b> ( {row.RefArticle} )
                      </div>
                      <div className="col-12  col-lg-4 d-flex justify-content-between align-items-center px-2 gap-1">
                        <div className="d-flex gap-1">
                          <b className={ItemsCount(row.RefArticle) > 1 ? "text-danger" : ""}> Qté: </b>
                          <span className="rounded bg-white text-dark text-center w50">{row.Quantite}</span>
                        </div>
                        <div className="d-flex gap-1">
                          <b>Prix:</b>
                          <span className="rounded px-1 bg-white text-dark text-end w75 mx-1">{FormattNumber(row.Prix - 0, 2)}</span>
                        </div>
                      </div>
                      <div className="col-12  col-lg-3 d-flex justify-content-between align-items-center px-2 gap-1 ">
                        <div className="d-flex gap-1">
                          <b>R%:</b>
                          <span className="rounded bg-white text-dark text-center w50"> {FormattNumber(row.Remise - 0, 0)}</span>
                        </div>
                        <div className="d-flex gap-1">
                          <b>TVA:</b>
                          <span className="rounded bg-white text-dark text-center w50 mx-1">{FormattNumber(row.TTva - 0, 0)}%</span>
                        </div>
                      </div>

                    </div>
                  </td>
                  <td className="border-start align-middle">
                    <div className="row">
                      <div className="col-12  col-lg-6 text-end mb-1" >
                        {FormattNumber(row.Total - 0, 2)}
                      </div>
                      <div className="col-12  col-lg-6 d-flex justify-content-end mb-1">
                        <button
                          className="btn bg-primary-1 btn-sm"
                          onClick={() => TriggerModal(row)}
                          name="edit"
                          type="button"
                          disabled={!props.upte}
                        >
                          <i className="fa fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-warning btn-sm delete ms-2 "
                          name="delete"
                          type="button"
                          onClick={() => deleteDetail(row.IdDetailBL)}
                          disabled={!props.upte}
                        >
                          <i className="fa fa-trash-o"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            ))}
          </tbody>
        </table>
        
      </div>

      <Spinner isLoading={isLoading} StyleClass="spinner-positionDetail" />

      {/* <!-- Button trigger modal --> */}

      {/* <!-- Modal --> */}
      <div
        ref={EditModal}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            ComboProduitRef.current.callBackBtn();
          }
        }}
        className=" border bg-dark bg-opacity-25 modal-fullscreen w-100 d-none fixed-top d-flex align-items-center justify-content-center"
      >
        <div
          className="bg-white border rounded-3 position-relative shadow-lg"
          style={{ width: "500px", minHeight: "230px" }}
        >
          <div className="row border-bottom m-auto w-100 py-2">
            <div className="col-12">Edition</div>
          </div>
          <div className="row m-3 small">
            <ComboProduit updateProduct={updateProduct} ref={ComboProduitRef} />
            <span className="text-danger">{FromErrors.Article || ""}</span>
            <div className="col-4">
              <div className="form-group">
                <label>Quantité</label>
                <input
                  className="form-control"
                  type="number"
                  ref={Qte}
                  onChange={(e) => (currentDetail.Quantite = e.target.value)}
                />
              </div>
              <span className="text-danger">{FromErrors.Quantite || ""}</span>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Prix</label>
                <input
                  className="form-control"
                  type="number"
                  ref={Prix}
                  onChange={(e) => (currentDetail.Prix = e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Remise(%)</label>
                <input
                  className="form-control"
                  type="number"
                  ref={Remise}
                  onChange={(e) => (currentDetail.Remise = e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row position-absolute bottom-0 m-auto border-top w-100 py-2">
            <div className="col-12">
              <div className="form-group text-end">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={() => TriggerModal()}
                >
                  Fermer
                </button>
                <input
                  className="btn btn-success btn-sm mx-2"
                  type="button"
                  value="Enregistrer"
                  onClick={() => VerifData()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default DetailBL;
