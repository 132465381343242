import { useDispatch } from "react-redux";
import { SetPageTitle } from "../../../redux/slices/LayoutSlice";

import { useEffect, useState,useRef } from "react";
import { useParams } from "react-router-dom";

import { getSingleUser, UpdateProduitsRoles, UpdateAchatsRoles, UpdateVentesRoles, UpdateTresorieRoles } from "../../../services/params";

const UserRoles = () => {
    const dispatch = useDispatch();

    const [currentUser, setcurrentUser] = useState({});
    const [menuList, setMenuList] = useState('produits');

    const AlertSuccess = useRef();
    const AlertError = useRef();
    


    const params = useParams();

    useEffect(() => {
        dispatch(SetPageTitle("User Roles"));

        const getFistData = async () => {
            const response = await getSingleUser(params.id);

            if (response.IdUser) {
                setcurrentUser(response);               

            } else {
                alert("?");
            }
        };

        getFistData();
    }, [dispatch, params.id]);

    const setRole = (role, i) => {
        const val = currentUser[role][i] === '0' ? '1' : '0';
        switch (i) {
            case 0: currentUser[role] = val + currentUser[role][1] + currentUser[role][2] ; break;
            case 1: currentUser[role] = currentUser[role][0] + val + currentUser[role][2] ; break;
            case 2: currentUser[role] = currentUser[role][0] + currentUser[role][1] + val ; break;
            

            default: return

        }



        setcurrentUser({ ...currentUser });

    }

    const saveRoles = () => {
        AlertSuccess.current.classList.add('d-none');
        AlertError.current.classList.add('d-none');
        
        if (menuList === "produits") {
            
            UpdateProduitsRoles(currentUser)
              .then(() => {
                AlertSuccess.current.classList.remove('d-none');
                setTimeout(() => {AlertSuccess.current.classList.add('d-none');}, 3000);

              })
              .catch(() => {
                AlertError.current.classList.remove('d-none');
                setTimeout(() => {AlertError.current.classList.add('d-none');}, 5000);
              });
          }
          else if (menuList === "achats") {
            
            UpdateAchatsRoles(currentUser)
              .then(() => {
                AlertSuccess.current.classList.remove('d-none');
                setTimeout(() => {AlertSuccess.current.classList.add('d-none');}, 3000);

              })
              .catch(() => {
                AlertError.current.classList.remove('d-none');
                setTimeout(() => {AlertError.current.classList.add('d-none');}, 5000);
              });
          }
          else if (menuList === "ventes") {
            
            UpdateVentesRoles(currentUser)
              .then(() => {
                AlertSuccess.current.classList.remove('d-none');
                setTimeout(() => {AlertSuccess.current.classList.add('d-none');}, 3000);

              })
              .catch(() => {
                AlertError.current.classList.remove('d-none');
                setTimeout(() => {AlertError.current.classList.add('d-none');}, 5000);
              });
          }
          else if (menuList === "tresorie") {

            UpdateTresorieRoles(currentUser)
              .then(() => {
                AlertSuccess.current.classList.remove('d-none');
                setTimeout(() => {AlertSuccess.current.classList.add('d-none');}, 3000);

              })
              .catch(() => {
                AlertError.current.classList.remove('d-none');
                setTimeout(() => {AlertError.current.classList.add('d-none');}, 5000);
              });
          }
    }

    return (
        <>
            
            <div className="row mb-3 small align-items-center not-print">            
                <div className="col-10">
                    <div className="border-bottom d-flex gap-2">
                        <button className={menuList==='produits' ? "btn btn-dark btn-sm bg-dark-2 text-white":"btn btn-light btn-sm mb-3"} onClick={() => setMenuList('produits')}>Produits</button>
                        <button className={menuList==='achats' ? "btn btn-dark btn-sm bg-dark-2 text-white":"btn btn-light btn-sm mb-3"} onClick={() => setMenuList('achats')}>Achats</button>
                        <button className={menuList==='ventes' ? "btn btn-dark btn-sm bg-dark-2 text-white":"btn btn-light btn-sm mb-3" } onClick={() => setMenuList('ventes')}>Ventes</button>
                        <button className={menuList==='tresorie' ? "btn btn-dark btn-sm bg-dark-2 text-white":"btn btn-light btn-sm mb-3"} onClick={() => setMenuList('tresorie')}>Trésorie</button>
                    </div>
                </div>
                <div className="col-2 text-end pe-3">
                    <b className="text-capitalize">{currentUser.userName}</b>
                </div>
            </div>

            <table className="table mt-5">
                {
                    currentUser.prodList &&
                    <tbody>
                        {menuList === 'produits' && (
                            <>
                                <tr>
                                    <td className="pt-3 h6">Liste des Produits</td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodList[0] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodList', 0)}
                                        >
                                            <span className="pe-3">Affichage : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodList[0] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodList[1] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodList', 1)}
                                        >
                                            <span className="pe-5">Modification :</span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodList[1] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodList[2] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodList', 2)}
                                        >
                                            <span>Suppression : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodList[2] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td className="pt-3 h6">Etat Periodique</td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodPeriodique[0] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodPeriodique', 0)}
                                        >
                                            <span className="pe-3">Affichage : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodPeriodique[0] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        {/* <button
                                            className={"btn " + (currentUser.prodPeriodique[1] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodPeriodique', 1)}
                                        >
                                            <span className="pe-5">Modification :</span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodPeriodique[1] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button> */}
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        {/* <button
                                            className={"btn " + (currentUser.prodPeriodique[2] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodPeriodique', 2)}
                                        >
                                            <span>Suppression : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodPeriodique[2] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button> */}
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <td className="pt-3 h6">Liste Inventaires</td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodInvenatire[0] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodInvenatire', 0)}
                                        >
                                            <span className="pe-3">Affichage : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodInvenatire[0] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodInvenatire[1] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodInvenatire', 1)}
                                        >
                                            <span className="pe-5">Modification :</span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodInvenatire[1] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodInvenatire[2] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodInvenatire', 2)}
                                        >
                                            <span>Suppression : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodInvenatire[2] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td className="pt-3 h6">Graphes & Courbes</td>
                                    <td style={{ width: '20%' }}>
                                        <button
                                            className={"btn " + (currentUser.prodGraphique[0] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodGraphique', 0)}
                                        >
                                            <span className="pe-3">Affichage : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodGraphique[0] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        {/* <button
                                            className={"btn " + (currentUser.prodGraphique[1] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodGraphique', 1)}
                                        >
                                            <span className="pe-5">Modification :</span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodGraphique[1] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button> */}
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        {/* <button
                                            className={"btn " + (currentUser.prodGraphique[2] === '1'
                                                ? 'btn-outline-primary'
                                                : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                            onClick={() =>
                                                setRole('prodGraphique', 2)}
                                        >
                                            <span>Suppression : </span>
                                            <span className="badge bg-white text-end" >
                                                <i
                                                    className={
                                                        currentUser.prodGraphique[2] === "1"
                                                            ? "fa fa-check-square-o text-success "
                                                            : "fa fa-times text-danger "
                                                    }
                                                ></i>
                                            </span>
                                        </button> */}
                                    </td>
                                    
                                </tr>
                                
                            </>

                        )}

                        {menuList === 'achats' && (
                            <>
                            <tr>
                                <td className="pt-3 h6">Liste des Fournisseurs</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatFournisseur[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatFournisseur', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatFournisseur[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatFournisseur[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatFournisseur', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatFournisseur[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatFournisseur[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatFournisseur', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatFournisseur[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Liste des Commandes</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatCommande[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatCommande', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatCommande[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatCommande[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatCommande', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatCommande[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatCommande[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatCommande', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatCommande[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Bons d'Achats</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatsBon[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatsBon', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatsBon[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatsBon[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatsBon', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatsBon[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatsBon[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatsBon', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatsBon[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Factures d'Achats</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatFacture[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatFacture', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatFacture[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatFacture[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatFacture', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatFacture[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatFacture[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatFacture', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatFacture[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Réglement des Achats</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatReglement[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatReglement', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatReglement[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatReglement[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatReglement', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatReglement[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.AchatReglement[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('AchatReglement', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.AchatReglement[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                               
                            </tr>
                            
                        </>
                        )}

                        {menuList === 'ventes' && (
                            <>
                            <tr>
                                <td className="pt-3 h6">Vente Comptoir</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteComptoir[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteComptoir', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteComptoir[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteComptoir[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteComptoir', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteComptoir[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteComptoir[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteComptoir', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteComptoir[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                               
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Liste des Clients</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteClient[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteClient', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteClient[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteClient[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteClient', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteClient[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteClient[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteClient', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteClient[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Liste des Devis</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteDevis[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteDevis', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteDevis[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteDevis[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteDevis', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteDevis[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteDevis[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteDevis', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteDevis[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Bons de Livraisons</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteBon[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteBon', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteBon[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteBon[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteBon', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteBon[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteBon[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteBon', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteBon[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Factures de Ventes</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteFacture[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteFacture', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteFacture[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteFacture[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteFacture', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteFacture[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteFacture[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteFacture', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteFacture[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="pt-3 h6">Réglement des Ventes</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteReglement[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteReglement', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteReglement[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteReglement[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteReglement', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteReglement[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.VenteReglement[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('VenteReglement', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.VenteReglement[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                            
                        </>
                        )}

                        {menuList === 'tresorie' && (
                            <tr>
                                <td className="pt-3 h6">Journal de Caisse</td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.prodList[0] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('prodList', 0)}
                                    >
                                        <span className="pe-3">Affichage : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.prodList[0] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.prodList[1] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('prodList', 1)}
                                    >
                                        <span className="pe-5">Modification :</span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.prodList[1] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                <td style={{ width: '20%' }}>
                                    <button
                                        className={"btn " + (currentUser.prodList[2] === '1'
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary') + " btn-sm d-flex justify-content-between align-items-center w-100"}
                                        onClick={() =>
                                            setRole('prodList', 2)}
                                    >
                                        <span>Suppression : </span>
                                        <span className="badge bg-white text-end" >
                                            <i
                                                className={
                                                    currentUser.prodList[2] === "1"
                                                        ? "fa fa-check-square-o text-success "
                                                        : "fa fa-times text-danger "
                                                }
                                            ></i>
                                        </span>
                                    </button>
                                </td>
                                
                            </tr>
                        )}

                        <tr>

                        <td colSpan={2} className="text-center border-0 py-4">
                        <div ref={AlertSuccess} class="alert alert-success d-none" role="alert"> Mise à jour des Roles Effectuer avec succée</div>
                        <div ref={AlertError} class="alert alert-danger d-none" role="alert"> Un probleme survenu lors de la mise à jour !!</div>
                            </td>
                            <td colSpan={2} className="text-end border-0 py-4">
                                <button
                                    className={"btn btn-primary btn-sm"}
                                    onClick={() =>
                                        saveRoles()}
                                >
                                    Enregistrer

                                </button>
                            </td>
                        </tr>
                    </tbody>
                }
            </table>

            
        </>
    );
};

export default UserRoles;
